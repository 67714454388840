// eslint-disable-next-line @typescript-eslint/naming-convention
declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";
import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AppCacheService } from 'projects/core-lib/src/lib/services/app-cache.service';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { CacheLevel, ApiProperties, ApiCall, ApiOperationType, Query, IApiResponseWrapperTyped, IApiResponseWrapper } from 'projects/core-lib/src/lib/api/ApiModels';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { Observable, of, Subject } from 'rxjs';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { map, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { Dictionary } from '../models/dictionary';
import { BaseService } from './base.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { SystemService } from './system.service';
import { differenceInMinutes, format, startOfDay } from 'date-fns';
import { AlertItemType } from 'projects/common-lib/src/lib/alert/alert-manager';
import { ApiModuleProxy } from "../api/Api.Module.Proxy";

@Injectable({
  providedIn: 'root'
})
export class AttachmentService extends BaseService {

  // protected apiProperties: ApiProperties = Api.Attachment();
  // protected apiCallList: ApiCall;
  // protected apiCallFull: ApiCall;

  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected cache: AppCacheService,
    protected sanitizer: DomSanitizer,
    protected router: Router,
    protected ngbModalService: NgbModal) {

    super();
    // this.apiCallList = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.List);
    // this.apiCallList.silent = true;
    // this.apiCallFull = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Get);
    // this.apiCallFull.silent = true;

  }



  // public getAssetFullObject(assetId: number, cacheIgnore: boolean = false): Observable<m5.AssetEditViewModel> {
  //   this.apiCallFull.cacheIgnoreOnRead = cacheIgnore;
  //   return this.apiService.execute(this.apiCallFull, assetId).pipe(
  //     map((result: IApiResponseWrapperTyped<m5.AssetEditViewModel>) => {
  //       if (result.Data.Success && result.Data.Data) {
  //         return result.Data.Data;
  //       } else {
  //         return null;
  //       }
  //     }),
  //     takeUntil(this.ngUnsubscribe));
  // }




  /*
   * Check if a file extension is viewable in the browser.
   * @param extension - The file extension.
   * @returns True if the file extension indicates this is viewable in the browser; otherwise, false.
   */
  public isViewable(extension: string): boolean {
    // Images are viewable
    if (Helper.isFileTypeImage(extension)) {
      return true;
    }
    return Helper.isFileTypeMatch(extension, ["pdf", "xml", "htm", "html", "txt"]);
  }

  /**
   * Check if a file extension  indicates this can be downloaded.
   * @param extension - The file extension.
   * @returns True if the file extension indicates this is downloadable in the browser; otherwise, false.
   */
  public isDownloadable(extension: string): boolean {
    // TODO don't download other types of assets or files????
    return true;
  }


  previewRenderMethod(extension: string): "image" | "iframe" | "icon" {
    if (Helper.isFileTypeImage(extension)) {
      return "image";
    } else if (this.isViewable(extension)) {
      return "iframe";
    } else if (!this.isViewable(extension)) {
      return "icon";
    } else {
      return "icon";
    }
  }


  /**
   * Determine the icon to use for an attachment based on the file extension.
   * @param extension - The file extension.
   * @returns The name of the icon to use.
   */
  public getIcon(extension: string): string {
    extension = (extension || "").toLowerCase();
    if (Helper.isFileTypeImage(extension)) {
      return "file-image";
    } else if (extension === "xls" || extension === "xlsx" || extension === "xlsm") {
      return "file-excel";
    } else if (extension === "pdf") {
      return "file-pdf";
    } else if (extension === "doc" || extension === "docx" || extension === "docm") {
      return "file-word";
    } else if (extension === "ppt" || extension === "pptx") {
      return "file-powerpoint";
    } else if (extension === "zip" || extension === "gz" || extension === "7z") {
      return "file-archive";
    } else if (extension === "csv") {
      return "file-csv";
    } else if (extension === "txt") {
      return "file-alt";
    } else if (extension === "xml" || extension === "htm" || extension === "html") {
      return "file-code";
    } else if (extension === "link") {
      return "external-link";
    } else if (Helper.isFileTypeAudio(extension)) {
      return "file-audio";
    } else if (Helper.isFileTypeVideo(extension)) {
      return "file-video";
    } else {
      return "file";
    }
  }

  /**
   * Get a description of the size of an attachment.
   * @param extension - The file extension.
   * @param sizeBytes - The size of the attachment in bytes.
   * @param sizeOther - The size of the attachment in duration, pages, etc.
   * @param sizeInformation - A textual description of the size of the attachment.
   * @param width - The width of an attachment.
   * @param height - The height of an attachment.
   * @param template - The template to use for building the attachment size description.
   * @returns A description of the size of the attachment.
   */
  public getSizeDescription(extension: string, sizeBytes: number, sizeOther: number, sizeInformation: string, width: number, height: number, template: string = ""): string {

    // If we have size information provided then run with that
    if (sizeInformation) {
      return sizeInformation;
    }

    let size: string = "";

    // For audio and video use duration if provided
    if ((Helper.isFileTypeAudio(extension) || Helper.isFileTypeVideo(extension)) && sizeOther && sizeOther > 0) {
      const duration = format(startOfDay(new Date()).setSeconds(sizeOther), 'H:mm:ss');
      size += (size && size !== "") ? "; " : "";
      size += duration;
    }

    // For documents use pages if provided
    if (Helper.isFileTypeDocument(extension) && sizeOther && sizeOther > 0) {
      size += (size && size !== "") ? "; " : "";
      size += sizeOther.toString() + " pages";
    }

    // For images and video use resolution if provided
    if ((Helper.isFileTypeImage(extension) || Helper.isFileTypeVideo(extension)) && width && height) {
      size += (size && size !== "") ? "; " : "";
      size += width.toString() + "x" + height.toString();
    }

    // Always use the actual disk space size if provided
    if (sizeBytes && sizeBytes > 0) {
      const sizeKb: number = (sizeBytes / 1024);
      const sizeMb: number = (sizeBytes / 1024 / 1024);
      size += (size && size !== "") ? "; " : "";
      if (sizeMb && Math.round(sizeMb) >= 1) {
        size += sizeMb.toFixed(1) + " MB";
      } else if (sizeKb && Math.round(sizeKb) >= 1) {
        size += sizeKb.toFixed(1) + " KB";
      } else {
        size += sizeBytes + " bytes";
      }
    }

    // Tell when we don't know anything
    if (!size || size === "") {
      size = "unknown size";
    }

    return size;

  }


  /**
   * Redirect to the view url for the specified attachment.
   */
  public view(attachment: m5.AttachmentMetaDataViewModel, fileName: string = "", viewInNewWindow: boolean = false): void {

    if (!attachment) {
      Log.errorMessage("Attachment view requested but no attachment object provided.");
      return;
    }
    if (!this.appService.hasPermission(attachment.OwnerResourceType, "R")) {
      this.appService.alertManager.addAlertMessage(AlertItemType.Danger, `Attachment view requested but missing read permissions for "${attachment.OwnerResourceType}".`);
      return;
    }

    const urls = this.getUrls(attachment, 0, fileName);

    // { path: "view/:ownerType/:ownerId/:category/:id/:name", component: AttachmentViewerComponent, data: { mode: "view", inputMethod: "route", baseRoute: "attachments" } },
    const slug = Helper.encodeURISlug(attachment.FriendlyName || attachment.Summary);
    if (viewInNewWindow) {
      const url = `${window.location.protocol}//${window.location.host}/attachments/view/${attachment.OwnerResourceType}/${attachment.OwnerResourceId}/${attachment.OwnerResourceCategory}/${attachment.AttachmentId}/${slug}`;
      Helper.openUrl(url, true);
    } else {
      this.router.navigate(["/attachments/view", attachment.OwnerResourceType, attachment.OwnerResourceId, attachment.OwnerResourceCategory, attachment.AttachmentId, slug]);
    }

  }



  /**
   After verifying the download is allowed this method downloads the specified attachment.
   */
  public download(attachment: m5.AttachmentMetaDataViewModel, fileName: string = "", onDownload: (attachment: m5.AttachmentMetaDataViewModel) => any = null): void {

    if (!attachment) {
      Log.errorMessage("Attachment download requested but no attachment object provided.");
      return;
    }
    if (!this.appService.hasPermission(attachment.OwnerResourceType, "R")) {
      this.appService.alertManager.addAlertMessage(AlertItemType.Danger, `Attachment download requested but missing read permissions for "${attachment.OwnerResourceType}".`);
      return;
    }

    const urls = this.getUrls(attachment, 0, fileName);
    Helper.openDownload(urls.downloadUrl);

    // If we asked for an onDownload action to fire then give 2 seconds for the download to start and then call that callback.
    if (onDownload) {
      setTimeout(onDownload, 2_000, attachment);
    }

  }



  protected urlCache: Dictionary<AttachmentUrls> = new Dictionary<AttachmentUrls>();
  public getUrls(attachment: m5.AttachmentMetaDataViewModel, cacheBuster: number = 0, fileName: string = "", alwaysUseApiHost: boolean = false): AttachmentUrls {

    // Validate inputs
    if (!attachment) {
      return null;
    }

    // Check cache
    const cacheKey: string = `${attachment.AttachmentId}-${cacheBuster}-${fileName}-${alwaysUseApiHost}`;
    if (this.urlCache.containsKey(cacheKey)) {
      // Get the cache object
      const cacheUrls = this.urlCache.item(cacheKey);
      // See if too old
      if (cacheUrls && cacheUrls.asOf) {
        const then = cacheUrls.asOf;
        const now = new Date();
        const duration = differenceInMinutes(now, then);
        if (duration > 10) {
          this.urlCache.remove(cacheKey);
        } else {
          return cacheUrls;
        }
      }
    }

    // Get URLs
    const urls: AttachmentUrls = {};
    urls.attachmentId = attachment.AttachmentId;
    urls.friendlyName = attachment.FriendlyName;
    urls.fileType = attachment.FileType;
    urls.asOf = new Date();

    // Link records just point to the specified url
    if (Helper.equals(attachment.FileType, "link", true)) {
      let url = attachment.Url;
      if (url && !url.startsWith("http")) {
        url = "http://" + url;
      }
      urls.downloadUrl = url;
      urls.downloadPublicUrl = url;
      urls.viewUrl = url;
      urls.viewUrlSafe = this.sanitizer.bypassSecurityTrustUrl(urls.viewUrl);
      urls.viewUrlResourceSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urls.viewUrl);
      urls.viewThumbnailUrl = url;
      urls.viewThumbnailUrlSafe = this.sanitizer.bypassSecurityTrustUrl(urls.viewThumbnailUrl);
      urls.viewThumbnailUrlResourceSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urls.viewThumbnailUrl);
      urls.viewPublicUrl = url;
      urls.viewPublicThumbnailUrl = url;
      // Add to cache
      this.urlCache.add(cacheKey, urls);
      return urls;
    }

    // Upload (this always uses api host and never proxy host)
    let apiProp: ApiProperties = Api.AttachmentUpload();
    let apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Add);
    urls.uploadUrlNew = ApiHelper.buildApiAbsoluteUrl(apiCall, attachment);
    apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Edit);
    // Save our token // TODO dump this cache upon logout?
    urls.token = apiCall.token;

    urls.uploadUrlReplace = ApiHelper.buildApiAbsoluteUrl(apiCall, attachment);
    urls.uploadUrlReplace = ApiHelper.addQueryStringToUrl(urls.uploadUrlReplace, `token=${urls.token}`);

    const apiRequestData = this.getApiRequestData(attachment, false, false, fileName);

    // Download (this may use proxy or api host depending on availability and settings)
    if (alwaysUseApiHost || !AppConfig.proxyUrl || !AppConfig.proxyDataSourceId) {
      apiProp = Api.AttachmentDownload();
      apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
      urls.downloadUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, apiRequestData);
      urls.downloadUrl = ApiHelper.addQueryStringToUrl(urls.downloadUrl, `token=${urls.token}`);
    } else {
      apiProp = ApiModuleProxy.AttachmentDownload();
      apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
      urls.downloadUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, apiRequestData);
      // Note proxy uses cookie based auth so appending token is not required
    }

    apiProp = Api.AttachmentView();
    apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
    urls.viewForEditUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, apiRequestData);
    if (cacheBuster) {
      urls.viewForEditUrl = ApiHelper.addQueryStringToUrl(urls.viewForEditUrl, `count=${cacheBuster}`);
    }
    urls.viewForEditUrl = ApiHelper.addQueryStringToUrl(urls.viewForEditUrl, `token=${urls.token}`);

    // View (this may use proxy or api host depending on availability and settings)
    if (alwaysUseApiHost || !AppConfig.proxyUrl || !AppConfig.proxyDataSourceId) {
      urls.viewUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, apiRequestData);
      if (cacheBuster) {
        urls.viewUrl = ApiHelper.addQueryStringToUrl(urls.viewUrl, `count=${cacheBuster}`);
      }
      urls.viewUrl = ApiHelper.addQueryStringToUrl(urls.viewUrl, `token=${urls.token}`);
    } else {
      apiProp = ApiModuleProxy.AttachmentView();
      apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
      urls.viewUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, apiRequestData);
      if (cacheBuster) {
        urls.viewUrl = ApiHelper.addQueryStringToUrl(urls.viewUrl, `count=${cacheBuster}`);
      }
      // Note proxy uses cookie based auth so appending token is not required
    }
    urls.viewUrlSafe = this.sanitizer.bypassSecurityTrustUrl(urls.viewUrl);
    urls.viewUrlResourceSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urls.viewUrl);

    // Thumbnail View (this may use proxy or api host depending on availability and settings)
    if (alwaysUseApiHost || !AppConfig.proxyUrl || !AppConfig.proxyDataSourceId) {
      apiRequestData.Thumbnail = true;
      urls.viewThumbnailUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, apiRequestData);
      if (cacheBuster) {
        urls.viewThumbnailUrl = ApiHelper.addQueryStringToUrl(urls.viewThumbnailUrl, `count=${cacheBuster}`);
      }
      urls.viewThumbnailUrl = ApiHelper.addQueryStringToUrl(urls.viewThumbnailUrl, `token=${urls.token}`);
    } else {
      apiProp = ApiModuleProxy.AttachmentViewThumbnail();
      apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
      urls.viewUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, apiRequestData);
      if (cacheBuster) {
        urls.viewUrl = ApiHelper.addQueryStringToUrl(urls.viewUrl, `count=${cacheBuster}`);
      }
      // Note proxy uses cookie based auth so appending token is not required
    }
    urls.viewThumbnailUrlSafe = this.sanitizer.bypassSecurityTrustUrl(urls.viewThumbnailUrl);
    urls.viewThumbnailUrlResourceSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urls.viewThumbnailUrl);

    // Public download / view / view thumbnail
    if (alwaysUseApiHost || !AppConfig.proxyUrl || !AppConfig.proxyDataSourceId) {
      apiProp = Api.AttachmentDownloadPublic();
      apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
      urls.downloadPublicUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, apiRequestData);
      apiProp = Api.AttachmentViewPublic();
      apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
      urls.viewPublicUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, apiRequestData);
      apiProp = Api.AttachmentViewThumbnailPublic();
      apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
      urls.viewPublicThumbnailUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, apiRequestData);
    } else {
      apiProp = ApiModuleProxy.AttachmentDownloadPublic();
      apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
      urls.downloadPublicUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, apiRequestData);
      apiProp = ApiModuleProxy.AttachmentViewPublic();
      apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
      urls.viewPublicUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, apiRequestData);
      apiProp = ApiModuleProxy.AttachmentViewThumbnailPublic();
      apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
      urls.viewPublicThumbnailUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, apiRequestData);
    }

    // Add to cache
    this.urlCache.add(cacheKey, urls);

    return urls;

  }

  // /**
  //  * Get the download url for the specified attachment. This takes broken down parameters instead of an AttachmentMetaDataViewModel
  //  * for callers who may not have the full object.
  //  * @param attachmentId
  //  * @param fileName
  //  * @param ownerResourceType
  //  * @param ownerResourceId
  //  * @param ownerResourceCategory
  //  * @param friendlyName
  //  * @param fileType
  //  * @returns
  //  */
  // getDownloadUrl(
  //   attachmentId: string,
  //   fileName: string,
  //   ownerResourceType: string,
  //   ownerResourceId: number,
  //   ownerResourceCategory: string,
  //   friendlyName: string,
  //   fileType: string,
  //   thumbnail: boolean = false,
  //   backup: boolean = false,
  //   silent: boolean = false
  // ): string {

  //   let url = "";

  //   // For buildApiAbsoluteUrl, it's finding the PathVariables in this object and putting them in the url
  //   // So we need: "{ownerResourceType}", "{ownerResourceId}", "{ownerResourceCategory}", "{attachmentId}", "{fileName}", "{thumbnail}", "{backup}", "{silent}"
  //   const apiRequestedData = {
  //     AttachmentId: attachmentId,
  //     PartitionId: this.appService.userOrDefault.PartitionId,
  //     OwnerResourceType: ownerResourceType,
  //     OwnerResourceId: ownerResourceId,
  //     OwnerResourceCategory: ownerResourceCategory,
  //     FileName: fileName,
  //     FriendlyName: friendlyName,
  //     FileType: fileType,
  //     Slug: Helper.encodeURISlug(`${friendlyName}.${fileType}`),
  //     Thumbnail: thumbnail,
  //     Backup: backup,
  //     Silent: silent
  //   };

  //   const apiProp = Api.AttachmentDownload();
  //   const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
  //   const token = apiCall.token;

  //   url = ApiHelper.buildApiAbsoluteUrl(apiCall, apiRequestedData);
  //   url = ApiHelper.addQueryStringToUrl(url, `token=${token}`);

  //   return url;
  // }

  // getUploadUrlReplace(attachment: m5.AttachmentMetaDataViewModel) {
  //   const apiProp: ApiProperties = Api.AttachmentUpload();
  //   const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Edit);
  //   return ApiHelper.buildApiAbsoluteUrl(apiCall, attachment);
  // }

  // getViewPublicThumbnailUrl(attachment: m5.AttachmentMetaDataViewModel, fileName: string = ""): string {
  //   let viewPublicThumbnailUrl = "";

  //   const apiRequestData = this.getApiRequestData(attachment, true, false, fileName);
  //   apiRequestData.thumbnail = true;
  //   const apiProp = Api.AttachmentViewThumbnailPublic();
  //   const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
  //   viewPublicThumbnailUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, apiRequestData);


  //   return viewPublicThumbnailUrl;
  // }


  public logAccess(attachment: m5.AttachmentMetaDataViewModel, accessMessage: string = "",
    signatureType: "None" | "Name" | "Login" | "Data" | "Other" = "None", signature: any = null): void {

    // Validate inputs
    if (!attachment) {
      return;
    }

    const apiProp: ApiProperties = Api.AttachmentAccessLog();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Add);
    apiCall.silent = true; // silent api call

    const log = new m5.AttachmentAccessLogViewModel();
    log.AttachmentId = attachment.AttachmentId;
    log.AccessedDateTime = new Date();
    log.AccessedById = this.appService.userOrDefault.ContactId.toString();
    log.AccessedByName = this.appService.userOrDefault.ContactName;
    log.AccessMessage = accessMessage;
    log.AccessSignatureType = signatureType;
    if (!signature && signatureType !== "None") {
      if (signatureType === "Name") {
        signature = this.appService.userOrDefault.ContactName;
      } else if (signatureType === "Login") {
        signature = this.appService.userOrDefault.Login;
      } else {
        signature = {};
      }
    }
    if (signatureType !== "None") {
      if (Helper.isString(signature)) {
        log.AccessSignature = signature;
      } else {
        try {
          log.AccessSignature = JSON.stringify(signature);
        } catch { }
      }
    }

    // A few settings that are not part of our model but used by the api service to resolve the endpoint path
    (log as any).OwnerResourceType = attachment.OwnerResourceType;
    (log as any).OwnerResourceId = attachment.OwnerResourceId;
    (log as any).OwnerResourceCategory = attachment.OwnerResourceCategory;

    this.apiService.execute(apiCall, log)
      .subscribe((result: IApiResponseWrapperTyped<m5.AttachmentAccessLogViewModel>) => {
        if (result.Data.Success) {
          // No action
        } else {
          console.error(result);
        }
      });

    return;

  }



  /**
   * Build out a request data object that merges attachment with other runtime settings for requesting attachments.
   * @param attachment
   * @param thumbnail
   * @param backup
   * @param fileName
   * @param silent
   * @returns
   */
  protected getApiRequestData(attachment: m5.AttachmentMetaDataViewModel, thumbnail: boolean = false, backup: boolean = false, fileName: string = "", silent: boolean = false): any {

    const data: any = {};

    // Validate inputs
    if (!attachment) {
      return data;
    }

    data.AttachmentId = attachment.AttachmentId;
    data.PartitionId = this.appService.userOrDefault.PartitionId;
    data.OwnerResourceType = attachment.OwnerResourceType;
    data.OwnerResourceId = attachment.OwnerResourceId;
    data.OwnerResourceCategory = attachment.OwnerResourceCategory;
    data.FileName = fileName;
    data.FriendlyName = attachment.FriendlyName;
    data.FileType = attachment.FileType;
    data.Slug = Helper.encodeURISlug(`${attachment.FriendlyName}.${attachment.FileType}`);
    data.Thumbnail = thumbnail;
    data.Backup = backup;
    data.Silent = silent;

    return data;

  }


  /**
   * Builds an attachment model based on the provided parameters.
   * @param attachmentId - The ID of the attachment.
   * @param ownerResourceType - The type of the owner resource.
   * @param ownerResourceId - The ID of the owner resource.
   * @param ownerResourceCategory - The category of the owner resource.
   * @param friendlyName - The friendly name of the attachment.
   * @param fileType - The type of the file.
   * @returns The attachment model.
   */
  public getAttachmentModel(
    attachmentId: string,
    ownerResourceType: string,
    ownerResourceId: number | string,
    ownerResourceCategory: string,
    friendlyName: string,
    fileType: string
  ): m5.AttachmentMetaDataViewModel {
    const model = new m5.AttachmentMetaDataViewModel();
    model.AttachmentId = attachmentId;
    model.OwnerResourceType = ownerResourceType;
    model.OwnerResourceId = ownerResourceId.toString();
    model.OwnerResourceCategory = ownerResourceCategory;
    model.FriendlyName = friendlyName;
    model.FileType = fileType;
    return model;
  }




}


interface AttachmentUrls {

  attachmentId?: string;
  friendlyName?: string;
  fileType?: string;

  viewUrl?: string;
  viewUrlSafe?: SafeUrl;
  viewUrlResourceSafe?: SafeResourceUrl;

  viewThumbnailUrl?: string;
  viewThumbnailUrlSafe?: SafeUrl;
  viewThumbnailUrlResourceSafe?: SafeResourceUrl;

  viewForEditUrl?: string;

  downloadUrl?: string;

  viewPublicUrl?: string;
  viewPublicThumbnailUrl?: string;
  downloadPublicUrl?: string;

  uploadUrlReplace?: string;
  uploadUrlNew?: string;

  token?: string;
  asOf?: Date;

}
