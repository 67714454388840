<ib-action-button *ngIf="!allowMultipleFilters"
                  [button]="singleFilterButton"
                  [allowSearch]="allowSearch"
                  [data]="data"></ib-action-button>

<div *ngIf="allowMultipleFilters"
     [style.width]="containerWidth">
  <ib-input-multiselect [vertical]="false"
                        [plainInput]="true"
                        [enableHeaderToggle]="enableAllOrAnyToggle"
                        [toggleEnabledLabel]="'All Selected'"
                        [toggleDisabledLabel]="'Any Selected'"
                        [toggleState]="filterToggleState"
                        [placeholder]="placeholder"
                        [closeCounter]="closeCounter"
                        [maxSelectedLabels]="maxSelectedLabels"
                        [selectedItemsLabel]="selectedItemsLabel"
                        [headerActionButton]="multiSelectBuildFilterButton"
                        [optionsPickList]="filtersPicklist"
                        (change)="onMultiFilterChange($event)"
                        (toggleStateChange)="onToggleStateChange($event)"
                        [(ngModel)]="selectedFilters"></ib-input-multiselect>
</div>