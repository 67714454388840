import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { IconHelper, IconData } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { Dictionary } from 'projects/core-lib/src/lib/models/dictionary';

@Component({
  selector: 'ib-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css']
})
export class IconComponent implements OnInit, OnChanges {

  @Input() public name: string = "";
  @Input() public icon: string = "";
  @Input() public label: string = "";
  @Input() public labelClasses: string = "";
  @Input() public labelStyles: string = "";
  /**
   * When true the label will appear before the icon.  The default is false which
   * shows the icon before the label.
   */
  @Input() public labelFirst: boolean = false;
  @Input() public tooltip: string = "";
  @Input() public tooltipPlacement: string = "auto";
  @Input() public size: string = ""; // xs, sm, lg, 2x-10x, 15x, 20x, 30x, font-size px, em, %
  @Input() public color: string = "";
  @Input() public animation: string = ""; // spin, pulse, wrench, ring, horizontal, vertical, flash, bounce, float, pulse, shake, tada, passing, passing-reverse, burst, falling, a-spin, a-pulse PLUS optional attribute suffixes (hover), (fast), (slow)
  @Input() public flip: "none" | "horizontal" | "vertical" = "none";
  @Input() public pull: "none" | "left" | "right" = "none";
  @Input() public rotate: "0" | "90" | "180" | "270" = "0";
  @Input() public fixedWidth: boolean = false;
  @Input() public border: boolean = false;
  @Input() public solid: boolean = false;
  @Input() public light: boolean = false;
  @Input() public duotone: boolean = false;
  @Input() public iconOnlyWhenMobile: boolean = false;
  @Input() public state: string = null;
  @Input() public stateIcons: StateIcon[] = null;

  @Output() public click: EventEmitter<EventModel> = new EventEmitter();

  public iconData: IconData = null;
  public classes: string = "";
  public styles: SafeStyle;
  public stylesForLabel: SafeStyle;

  public stackedTopClasses: string = "";
  public stackedTopStyles: SafeStyle;
  public stackedBottomClasses: string = "";
  public stackedBottomStyles: SafeStyle;

  public isFileIcon: boolean = false;
  public fileIconUrl: string = "";
  public fileIconSize: number = 15;

  public stateIconData: Dictionary<{ data: IconData, classes: string, styles: SafeStyle }> = new Dictionary<{ data: IconData, classes: string, styles: SafeStyle }>();

  constructor(protected sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    this.configure();

    if (changes.stateIcons) {
      this.setupState();
    }
    if (changes.state) {
      this.setState();
    }

    if (this.iconOnlyWhenMobile && this.icon && Helper.isMobile()) {
      this.label = "";
    }

  }

  configure() {

    if (this.labelStyles) {
      this.stylesForLabel = this.sanitizer.bypassSecurityTrustStyle(this.labelStyles);
    }

    // No name then nothing to show
    if (!this.icon) {
      this.isFileIcon = false;
      this.fileIconUrl = "";
      this.iconData = null;
      this.classes = "";
      return;
    }

    if (Helper.startsWith(this.icon, "file:", true)) {
      this.isFileIcon = true;
      this.fileIconUrl = this.icon.substr(5);
      if (!Helper.contains(this.fileIconUrl, "\\", true) && !Helper.contains(this.fileIconUrl, "/", true)) {
        // No path provided for the icon so push in our /assets/icons/ folder as the default
        this.fileIconUrl = "/assets/icons/" + this.fileIconUrl;
      }
      // this.size: xs, sm, lg, 2x-10x, 15x, 20x, 30x, font-size px, em, %
      if (this.size === "xs") {
        this.fileIconSize = 5;
      } else if (this.size === "sm") {
        this.fileIconSize = 10;
      } else if (this.size === "lg") {
        this.fileIconSize = 20;
      } else if (Helper.endsWith(this.size, "px", true)) {
        this.fileIconSize = parseInt(this.size.replace("px", ""), 10);
      } else if (Helper.endsWith(this.size, "x", true)) {
        const x = parseInt(this.size.replace("x", ""), 10);
        this.fileIconSize = (15 * x);
      }
      this.styles = this.sanitizer.bypassSecurityTrustStyle(`width:${this.fileIconSize}px;height:${this.fileIconSize}px;`);
      return;
    }

    this.iconData = new IconData(this.icon, this.animation);
    this.iconData.size = this.size;
    this.iconData.color = this.color;
    this.iconData.flip = this.flip;
    this.iconData.pull = this.pull;
    this.iconData.rotate = this.rotate;
    this.iconData.fixedWidth = this.fixedWidth;
    this.iconData.border = this.border;

    // Conditional in case icon description had (solid), (light), or (duotone) suffix
    if (this.solid) {
      this.iconData.solid = true;
    } else if (this.light) {
      this.iconData.light = true;
    } else if (this.duotone) {
      this.iconData.duotone = true;
    }

    this.classes = this.iconData.calculatedClasses;
    this.styles = this.sanitizer.bypassSecurityTrustStyle(this.iconData.calculatedStyles);

    if (this.iconData.stackedTop) {
      this.stackedTopClasses = this.iconData.stackedTop.calculatedClasses;
      this.stackedTopStyles = this.sanitizer.bypassSecurityTrustStyle(this.iconData.stackedTop.calculatedStyles);
    }
    if (this.iconData.stackedBottom) {
      this.stackedBottomClasses = this.iconData.stackedBottom.calculatedClasses;
      this.stackedBottomStyles = this.sanitizer.bypassSecurityTrustStyle(this.iconData.stackedBottom.calculatedStyles);
    }

  }

  protected setState() {
    if (this.stateIconData.containsKey(this.state)) {
      const data = this.stateIconData.item(this.state);
      this.classes = data.classes;
      this.styles = data.styles;
    } else if (this.iconData) {
      this.classes = this.iconData.calculatedClasses;
      this.styles = this.sanitizer.bypassSecurityTrustStyle(this.iconData.calculatedStyles);
    } else {
      this.classes = "";
      this.labelClasses = "";
    }
  }

  protected setupState() {
    if (!this.stateIcons || this.stateIcons.length === 0) {
      return;
    }
    this.stateIcons.forEach(icon => {
      const data = new IconData(icon.icon, icon.animation);
      data.size = this.size;
      data.color = this.color;
      data.flip = this.flip;
      data.pull = this.pull;
      data.rotate = this.rotate;
      data.fixedWidth = this.fixedWidth;
      data.border = this.border;
      // if (this.solid) {
      //  data.solid = true;
      // } else if (this.light) {
      //  data.light = true;
      // }
      this.stateIconData.add(icon.state, { data: data, classes: data.calculatedClasses, styles: this.sanitizer.bypassSecurityTrustStyle(data.calculatedStyles) });
    });
  }

  public isClickable(): boolean {
    return (this.click.observers.length > 0);
  }

  public fireClick(event) {
    const payload: EventModel = new EventModel("click", event, null, new EventElementModel("icon", null, this.name, this.icon), { state: this.state });
    this.click.emit(payload);
    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // If we used different event names then it wouldn't be an issue but using these event names is more developer friendly.
      if (this.click.observers.length > 0) {
        // But only do this if someone was listening to us.
        event.stopPropagation();
        event.preventDefault();
      }
    } catch (err) {
      Log.errorMessage(err);
    }
  }

}


export interface StateIcon {
  state: string;
  icon: string;
  animation?: string;
}
