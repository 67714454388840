<div [ngClass]="{'dock-container-left': position === 'left',
                 'dock-container-right': position === 'right',
                 'dock-container-bottom': position === 'bottom',
                 'dock-container-top': position === 'top'}">
    <p-dock class="global-p-dock-light"
            [ngClass]="{'global-p-dock-light': imageDockBackgroundTheme === 'light',
                        'global-p-dock-dark': imageDockBackgroundTheme === 'dark',
                        'global-p-dock-transparent-dark': imageDockBackgroundTheme === 'transparent-dark'}"
            [model]="menuItems" 
            [position]="position">
        <ng-template pTemplate="item" let-item>
            <img style="width: auto; max-width: 100%; height: auto; max-height: 100%;"
                 [ngStyle]="{'filter': imageFilter}"
                 [pTooltip]="item.label"
                 [tooltipOptions]="{showDelay: 600}"
                 [tooltipPosition]="imageToolTipPosition"
                 [src]="item.icon" 
                 [alt]="item.label"
                 (click)="onImgSelect($event, item)"
                 (mouseenter)="onMouseEnterImage($event, item)"/>
        </ng-template>
    </p-dock>
</div>

<p-overlayPanel #dockOverlayPanel
                styleClass="global-overlaypanel-padding {{overlayHijack}}">
  <p-menu [model]="overlayPanelMenu"
          (click)="onMenuClick($event)"></p-menu>
</p-overlayPanel>
