import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER, PLATFORM_ID } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
// Note that we reference lib via source not dist 'core-lib/lib/core-lib.module' since acting as feature module instead of actual lib
import { CoreLibModule } from 'projects/core-lib/src/lib/core-lib.module';
import { CommonLibModule } from 'projects/common-lib/src/lib/common-lib.module';
import { AppRoutingModule } from 'projects/ib-app/src/app/app.routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedCoreModule } from 'projects/shared-lib/src/lib/shared-core/shared-core.module';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { HttpLoaderFactory, ApiMissingTranslationHandler } from 'projects/core-lib/src/lib/helpers/i18n-helpers';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { GlobalErrorHandler } from 'projects/core-lib/src/lib/services/global-error-handler';
import { AppInitService } from 'projects/core-lib/src/lib/services/app-init.service';
import { LayoutStandardComponent } from 'projects/common-lib/src/lib/layout/layout-standard/layout-standard.component';
import { LayoutImageDockComponent } from 'projects/common-lib/src/lib/layout/layout-image-dock/layout-image-dock.component';


/**
 * We need to execute the appInitService.Init(). We cannot do it directly from the provider.
 * We need to create a function which invokes the appInitService.Init() and returns a Promise.
 * We do that in initializeApp1 function.
 * See https://www.tektutorialshub.com/angular/angular-how-to-use-app-initializer/
 * @param appInitService
 */
export function initializeApp1(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.Init();
  };
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    // CacheModule.forRoot(),
    // NgForageModule, //.forRoot(),
    // BrowserCacheModule.forRoot([
    // {
    // provide: CACHE,
    // useClass: MemoryCacheService, // or LocalStorageCacheService
    // deps: [PLATFORM_ID]
    // }
    // ]),
    NgbModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: ApiMissingTranslationHandler, deps: [ApiService] },
      useDefaultLang: false
    }),
    NgProgressModule.withConfig({
      // trickleSpeed: 50,
      // speed: 50,
      debounceTime: 100,
      color: "red",
      thick: true,
      meteor: true
    }), // See https://github.com/MurhafSousli/ngx-progressbar
    NgProgressRouterModule.withConfig({
      delay: 0,
    }), // See https://github.com/MurhafSousli/ngx-progressbar/wiki/Router-events
    CoreLibModule,
    CommonLibModule,
    SharedCoreModule,
    AppRoutingModule,
    LayoutStandardComponent,
    LayoutImageDockComponent
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    AppInitService,
    { provide: APP_INITIALIZER, useFactory: initializeApp1, deps: [AppInitService], multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
