import { Component } from '@angular/core';
import { NgProgressModule } from 'ngx-progressbar';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { CommonLibModule } from '../../common-lib.module';
import { CommonModule, NgClass, NgIf, NgStyle } from '@angular/common';
import { NavService } from '../../ux/nav/nav.service';
import { AppRoutingModule } from 'projects/ib-app/src/app/app.routing.module';
import { InputTextComponent } from '../../input/input-text/input-text.component';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { ConfigHelpers } from 'projects/core-lib/src/lib/helpers/config-helpers';

@Component({
  standalone: true,
  imports: [NgProgressModule, CommonLibModule, AppRoutingModule, NgIf, NgClass, NgStyle],
  selector: 'ib-layout-standard',
  templateUrl: './layout-standard.component.html',
  styleUrls: ['./layout-standard.component.css']
})
export class LayoutStandardComponent extends BaseComponent {
  /**
   * The config can define a 'style' for the background image.
   * This is an object that we place on ngStyle so the correctly css gets applied to the background image
   * to achieve this 'style'.
   */
  public backgroundImageNgStyle: { [key: string]: string } = {};

  constructor(public nav: NavService) {
    super();

    this.backgroundImageNgStyle = ConfigHelpers.getBackgroundImageStyle();
  }
}
