import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, ElementRef, ComponentRef, ChangeDetectorRef, SimpleChange } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { ApiProperties, ApiOperationType, IApiResponseWrapper, ApiCall, IApiResponseWrapperTyped } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { FormHelper } from 'projects/common-lib/src/lib/form/form-helper';
import { EventModel, EventElementModel, ButtonItem, EventModelTyped } from 'projects/common-lib/src/lib/ux-models';
import { Dictionary } from 'projects/core-lib/src/lib/models/dictionary';
import { HandlebarsHelpers } from 'projects/core-lib/src/lib/helpers/handlebars-helpers';
import { TranslationHelperService } from 'projects/core-lib/src/lib/services/translation-helper.service';
import { FormStatusService } from 'projects/core-lib/src/lib/services/form-status.service';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { DynamicComponentService } from 'projects/core-lib/src/lib/services/dynamic-component.service';
import { DynamicFormService } from 'projects/core-lib/src/lib/services/dynamic-form.service';

@Component({
  selector: 'ib-form-control-render',
  templateUrl: './form-control-render.component.html',
  styleUrls: ['./form-control-render.component.css']
})
export class FormControlRenderComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() formModel: m5web.FormEditViewModel;
  // @Input() formStatus: FormStatusModel;
  @Input() parentGroupModel: m5web.FormControlGroupEditViewModel;
  @Input() groupModel: m5web.FormControlGroupEditViewModel;
  @Input() controlModel: m5web.FormControlEditViewModel;
  @Input() data: any;
  @Input() dataChangeCount: number = 0;
  @Input() formIsReadOnly: boolean = false;
  @Input() loading: boolean = false;
  @Input() working: boolean = false;

  @Input() designMode: boolean = false;
  @Input() designModeStyling: boolean = false;
  @Input() designModeControlActions: ButtonItem = null;

  @Input() contextResourceType: string = "";
  @Input() contextResourceId: number = 0;
  @Input() contextResourceId2: string = "";
  @Input() context: any = null;

  @Input() mode: "add" | "edit" | "view" | "list" = "edit"; // note "list" is rarely used except in custom components as that's usually part of our editor not form

  /**
  This is a pass through from group render and is incremented when the component hosting the form
  wants to signal a save is successful by incrementing the count.  The form will then decide how to
  act but will also pass this through to the group and the group to the control so groups and
  controls can decide what to do.  For example, we might be hosting a component that needs to save
  it's own data based on this event happening.
  */
  @Input() saveCount: number = 0;

  @Output() change: EventEmitter<EventModel> = new EventEmitter();
  @Output() status: EventEmitter<EventModel> = new EventEmitter();
  @Output() addSave: EventEmitter<EventModel> = new EventEmitter();
  @Output() addClose: EventEmitter<EventModel> = new EventEmitter();
  @Output() editSave: EventEmitter<EventModel> = new EventEmitter();
  @Output() editClose: EventEmitter<EventModel> = new EventEmitter();
  @Output() controlEvent: EventEmitter<EventModelTyped<m5web.FormControlEditViewModel>> = new EventEmitter();

  @Output() designModeEditControlProperties: EventEmitter<EventModel> = new EventEmitter();

  @ViewChild('hostComponent') hostComponentElement: ElementRef;
  dynamicComponentType: string = "";
  dynamicComponent: ComponentRef<any> = null;


  /**
   * controlModel.OptionsPickListFilter may contain macros when this is a cascading
   * pick list.  For example: select#1 asks for X.  select#2 has pick list filter
   * where Property = "{{X}}" and we need to dynamically resolve the macro based on
   * the value from select#1.
   */
  public optionsPickListFilter: string = "";
  protected isDynamicPickListFilter: boolean = false;

  protected assetTextCache = new Dictionary<string>();


  constructor(
    public formStatus: FormStatusService,
    protected translate: TranslationHelperService,
    protected apiService: ApiService,
    protected changeDetector: ChangeDetectorRef,
    protected dynamic: DynamicComponentService,
    protected dynamicFormService: DynamicFormService) {
    super();
  }

  // ngOnInit() {
  //   super.ngOnInit();
  // }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.controlModel) {
      this.optionsPickListFilter = this.controlModel.OptionsPickListFilter;
      this.isDynamicPickListFilter = Helper.contains(this.controlModel.OptionsPickListFilter, "{{", false);
      this.refreshOptionsPickListFilter();
      this.componentWireUp();
    }
    if (changes.data || changes.dataChangeCount) {
      this.refreshOptionsPickListFilter();
      // Always build and apply component inputs on data changes
      const inputs: any = this.componentInputsBuild();
      this.dynamic.componentApplyInputs(this.dynamicComponent, inputs, false);
    }
    if (changes.mode && this.componentInputsWatchingProperty("mode")) {
      // This value changed and it's a property that our dynamic component is watching so update our dynamic component inputs
      const inputs: any = this.componentInputsBuild();
      this.dynamic.componentApplyInputs(this.dynamicComponent, inputs, false);
    }
    if (changes.saveCount && this.componentInputsWatchingProperty("saveCount")) {
      // This value changed and it's a property that our dynamic component is watching so update our dynamic component inputs
      const inputs: any = this.componentInputsBuild();
      this.dynamic.componentApplyInputs(this.dynamicComponent, inputs, false);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.dynamicComponent) {
      this.dynamic.componentRemoveReference(this.dynamicComponent);
    }
  }


  refreshOptionsPickListFilter(): void {
    if (!this.isDynamicPickListFilter) {
      return;
    }
    this.optionsPickListFilter = HandlebarsHelpers.handlebarsTemplateResolve(this.controlModel.OptionsPickListFilter, this.data, `DynamicForm-${this.controlModel.ObjectFullName}-${this.controlModel.PropertyName}-${this.controlModel.OptionsPickListId}-${this.controlModel.OptionsPickListFilter}`);
    // console.error(this.controlModel.OptionsPickListFilter, this.optionsPickListFilter, this.data);
  }


  getInputControlName(): string {
    return FormHelper.getInputControlName(this.controlModel);
  }


  /**
   * If the control is for a component this wires up the component dynamically
   * assuming it's been registered with our dynamic component service either on
   * app start or by a dynamic form host which knows which components are appropriate
   * for the scenario.
   * @returns
   */
  componentWireUp() {
    if (!this.controlModel) {
      return;
    }
    if (!this.data) {
      return;
    }
    if (!Helper.equals(this.controlModel.ControlType, "Component", true)) {
      return;
    }
    if (!this.controlModel?.Contents) {
      console.error(`No component name specified to load for Control Id ${this.controlModel.FormControlId}.`);
      return;
    }
    if (this.dynamicComponent && this.dynamicComponentType === this.controlModel.Contents) {
      // Dynamic component is already wired up
      return;
    } else if (this.dynamicComponent) {
      // The dynamic component changed so remove the old one before we wire up a new one
      this.dynamic.componentRemoveReference(this.dynamicComponent);
    }
    // Prepare inputs for the component
    const inputs: any = this.componentInputsBuild();
    const eventBindings: { [eventName: string]: (eventData: any) => void } = {};

    // Call change detector so hostComponentElement is initialized since it's under an *ngSwitch component
    // it's not initialized automatically since it's not in scope until the *ngSwitch result is true.
    // See https://danieleyassu.com/angular-viewchild-and-ngif
    this.changeDetector.detectChanges();

    this.dynamicComponent = this.dynamic.componentGetReference(this.controlModel.Contents,
      [{ provide: FormStatusService, useValue: this.formStatus }],
      inputs,
      eventBindings,
      this.hostComponentElement);
    if (this.dynamicComponent) {
      this.dynamicComponentType = this.controlModel.Contents;
      // console.error('dyn comp', this.dynamicComponent);
    } else {
      if (this.designMode) {
        console.warn(`The designer was unable to load dynamic component "${this.controlModel.Contents}" for Control Id ${this.controlModel.FormControlId}.  The component may not be registered until you load the form that hosts it.`);
      } else {
        console.error(`Unable to load dynamic component "${this.controlModel.Contents}" for Control Id ${this.controlModel.FormControlId}.  The component may not be registered yet.`);
      }
    }

  }


  componentInputsBuild(): any {
    // Validate we're ready to proceed
    if (!this.controlModel) {
      return null;
    }
    if (!Helper.equals(this.controlModel.ControlType, "Component", true)) {
      return null;
    }
    // A common used input is data to submit so determine if it is our form composite data object
    // or, more likely, a child object on that data object as specified in the control model.
    let componentData: any = {};
    if (this.controlModel.ObjectName) {
      componentData = FormHelper.getInputControlObject(this.controlModel, this.data);
    } else {
      // console.warn(`Dynamic component "${this.controlModel.Contents}" for Control Id ${this.controlModel.FormControlId} has no object name set to using composite data object which is probably not correct.`);
      componentData = this.data;
    }
    // console.error("component data", componentData);
    // Prepare inputs for the component
    const inputs: any = this.dynamicFormService.buildDynamicComponentInputs(this.controlModel.Properties, componentData, this.mode, this.saveCount);
    // console.error("component inputs", inputs);
    return inputs;
  }

  /**
   * Some internal properties might be watched by component inputs via {{placeholder}}
   * references in which case we want to update the input properties but we don't want
   * to take that step if not needed and this method helps us know if that is needed.
   * @param propertyName
   * @returns
   */
  componentInputsWatchingProperty(propertyName: string): boolean {
    // Validate we're ready to proceed
    if (!this.controlModel) {
      return false;
    }
    if (!Helper.equals(this.controlModel.ControlType, "Component", true)) {
      return false;
    }
    if (!propertyName) {
      return false;
    }
    const json = JSON.stringify(this.controlModel.Properties);
    return Helper.contains(json, `{{${propertyName}}}`, true);
  }



  getContents(): string {

    let contents = this.controlModel.Contents;

    if (!contents && this.controlModel.ObjectName && this.controlModel.PropertyName) {
      // This is text from the data so we don't translate it.
      contents = this.getModel();
      if (this.controlModel.ControlType === "InputStatic" && !contents) {
        // Static controls aren't very happy with no content
        return contents || "&nbsp;";
      } else {
        return contents || "";
      }
    }

    if (!contents && this.controlModel.AssetId) {
      const query: any = { AssetId: this.controlModel.AssetId };
      if (this.contextResourceType) {
        query.ContextResourceType = this.contextResourceType;
      }
      if (this.contextResourceId) {
        query.ContextResourceId = this.contextResourceId;
      }
      if (this.contextResourceId2) {
        query.ContextResourceId2 = this.contextResourceId2;
      }
      if (this.context) {
        query.Context = JSON.stringify(this.context);
      }
      const cacheKey = `${this.controlModel.AssetId}-${JSON.stringify(query)}`;
      if (this.assetTextCache.containsKey(cacheKey)) {
        contents = this.assetTextCache.item(cacheKey);
      } else {
        // Push a temp empty string into cache to prevent multiple server submissions for this data
        this.assetTextCache.add(cacheKey, "");
        // Get the text from the server
        const apiProp = Api.AssetText();
        const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
        apiCall.cacheKey = cacheKey; // Use same cache key at api service layer
        this.apiService.execute(apiCall, query).subscribe((result: IApiResponseWrapperTyped<string>) => {
          if (result.Data.Success) {
            contents = result.Data.Data;
            this.assetTextCache.add(cacheKey, contents);
            if (!contents && this.designModeStyling) {
              // In design mode we often will not have any text from the server if we don't have the correct
              // context so put some placeholder there so we have something visible in the designer.
              if (Helper.equals(this.controlModel.ControlType, "HTML", true)) {
                contents = `<span class="badge bg-info">Asset Id ${this.controlModel.AssetId}</span>`;
              } else {
                contents = `Asset Id ${this.controlModel.AssetId}`;
              }
              this.assetTextCache.add(cacheKey, contents);
            }
          } else {
            // We may have an error in design mode due to lack of expected context.
            if (this.designModeStyling) {
              if (Helper.equals(this.controlModel.ControlType, "HTML", true)) {
                contents = `<span class="badge bg-info">Asset Id ${this.controlModel.AssetId}</span>`;
              } else {
                contents = `Asset Id ${this.controlModel.AssetId}`;
              }
              this.assetTextCache.add(cacheKey, contents);
            } else {
              console.error(result);
            }
          }
        });
      }
    }

    if (this.controlModel.ControlType === "InputStatic" && !contents) {
      // Static controls aren't very happy with no content
      contents = "&nbsp;";
    } else if (!contents) {
      return "";
    }

    // Translation will handle translation plus {{variable}} replacement provided it's a simple variable reference
    contents = this.translate.getTranslation(contents, this.data);

    // Any remaining {{}} macros need to be handled using handlebars as there may be helper functions, conditional expressions, etc.
    // that cannot be handled by the translation service as that only does simple value replacements.
    if (Helper.contains(contents, "{{")) {
      contents = HandlebarsHelpers.handlebarsTemplateResolve(contents, this.data, `FormControlId-${this.controlModel.FormControlId}`);
    }

    if (this.controlModel.ControlType === "InputStatic" && !contents) {
      // Static controls aren't very happy with no content
      contents = "&nbsp;";
    }

    return contents;

  }


  // /**
  //  * Determine if the control should be displayed or not.
  //  */
  // showControl(): boolean {

  //   if (this.designMode) {
  //     // When in design mode we want to see all the controls
  //     return true;
  //   }

  //   // Input controls that are not static must have an object name and property name before we can consider showing them
  //   if (Helper.startsWith(this.controlModel.ControlType, "Input", true) && !Helper.equals(this.controlModel.ControlType, "InputStatic", true)) {
  //     if (!this.controlModel.ObjectName) {
  //       return false;
  //     } else if (!this.controlModel.PropertyName) {
  //       return false;
  //     }
  //   }

  //   // If we have a display when macro expression let's evaluate it to figure out if we're going to display the control or not
  //   if (this.controlModel.DisplayWhenExpression && Helper.contains(this.controlModel.DisplayWhenExpression, "{{")) {
  //     let result = HandlebarsHelpers.handlebarsTemplateResolve(this.controlModel.DisplayWhenExpression, this.data, `FormControlId-${this.controlModel.FormControlId}-DisplayWhenExpression`);
  //     if (result && !Helper.equals(result, "false", true)) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }

  //   // Nobody said not to show it so we're showing it
  //   return true;

  // }


  getContactPickerData(): any {

    let contactId: number = null;
    let contactName: string = "";

    if (this.controlModel.ObjectGrandchildName) {
      contactId = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyName];
      if (this.controlModel.PropertyDescriptionName) {
        contactName = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyDescriptionName];
      }
    } else if (this.controlModel.ObjectChildName) {
      contactId = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyName];
      if (this.controlModel.PropertyDescriptionName) {
        contactName = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyDescriptionName];
      }
    } else {
      contactId = this.data[this.controlModel.ObjectName][this.controlModel.PropertyName];
      if (this.controlModel.PropertyDescriptionName) {
        contactName = this.data[this.controlModel.ObjectName][this.controlModel.PropertyDescriptionName];
      }
    }

    return { ContactId: contactId, ContactName: contactName, ContactType: "" };

  }


  getContactPickerContactTypes(): string[] {

    let contactTypes: string[] = [];

    if (this.controlModel.ControlProperties) {
      try {
        const properties: any = JSON.parse(this.controlModel.ControlProperties);
        if (properties) {
          if (properties.ContactTypes) {
            contactTypes = properties.ContactTypes;
          } else if (properties.contactTypes) {
            contactTypes = properties.contactTypes;
          }
        }
      } catch (err) {
        Log.errorMessage(err);
      }
    }

    return contactTypes;

  }


  getAssetPickerData(): any {

    let assetId: number = null;
    let assetTitle: string = "";

    if (this.controlModel.ObjectGrandchildName) {
      assetId = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyName];
      if (this.controlModel.PropertyDescriptionName) {
        assetTitle = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyDescriptionName];
      }
    } else if (this.controlModel.ObjectChildName) {
      assetId = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyName];
      if (this.controlModel.PropertyDescriptionName) {
        assetTitle = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyDescriptionName];
      }
    } else {
      assetId = this.data[this.controlModel.ObjectName][this.controlModel.PropertyName];
      if (this.controlModel.PropertyDescriptionName) {
        assetTitle = this.data[this.controlModel.ObjectName][this.controlModel.PropertyDescriptionName];
      }
    }

    return { AssetId: assetId, Title: assetTitle };

  }

  getModel(): any {
    // We need our data assigned before we can decide this
    if (!this.data) {
      return "";
    }
    return FormHelper.getInputControlValue(this.controlModel, this.data);
  }

  getDescriptionPropertyValue(): any {
    // We need our data assigned before we can decide this
    if (!this.data) {
      return "";
    }
    return FormHelper.getInputControlValue(this.controlModel, this.data, this.controlModel.PropertyDescriptionName);
  }

  onControlPrefixClick($event: any, control: m5web.FormControlEditViewModel) {
    const payload = new EventModelTyped<m5web.FormControlEditViewModel>("click-prefix", $event, control, new EventElementModel("input", this.controlModel.ObjectFullName, this.controlModel.PropertyName));
    this.controlEvent.emit(payload);
  }

  onControlSuffixClick($event: any, control: m5web.FormControlEditViewModel) {
    const payload = new EventModelTyped<m5web.FormControlEditViewModel>("click-suffix", $event, control, new EventElementModel("input", this.controlModel.ObjectFullName, this.controlModel.PropertyName));
    this.controlEvent.emit(payload);
  }

  onControlSuffix2Click($event: any, control: m5web.FormControlEditViewModel) {
    const payload = new EventModelTyped<m5web.FormControlEditViewModel>("click-suffix2", $event, control, new EventElementModel("input", this.controlModel.ObjectFullName, this.controlModel.PropertyName));
    this.controlEvent.emit(payload);
  }

  onModelChange($event) {
    if (this.controlModel.ObjectGrandchildName) {
      if ($event && $event.data !== undefined) {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyName] = $event.data;
      } else {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyName] = $event;
      }
    } else if (this.controlModel.ObjectChildName) {
      if ($event && $event.data !== undefined) {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyName] = $event.data;
      } else {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyName] = $event;
      }
    } else {
      if ($event && $event.data !== undefined) {
        this.data[this.controlModel.ObjectName][this.controlModel.PropertyName] = $event.data;
      } else {
        this.data[this.controlModel.ObjectName][this.controlModel.PropertyName] = $event;
      }
    }
    // console.error($event);
    // console.error(this.data);
    const payload: EventModel = new EventModel("change", $event, this.data, new EventElementModel("input", this.controlModel.ObjectFullName, this.controlModel.PropertyName));
    this.change.emit(payload);
  }

  onStatusChange($event) {
    // console.error("control onStatusChange", $event);
    // console.error($event)
    // $event is already in our EventModel format
    this.status.emit($event);
  }

  onContactSelected($event) {
    if (this.controlModel.ObjectGrandchildName) {
      this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyName] = $event.data.ContactId;
      if (this.controlModel.PropertyDescriptionName) {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyDescriptionName] = $event.data.ContactName;
      }
    } else if (this.controlModel.ObjectChildName) {
      this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyName] = $event.data.ContactId;
      if (this.controlModel.PropertyDescriptionName) {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyDescriptionName] = $event.data.ContactName;
      }
    } else {
      this.data[this.controlModel.ObjectName][this.controlModel.PropertyName] = $event.data.ContactId;
      if (this.controlModel.PropertyDescriptionName) {
        this.data[this.controlModel.ObjectName][this.controlModel.PropertyDescriptionName] = $event.data.ContactName;
      }
    }
    const payload: EventModel = new EventModel("change", $event, this.data, new EventElementModel("input", this.controlModel.ObjectFullName, this.controlModel.PropertyName));
    this.change.emit(payload);
    this.status.emit($event);
  }

  onAssetSelected($event) {
    if (this.controlModel.ObjectGrandchildName) {
      this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyName] = $event.data.AssetId;
      if (this.controlModel.PropertyDescriptionName) {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyDescriptionName] = $event.data.Title;
      }
    } else if (this.controlModel.ObjectChildName) {
      this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyName] = $event.data.AssetId;
      if (this.controlModel.PropertyDescriptionName) {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyDescriptionName] = $event.data.Title;
      }
    } else {
      this.data[this.controlModel.ObjectName][this.controlModel.PropertyName] = $event.data.AssetId;
      if (this.controlModel.PropertyDescriptionName) {
        this.data[this.controlModel.ObjectName][this.controlModel.PropertyDescriptionName] = $event.data.Title;
      }
    }
    const payload: EventModel = new EventModel("change", $event, this.data, new EventElementModel("input", this.controlModel.ObjectFullName, this.controlModel.PropertyName));
    this.change.emit(payload);
    this.status.emit($event);
  }


  onAddSave($event) {
    if (this.formStatus && !this.formStatus.isValid) {
      return;
    }
    if (this.working) {
      return;
    }
    // Build in our expected format and attach data
    const controlId = `FormControlId-${this.controlModel.FormControlId}`;
    const controlName = this.controlModel.ControlType;
    const model: EventModel = new EventModel("addSave", $event, this.data, new EventElementModel("button", controlId, controlName));
    this.addSave.emit(model);
  }

  onAddClose($event) {
    // Build in our expected format and attach data
    const controlId = `FormControlId-${this.controlModel.FormControlId}`;
    const controlName = this.controlModel.ControlType;
    const model: EventModel = new EventModel("addClose", $event, this.data, new EventElementModel("button", controlId, controlName));
    this.addClose.emit(model);
  }

  onEditSave($event) {
    if (this.formStatus && !this.formStatus.isValid) {
      return;
    }
    if (this.working) {
      return;
    }
    // Build in our expected format and attach data
    const controlId = `FormControlId-${this.controlModel.FormControlId}`;
    const controlName = this.controlModel.ControlType;
    const model: EventModel = new EventModel("editSave", $event, this.data, new EventElementModel("button", controlId, controlName));
    this.editSave.emit(model);
  }

  onEditClose($event) {
    // Build in our expected format and attach data
    const controlId = `FormControlId-${this.controlModel.FormControlId}`;
    const controlName = this.controlModel.ControlType;
    const model: EventModel = new EventModel("editClose", $event, this.data, new EventElementModel("button", controlId, controlName));
    this.editClose.emit(model);
  }


  onEnterKey($event) {
    // We need SubmitOnEnter flag on the form for this to be an option
    if (!this.formModel?.Flags) {
      return;
    }
    const submitOnEnter: boolean = this.formModel.Flags.some(x => Helper.equals(x, "SubmitOnEnter", true));
    // We have SubmitOnEnter flag so we can fire off add or edit save action based on form mode
    if (submitOnEnter) {
      if (this.mode === 'add' && (this.formModel.AddSaveButtonLabel || this.formModel.AddSaveButtonIcon)) {
        this.onAddSave($event);
      } else if (this.mode !== 'add' && (this.formModel.EditSaveButtonLabel || this.formModel.EditSaveButtonIcon)) {
        this.onEditSave($event);
      }
    }
  }

  onDesignModeEditControlProperties(event: any) {
    const cargo: any = { group: this.groupModel, control: this.controlModel };
    const payload: EventModel = new EventModel("designModeEditControlProperties", event, this.controlModel, new EventElementModel("control", (this.controlModel.FormControlId || 0).toString(), this.controlModel.Description), cargo);
    this.designModeEditControlProperties.emit(payload);
  }

}
