import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'emailLink'
})
export class EmailLinkPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }

  public transform(value: any, subject: string = "", body: string = ""): SafeHtml {

    if (!value) {
      return this.sanitizer.bypassSecurityTrustHtml("");
    }

    let html: string = "";
    if (subject && body) {
      html = `<a href="mailto:${value}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}">${value}</a>`;
    } else if (subject) {
      html = `<a href="mailto:${value}?subject=${encodeURIComponent(subject)}">${value}</a>`;
    } else if (body) {
      html = `<a href="mailto:${value}?body=${encodeURIComponent(body)}">${value}</a>`;
    } else {
      html = `<a href="mailto:${value}">${value}</a>`;
    }

    return this.sanitizer.bypassSecurityTrustHtml(html);

  }

  // transform(value: any, args?: any): any {
  //  return null;
  // }

}
