import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CommonDataEditorOptions, EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { FormStatusModel } from 'projects/core-lib/src/lib/models/form-status-model';

@Component({
  selector: 'ib-data-editor-header',
  templateUrl: './data-editor-header.component.html',
  styleUrls: ['./data-editor-header.component.css']
})
export class DataEditorHeaderComponent implements OnInit, OnChanges, OnDestroy {

  @Input() mode: "list" | "add" | "edit" | "view" | "download" | "upload" | string;
  @Input() options: CommonDataEditorOptions;
  /**
   * Sometimes our action button may have options that are visible or not based on current state of the data
   * so if the data editor passes in the data object to us we can pass that along to the action button.
   */
  @Input() data: any;
  /**
   * Sometimes our action button may have options that are visible or not based on current state of the cargo
   * so if the data editor passes in the cargo object to us we can pass that along to the action button.
   */
  @Input() cargo: any;
  @Input() objectDescription: string;
  @Input() formStatus: FormStatusModel;
  @Input() working: boolean = false;
  @Output() save: EventEmitter<EventModel> = new EventEmitter();

  protected titleWasChanged: boolean = false;
  objectDescriptionShort: string = "";

  constructor(protected appService: AppService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // If configured to update doc title based on our form header then let's set that now.
    if (this.options && this.options.documentTitleMirrorHeaderText) {
      if (this.mode === "list" && this.options.headerTextListMode) {
        this.appService.title = this.options.headerTextListMode;
        this.titleWasChanged = true;
      } else if (this.mode === "add" && this.options.headerTextAddMode) {
        this.appService.title = this.options.headerTextAddMode;
        this.titleWasChanged = true;
      } else if ((this.mode === "edit" || this.mode === "view") && this.objectDescription) {
        this.appService.title = this.objectDescription;
        this.titleWasChanged = true;
      }
    }

    if ((this.mode === "edit" || this.mode === "view") && this.objectDescription) {
      this.objectDescriptionShort = this.objectDescription; // Helper.left(this.objectDescription, 75, true);
    }
  }


  ngOnDestroy() {
    // If we've been jacking with the doc title then reset it now
    if (this.titleWasChanged) {
      this.appService.titleReset();
    }
  }


  public fireSave($event) {
    // Do not emit any click events when the button is disabled
    if (this.formStatus && this.formStatus.isValid && !this.formStatus.isPristine) {
      const payload: EventModel = new EventModel("click", event, true, new EventElementModel("button", null, "Save"));
      this.save.emit(payload);
    }
    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // If we used different event names then it wouldn't be an issue but using these event names is more developer friendly.
      event.stopPropagation();
      event.preventDefault();
    } catch (err) {
      // Log.errorMessage(err);
    }
  }


}
