import { Pipe, PipeTransform } from '@angular/core';
import { ApiService } from '../api/api.service';
import { of, Observable, AsyncSubject } from 'rxjs';
import { Helper } from '../helpers/helper';

@Pipe({
  name: 'pickListText'
})
export class PickListTextPipe implements PipeTransform {

  constructor(protected apiService: ApiService) { }

  // Must use with async pipe data.Value | pickListText : "PickListId" | async

  public transform(value: any, pickListId: string): Observable<string> {

    // console.error("get pick list text", value, pickListId);

    if (Helper.isUndefinedOrNull(value)) {
      return of("");
    }
    if (!pickListId) {
      return of(value);
    }

    const subject = new AsyncSubject<string>();

    this.apiService.loadPickList(pickListId).subscribe(result => {
      if (result.Data.Success) {
        const match = Helper.firstOrDefault(result.Data.Data, x => Helper.equals(x.Value, value.toString(), true));
        if (match) {
          // console.error("found match", match);
          subject.next(match.DisplayText || value);
          subject.complete();
        } else {
          // console.error("no match", value , result.Data.Data);
          subject.next(value);
          subject.complete();
        }
      } else {
        subject.next(value);
        subject.complete();
      }
    });

    return subject.asObservable();

  }


}
