import { Component } from '@angular/core';
import { NavService } from 'projects/common-lib/src/lib/ux/nav/nav.service';
import { AppStyles } from 'projects/core-lib/src/lib/config/AppConfig';
import { ConfigHelpers } from 'projects/core-lib/src/lib/helpers/config-helpers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  appStyle: AppStyles = "standard";

  constructor(public nav: NavService) {
    this.appStyle = ConfigHelpers.getAppStyle();
  }

}
