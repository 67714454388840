import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { format, getDate, getMonth, getYear } from 'date-fns';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";

@Injectable({
  providedIn: 'root'
})
export class NgbDateIsoStringAdapterService extends NgbDateAdapter<string> {

  constructor() {
    super();
  }

  // const date: NgbDateStruct = { day: 14, month: 7, year: 1789 }; // July, 14 1789

  // from your model -> internal model
  /**
   * Convert from ISO date time string to NgbDateStruct object.
   * @param value
   */
  fromModel(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }
    const d = new Date(value);
    return { year: getYear(d), month: getMonth(d) + 1, day: getDate(d) };
  }

  // from internal model -> your mode
  /**
   * Convert from NgbDateStruct object to ISO date time string
   * @param date
   */
  toModel(date: NgbDateStruct): string {
    if (!date) {
      return null;
    }
    const d = new Date();
    d.setFullYear(date.year);
    d.setMonth(date.month - 1);
    d.setDate(date.day);
    d.setHours(12);// go mid-day to avoid timezone shifts of date.  Inside input control the time gets saved separately as it's separate inputs.
    // m.year(date.year);
    // m.month(date.month - 1);
    // m.date(date.day);
    // m.hour(12);
    // TODO NEEDS TESTING
    return format(d, Constants.DateFormat.FnsIsoDateTime);
  }

}
