import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { Helper, Log } from "projects/core-lib/src/lib/helpers/helper";
import { PlacementArray } from "@ng-bootstrap/ng-bootstrap/util/positioning";
import { EventEmitter, QueryList, TemplateRef } from "@angular/core";
import { StaticPickList } from "projects/core-lib/src/lib/models/model-helpers";


export class TableColumnOptions {

  /**
   * An optional id that represents this column when an id reference is needed.
   */
  public tableColumnId: string = "";

  public propertyName: string = "";

  /**
   * A property name that supports the primary property name.  This could be a
   * currency code for an amount, a name for an id, etc.
   */
  public supportingPropertyName: string = "";

  public headerIcon: string = "";

  public header: string = "";

  public headerTooltip: string = "";

  public visible: boolean = true;

  public sortable: boolean = true;

  public resizable: boolean = true;

  public editable: boolean = true;

  public wrap: boolean = true;

  public allowLineBreaks: boolean = false;

  public allowHtml: boolean = false;

  public dataType: string = "string"; // unknown, string, boolean, number, int, float, currency, date, time, datetime, picklist, icon, email, avatar, function, pie-chart

  public decimals: number = undefined;

  /**
   * If there is a maximum number of characters to show it should be set here.  Text exceeding this will be truncated with ellipsis.
   */
  public maxCharacterLength: number = 0;

  /**
   * If the maximum number of characters is exceeded and a tooltip should be used to indicate the full content this should be true.
   */
  public toolTipWhenMaxCharacterLengthExceeded: boolean = true;

  public align: "left" | "center" | "right" = "left";



  public toolTipType: "none" | "tooltip" | "popover" = "none";

  public toolTipTitle: string = "";

  public toolTipText: string = "";

  public toolTipTitleFunction: (row: any) => any;

  public toolTipTextFunction: (row: any) => any;

  public toolTipAppendToBody: boolean = true;

  public toolTipPlacement: string | PlacementArray = "auto";



  public width: string = "";

  public footerHtml: string = "";

  /**
   * If the table sortMode is "none" or this column is not marked as sortable and a url is provided then header click will
   * route to the specified url.  The url can contain the following macros: {{ClickedPropertyName}} or {{ClickedHeaderLabel}}.
   * This will override the headerClickUrl setting in table options so different columns can go to a different url.
   */
  public headerClickUrl: string = "";

  public headerClickUrlNewWindow: boolean = false;

  /**
   * When provided a row click will route to the specified url.  The url can contain {{macros}} that map
   * properties found on the row or static values {{ClickedPropertyName}} or {{ClickedHeaderLabel}}.
   * This will override the rowClickUrl setting in table options so different columns can go to a different url.
   */
  public rowClickUrl: string = "";

  public rowClickUrlNewWindow: boolean = false;

  /**
  When dataType is "function" this is the function to execute for rendering output in a table.
  When dataType is a chart (e.g. "pie-chart") this is the function to execute to get the data to chart.
  The row object is passed in as input parameters.
  */
  public render: (row: any) => any;

  /**
   * Some rendering might be done with OnPush change detection which means late rendered items like text
   * and icons from pick lists may not get displayed properly.  If this is true the rendering will attempt
   * a late refresh of the render output to accommodate for late rendered items.
   */
  public supportLateRender: boolean = false;

  public style: string = "";

  public getStyle: (data: any, row: any) => string;

  /**
  If pick list id is specified it will be loaded into pick list array and used for input and output in the table.
  */
  public optionsPickListId: string = "";
  /**
  If pick list is specified it will be used for input and output in the table.
  */
  public optionsPickList: m5core.PickListSelectionViewModel[] = [];
  public optionsIncludeNone: boolean = true;
  public optionsNoneLabel: string = "<None>";

  public includeInGlobalFilter: boolean = true;

  public filterType: "none" | "text" | "select" | "multiselect" = "text";  // | "daterange"

  public filterMatchMode: string = "contains"; // Available match modes are "startsWith", "contains", "endsWith", "equals", "notEquals", "in", "lt", "lte", "gt" and "gte".

  // public filterDateRange: string = "CUSTOM";

  // public filterDateValue1: string = "";

  // public filterDateValue2: string = "";

  public filterValue: string = "";

  public filterPickListId: string = "";

  public filterPickListValues: m5core.PickListSelectionViewModel[] = [];

  public filterSelections: string[] = [];

  constructor(propertyName: string = "", header: string = "", dataType: string = "", pickListId: string = "") {
    this.propertyName = propertyName;
    if (header) {
      this.header = header;
    } else if (propertyName) {
      this.header = Helper.formatIdentifierWithSpaces(propertyName);
    }
    if (dataType) {
      this.dataType = dataType;
    }
    if (Helper.equals(dataType, "pie-chart", true)) {
      this.headerIcon = "chart-pie-alt";
      this.filterType = "none";
      this.sortable = false;
    }
    if (Helper.equals(dataType, "boolean", true)) {
      this.filterPickListValues = StaticPickList.stringItemsToPickList("True", "False");
      this.filterType = "multiselect";
    }
    if (pickListId) {
      this.optionsPickListId = pickListId;
      this.dataType = "picklist";
      this.filterPickListId = pickListId;
      this.filterType = "multiselect";
      // if (!dataType) {
      //  this.dataType = "picklist";
      // }
    }
  }

}



/**
 * PrimeNG used to have a Column class but that seemed to have disappeared in v9
 **/
export class PrimeColumn { // export declare class PrimeColumn {
  field: string;
  colId: string;
  sortField: string;
  filterField: string;
  header: string;
  footer: string;
  sortable: any;
  editable: boolean;
  filter: boolean;
  filterMatchMode: string;
  filterType: string;
  excludeGlobalFilter: boolean;
  rowspan: number;
  colspan: number;
  scope: string;
  style: any;
  styleClass: string;
  exportable: boolean;
  headerStyle: any;
  headerStyleClass: string;
  bodyStyle: any;
  bodyStyleClass: string;
  footerStyle: any;
  footerStyleClass: string;
  hidden: boolean;
  expander: boolean;
  selectionMode: string;
  filterPlaceholder: string;
  filterMaxlength: number;
  frozen: boolean;
  options: TableColumnOptions;
  resizable: boolean;
  sortFunction: EventEmitter<any>;
  templates: QueryList<any>;
  template: TemplateRef<any>;
  headerTemplate: TemplateRef<any>;
  bodyTemplate: TemplateRef<any>;
  footerTemplate: TemplateRef<any>;
  filterTemplate: TemplateRef<any>;
  editorTemplate: TemplateRef<any>;
}



/* Deprecated */
// @Component({
//  selector: 'p-column',
//  template: ''
// })
// export class Column implements AfterContentInit {
//  @Input() field: string;
//  @Input() colId: string;
//  @Input() sortField: string;
//  @Input() filterField: string;
//  @Input() header: string;
//  @Input() footer: string;
//  @Input() sortable: any;
//  @Input() editable: boolean;
//  @Input() filter: boolean;
//  @Input() filterMatchMode: string;
//  @Input() filterType: string = 'text';
//  @Input() excludeGlobalFilter: boolean;
//  @Input() rowspan: number;
//  @Input() colspan: number;
//  @Input() scope: string;
//  @Input() style: any;
//  @Input() styleClass: string;
//  @Input() exportable: boolean = true;
//  @Input() headerStyle: any;
//  @Input() headerStyleClass: string;
//  @Input() bodyStyle: any;
//  @Input() bodyStyleClass: string;
//  @Input() footerStyle: any;
//  @Input() footerStyleClass: string;
//  @Input() hidden: boolean;
//  @Input() expander: boolean;
//  @Input() selectionMode: string;
//  @Input() filterPlaceholder: string;
//  @Input() filterMaxlength: number;
//  @Input() frozen: boolean;
//  @Input() resizable: boolean = true;
//  @Output() sortFunction: EventEmitter<any> = new EventEmitter();
//  @ContentChildren(PrimeTemplate) templates: QueryList<any>;
//  @ContentChild(TemplateRef, { static: false }) template: TemplateRef<any>;

//  public headerTemplate: TemplateRef<any>;
//  public bodyTemplate: TemplateRef<any>;
//  public footerTemplate: TemplateRef<any>;
//  public filterTemplate: TemplateRef<any>;
//  public editorTemplate: TemplateRef<any>;

//  ngAfterContentInit(): void {
//    this.templates.forEach((item) => {
//      switch (item.getType()) {
//        case 'header':
//          this.headerTemplate = item.template;
//          break;

//        case 'body':
//          this.bodyTemplate = item.template;
//          break;

//        case 'footer':
//          this.footerTemplate = item.template;
//          break;

//        case 'filter':
//          this.filterTemplate = item.template;
//          break;

//        case 'editor':
//          this.editorTemplate = item.template;
//          break;

//        default:
//          this.bodyTemplate = item.template;
//          break;
//      }
//    });
//  }
// }
