import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { Log, Helper } from "projects/core-lib/src/lib/helpers/helper";
import { FormHelper } from "projects/common-lib/src/lib/form/form-helper";
import { RouterPreloader } from "@angular/router";

export class FormGroupFluentModel extends m5web.FormControlGroupEditViewModel {

  constructor(style: string, description: string = "", classes: string = "") {
    super();
    if (Helper.equals(style, "tabset", true)) {
      this.PresentationStyle = "S";
    } else {
      this.PresentationStyle = Helper.left(style, 1, false).toUpperCase();
    }
    this.FormControlGroupId = Helper.randomInteger(true);
    this.Label = description;
    this.Description = description;
    this.GroupClasses = classes;
    this.Enabled = true;
    this.GroupType = "G";
    this.GroupScope = "B";
  }

  public HasChildGroup(style: string, description: string = "", classes: string = ""): FormGroupFluentModel {
    this.Groups.push(new FormGroupFluentModel(style, description, classes));
    return this;
  }

  public HasDescendantGroup(style: string, description: string = "", classes: string = ""): FormGroupFluentModel {
    this.AddDescendantGroup(null, style, description, classes);
    return this;
  }

  protected AddDescendantGroup(group: m5web.FormControlGroupEditViewModel, style: string, description: string, classes: string): void {

    // First call passes null as start of group search
    if (!group) {
      group = this;
    }

    // If this group has child groups then make recursive call to the last child group
    if (group.Groups.length > 0) {
      this.AddDescendantGroup(group.Groups.slice(-1)[0], style, description, classes);
      return;
    }

    group.Groups.push(new FormGroupFluentModel(style, description, classes));
    return;

  }

  public HasDescendantSiblingGroup(style: string, description: string = "", classes: string = ""): FormGroupFluentModel {
    this.AddDescendantSiblingGroup(null, style, description, classes);
    return this;
  }

  protected AddDescendantSiblingGroup(group: m5web.FormControlGroupEditViewModel, style: string, description: string, classes: string): void {

    // First call passes null as start of group search
    if (!group) {
      group = this;
    }

    // If this group has child groups then make recursive call to the last child group
    if (group.Groups.length > 0) {
      const child = group.Groups.slice(-1)[0];
      // If child has no children then this is our sibling
      if (child.Groups.length === 0) {
        group.Groups.push(new FormGroupFluentModel(style, description, classes));
      } else {
        this.AddDescendantSiblingGroup(child, style, description, classes);
      }
      return;
    }

    group.Groups.push(new FormGroupFluentModel(style, description, classes));
    return;

  }


  public HasText(text: string, type: "1" | "2" | "3" | "4" | "5" | "6" | "P" = "P", width: string = "F", containerClasses: string = ""): FormGroupFluentModel {
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddStatic(null, text, "Text", type, width, containerClasses);
    return this;
  }

  public HasHtml(html: string, width: string = "F", containerClasses: string = ""): FormGroupFluentModel {
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddStatic(null, html, "HTML", "", width, containerClasses);
    return this;
  }

  public HasInputStatic(label: string, watermark: string, objectName: string, propertyName: string, width: string = "W", required: boolean = false, minLength: number = undefined, maxLength: number = undefined): FormGroupFluentModel {
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddInput(null, label, watermark, objectName, propertyName, "InputStatic", width, required, minLength, maxLength, "");
    return this;
  }

  public HasInputString(label: string, watermark: string, objectName: string, propertyName: string, width: string = "W", required: boolean = false, minLength: number = undefined, maxLength: number = undefined): FormGroupFluentModel {
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddInput(null, label, watermark, objectName, propertyName, "InputText", width, required, minLength, maxLength, "");
    return this;
  }

  public HasInputLongString(label: string, watermark: string, objectName: string, propertyName: string, rows: number, width: string = "W", required: boolean = false, minLength: number = undefined, maxLength: number = undefined): FormGroupFluentModel {
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddInput(null, label, watermark, objectName, propertyName, "InputTextArea", width, required, minLength, maxLength, "", "", "", "", rows);
    return this;
  }

  public HasInputNumber(label: string, watermark: string, objectName: string, propertyName: string, width: string = "M", required: boolean = false, minLength: number = undefined, maxLength: number = undefined): FormGroupFluentModel {
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddInput(null, label, watermark, objectName, propertyName, "InputNumber", width, required, minLength, maxLength, "");
    return this;
  }

  public HasInputDateTime(label: string, watermark: string, objectName: string, propertyName: string, width: string = "W", required: boolean = false): FormGroupFluentModel {
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddInput(null, label, watermark, objectName, propertyName, "InputDateTime", width, required, 0, 0, "", "DateTime");
    return this;
  }

  public HasInputDate(label: string, watermark: string, objectName: string, propertyName: string, width: string = "W", required: boolean = false): FormGroupFluentModel {
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddInput(null, label, watermark, objectName, propertyName, "InputDateTime", width, required, 0, 0, "", "Date");
    return this;
  }

  public HasInputTime(label: string, watermark: string, objectName: string, propertyName: string, width: string = "W", required: boolean = false): FormGroupFluentModel {
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddInput(null, label, watermark, objectName, propertyName, "InputDateTime", width, required, 0, 0, "", "Time");
    return this;
  }

  public HasInputContact(label: string, watermark: string, objectName: string, propertyName: string, propertyDescriptionName: string, contactTypes: string[], width: string = "W", required: boolean = false): FormGroupFluentModel {
    const properties: any = { ContactTypes: contactTypes };
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddInput(null, label, watermark, objectName, propertyName, "InputContact", width, required, 0, 0, "", "", propertyDescriptionName, properties);
    return this;
  }

  public HasInputAsset(label: string, watermark: string, objectName: string, propertyName: string, propertyDescriptionName: string, width: string = "W", required: boolean = false): FormGroupFluentModel {
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddInput(null, label, watermark, objectName, propertyName, "InputAsset", width, required, 0, 0, "", "", propertyDescriptionName);
    return this;
  }

  public HasInputSelect(label: string, objectName: string, propertyName: string, pickListId: string, width: string = "W", required: boolean = false, pickList: m5core.PickListSelectionViewModel[] = null): FormGroupFluentModel {
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddInput(null, label, "", objectName, propertyName, "InputSelect", width, required, 0, 0, pickListId);
    if (pickList) {
      const control = this.LastControl();
      if (control) {
        control.OptionsPickList = pickList;
      }
    }
    return this;
  }

  public HasInputMultiSelect(label: string, objectName: string, propertyName: string, pickListId: string, width: string = "W", required: boolean = false, pickList: m5core.PickListSelectionViewModel[] = null): FormGroupFluentModel {
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddInput(null, label, "", objectName, propertyName, "InputMultiSelect", width, required, 0, 0, pickListId);
    if (pickList) {
      const control = this.LastControl();
      if (control) {
        control.OptionsPickList = pickList;
      }
    }
    return this;
  }

  public HasInputRadio(label: string, objectName: string, propertyName: string, pickListId: string, width: string = "W", required: boolean = false, pickList: m5core.PickListSelectionViewModel[] = null): FormGroupFluentModel {
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddInput(null, label, "", objectName, propertyName, "InputRadio", width, required, 0, 0, pickListId);
    if (pickList) {
      const control = this.LastControl();
      if (control) {
        control.OptionsPickList = pickList;
      }
    }
    return this;
  }

  public HasInputCheckbox(label: string, objectName: string, propertyName: string): FormGroupFluentModel {
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddInput(null, label, "", objectName, propertyName, "InputCheckbox", "W", false, 0, 0, "");
    return this;
  }

  public HasInputOther(label: string, watermark: string, objectName: string, propertyName: string, controlType: string, width: string = "W", required: boolean = false, minLength: number = undefined, maxLength: number = undefined): FormGroupFluentModel {
    // Add to last group (method will make as many recursive calls to get to last embedded group)
    this.AddInput(null, label, watermark, objectName, propertyName, controlType, width, required, minLength, maxLength, "");
    return this;
  }

  public SetLastControlAsReadOnly() {
    const control = this.LastControl();
    if (control) {
      control.ReadOnly = true;
    }
  }

  public LastGroup(group: m5web.FormControlGroupEditViewModel = null): FormGroupFluentModel {
    if (!group) {
      group = this;
    }
    // If this group has child groups then make recursive call to the last child group
    if (group.Groups.length > 0) {
      return group.Groups.slice(-1)[0] as FormGroupFluentModel;
    }
    return null;
  }

  public LastControl(group: m5web.FormControlGroupEditViewModel = null): m5web.FormControlEditViewModel {
    if (!group) {
      group = this;
    }
    // If this group has child groups then make recursive call to the last child group
    if (group.Groups.length > 0) {
      return this.LastControl(group.Groups.slice(-1)[0]);
    }
    if (group.Controls.length === 0) {
      return null;
    }
    return group.Controls.slice(-1)[0];
  }


  public FindControl(objectName: string, propertyName: string, group: m5web.FormControlGroupEditViewModel = null): m5web.FormControlEditViewModel {
    if (!group) {
      group = this;
    }
    let match: m5web.FormControlEditViewModel = null;
    if (group.Controls) {
      // every is like foreach where return false = break and return true = continue (required or will break)
      group.Controls.every(control => {
        if (Helper.equals(control.ObjectName, objectName, true) && Helper.equals(control.PropertyName, propertyName, true)) {
          match = control;
          return false; // break
        }
        return true; // continue
      });
    }
    if (match) {
      return match;
    }
    if (group.Groups) {
      // every is like foreach where return false = break and return true = continue (required or will break)
      group.Groups.every(child => {
        match = this.FindControl(objectName, propertyName, child);
        if (match) {
          return false; // break
        }
        return true; // continue
      });
    }
    return match;
  }


  protected AddInput(group: m5web.FormControlGroupEditViewModel, label: string, watermark: string, objectName: string, propertyName: string, controlType: string, width: string, required: boolean, minLength: number, maxLength: number, pickListId: string, dataType: string = "", propertyDescriptionName: string = "", controlProperties: any = {}, height: number = 0): void {

    // First call passes null as start of group search
    if (!group) {
      group = this;
    }

    // If this group has child groups then make recursive call to the last child group
    if (group.Groups.length > 0) {
      this.AddInput(group.Groups.slice(-1)[0], label, watermark, objectName, propertyName, controlType, width, required, minLength, maxLength, pickListId, dataType, propertyDescriptionName, controlProperties, height);
      return;
    }

    const control = new m5web.FormControlEditViewModel();
    control.FormControlId = Helper.randomInteger(true);
    control.FormControlGroupId = group.FormControlGroupId;
    control.ControlType = "InputText";
    control.Required = required;
    control.IncludeRequiredIcon = required;
    control.Minimum = minLength;
    control.Maximum = maxLength;
    control.Label = label;
    control.Watermark = watermark;
    control.ObjectName = objectName;
    control.PropertyName = propertyName;
    control.PropertyDescriptionName = propertyDescriptionName;
    control.PropertyDataType = "string";
    control.ControlProperties = JSON.stringify(controlProperties);
    control.Width = width;
    control.Height = height;
    control.OptionsPickListId = pickListId;
    control.OptionsIncludeNone = true;
    control.OptionsNoneLabel = "<None>";
    if (dataType) {
      control.PropertyDataType = dataType;
    } else if (controlType) {
      control.PropertyDataType = FormHelper.fromControlTypeToDataType(controlType);
    }
    if (controlType) {
      control.ControlType = controlType;
    }

    group.Controls.push(control);
    return;

  }

  protected AddStatic(group: m5web.FormControlGroupEditViewModel, contents: string, controlType: string, controlOption: string, width: string, containerClasses: string): void {

    // First call passes null as start of group search
    if (!group) {
      group = this;
    }

    // If this group has child groups then make recursive call to the last child group
    if (group.Groups.length > 0) {
      this.AddStatic(group.Groups.slice(-1)[0], contents, controlType, controlOption, width, containerClasses);
      return;
    }

    const control = new m5web.FormControlEditViewModel();
    control.Contents = contents;
    control.ControlType = controlType;
    control.ControlOption = controlOption;
    control.Width = width;
    control.PropertyDataType = "string";
    control.ContainerClasses = containerClasses;

    group.Controls.push(control);
    return;

  }

  /*
    table.AddColumn( "PropertyDataType" , "VarChar(100)" , "'String'" , ColumnProperty.None , "Property Data Type" ,
                    "The data type of this property.  Possible values include:" + Environment.NewLine +
                    //"Inferred = Inferred from object and property" + Environment.NewLine +
                    "String = String" + Environment.NewLine +
                    "Integer = Integer" + Environment.NewLine +
                    "Float = Float" + Environment.NewLine +
                    //"Number = Number (not known if integer or floating point)" + Environment.NewLine +
                    "DateTime = DateTime" + Environment.NewLine +
                    "Date = Date" + Environment.NewLine +
                    "Time = Time" + Environment.NewLine +
                    "Boolean = Boolean" + Environment.NewLine +
                    "ListString = List of Strings" + Environment.NewLine +
                    "ListObject = List of Objects" + Environment.NewLine +
                    "Object = Object" + Environment.NewLine +
                    "Other = Other" + Environment.NewLine +
                    "Unknown = Unknown" );
    table.AddColumn( "ControlType" , "VarChar(100)" , "'InputText'" , ColumnProperty.None , "Control Type" ,
                    "Type of control.  Possible options include:" + Environment.NewLine +
                    //"Standard = Standard (The standard control for the data type defined by the property data type.)" + Environment.NewLine +
                    "Text = Text (Text from the contents value or asset id.)" + Environment.NewLine +
                    "HTML = HTML (HTML text from the contents value or asset id.)" + Environment.NewLine +
                    "Image = Image (Image from the asset id or asset url.)" + Environment.NewLine +
                    "Video = Video (Video from the asset id or asset url.)" + Environment.NewLine +
                    "Attachment = Attachment (Attachment to be associated with the specified object.)" + Environment.NewLine +
                    "FormButtons = Form Buttons (Buttons defined for the form - e.g. Save and Cancel buttons.)" + Environment.NewLine +
                    "Button = Button (Button to execute the click event.  Button text is taken from contents value.  " +
                                "Save buttons are automatically added for the form and are not needed as a control.)" + Environment.NewLine +
                    "Form = Form (An embedded form referenced in the embedded form id.)" + Environment.NewLine +
                    "InputText = Text Input (A single line text input.)" + Environment.NewLine +
                    "InputNumber = Number Input (A numeric input.)" + Environment.NewLine +
                    "InputTags = Tags Input (Tags for the specified object.)" + Environment.NewLine +
                    "InputTextArea = Text Area Input (A multiple line text area input.)" + Environment.NewLine +
                    "InputSelect = Select Input (A drop down selection input.)" + Environment.NewLine +
                    "InputMultiSelect = MultiSelect Input (A multiple selection input.)" + Environment.NewLine +
                    "InputCheckbox = Checkbox Input (A check box input.)" + Environment.NewLine +
                    "InputRadio = Radio Input (A radio input.)" + Environment.NewLine +
                    "InputDateTime = Date Time Input (A date time picker input.)" + Environment.NewLine +
                    "InputContactPicker = Contact Picker Input (A contact picker component.)" + Environment.NewLine +
                    "InputAssetPicker = Asset Picker Input (An asset picker component.)" + Environment.NewLine +
                    "" + Environment.NewLine +
                    "" + Environment.NewLine +
                    "" + Environment.NewLine +
                    "" + Environment.NewLine +
                    //"Component = Component (A component reference document in contents and properties.)" + Environment.NewLine +
                    "" );
  */


}
