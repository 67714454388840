import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { NavService } from 'projects/common-lib/src/lib/ux/nav/nav.service';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { MenuService } from 'projects/core-lib/src/lib/services/menu.service';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ib-nav-side',
  templateUrl: './nav-side.component.html',
  styleUrls: ['./nav-side.component.css']
})
export class NavSideComponent extends BaseComponent implements OnInit, OnDestroy {

  public primeMenu: MenuItem[] = [];

  constructor(
    public nav: NavService,
    public menuService: MenuService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    // Listen for menu changes
    this.menuService.primeMenuFeed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(menu => {
      // console.error("nav side got new menu", menu);
      if (menu) {
        this.primeMenu = [...menu];
      }
    });
    // // Do initial menu rendering
    // this.menuService.updateMenu(this.nav.isSideCollapsed, "initial menu render");
  }


  public toggleSideCollapsed() {
    this.nav.isSideCollapsed = !this.nav.isSideCollapsed;
    // Render menu again so we can show/hide text based on the collapsed status
    this.menuService.updateMenu(this.nav.isSideCollapsed, "toggle side collapsed");
    // console.error(`side collapsed = ${this.isSideCollapsed}`);
  }


}
