<div class="form-group {{inputFormGroupClass}} {{outerClass}}">
  <label for="{{inputControlId}}"
         class="{{inputLabelClass}}"
         ngbTooltip="{{tooltip | translate}}">
    <span *ngIf="required && includeRequiredIcon">
      <ib-icon icon="exclamation-circle (solid)"
               ngbTooltip="Required"></ib-icon>&nbsp;
    </span>
    {{labelPrefix}}{{( label | translate ) || label}}{{labelSuffix}}
  </label>
  <div class="{{inputWrapperClass}}">
    <div class="{{inputGroupClass}}">
      <span *ngIf="prefixText || prefixIcon"
            class="input-group-text"
            ngbTooltip="{{prefixTooltip | translate}}"
            [ngClass]="{'clickable': isPrefixClickable()}"
            (click)="firePrefixClick($event)">
        {{prefixText | translate}}
        <ib-icon icon="{{prefixIcon}}"></ib-icon>
      </span>
      <input type="number"
            class="form-control {{inputSize}} {{controlClass}}"
            id="{{inputControlId}}"
            name="{{name}}"
            placeholder="{{( placeholder | translate ) || placeholder}}"
            ngbTooltip="{{tooltip | translate}}"
            [(ngModel)]="value"
            [pKeyFilter]="keyFilterType"
            [min]="min"
            [max]="max"
            [step]="step"
            [disabled]="disabled || readonly ? 'disabled' : null"
            [readonly]="readonly ? 'readonly' : null"
            [required]="required ? 'required' : null"
            (focus)="fireFocus($event, inputControl)"
            (blur)="fireBlur($event, inputControl)"
            (keyup)="fireKeyUp($event, inputControl)"
            (change)="fireChange($event, inputControl)"
            [ngModelOptions]="{standalone: standalone}"
            #inputControl="ngModel" />

        <!--<span class="input-group-text" ngbTooltip="{{suffixTooltip | translate}}" (click)="fireSuffixClick($event)">
          {{suffixText | translate}}
          <ib-icon icon="{{suffixIcon}}"></ib-icon>
        </span>-->
      <ib-input-select-button *ngIf="allowEditCurrency"
                              name="Select Column"
                              label="Column"
                              color="default"
                              buttonStyles="border-top-left-radius:0; border-bottom-left-radius:0;"
                              [(ngModel)]="isoCurrencyCode"
                              [tooltip]=""
                              [buttonLabelMaxLength]="20"
                              buttonLabelType="Template"
                              buttonLabelTemplate="{Value} - {DisplayText}"
                              [optionsIncludeNone]="true"
                              optionsPickListId="{{Constants.PickList._Currency}}"
                              [allowSearch]="true"
                              [ngClass]="{'clickable': true}"
                              (change)="onIsoCurrencyCodeChange($event)">
      </ib-input-select-button>
    </div>
  </div>
  <div class="{{inputErrorWrapperClass}}"
       *ngIf="inputControl.invalid && (inputControl.dirty || inputControl.touched)">
    <small *ngFor="let errorMessage of errorMessages"
           class="form-text input-invalid input-error-message">
      {{errorMessage | translate:inputInformationValues}}
    </small>
  </div>
</div>
