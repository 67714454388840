import { OnDestroy, OnInit, Directive } from "@angular/core";
import { Subject } from "rxjs";

@Directive()
export abstract class BaseService implements OnInit, OnDestroy {

  /**
  We use takeUntil pattern (see https://stackoverflow.com/a/41177163) where we
  only need to unsubscribe from this one shared subject in ngOnDestroy as it
  is passed as parameter to takeUntil.
  @example
  this.contactService.getContactFullObject(contact.ContactId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(model => {
    ...
  });
  */
  protected ngUnsubscribe = new Subject();

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    /**
    We use takeUntil pattern (see https://stackoverflow.com/a/41177163) where we
    only need to unsubscribe from this one shared subject in ngOnDestroy as it
    is passed as parameter to takeUntil.
    @example
    this.contactService.getContactFullObject(contact.ContactId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(model => {
      ...
    });
    */
    this.ngUnsubscribe.next(void 0);
    this.ngUnsubscribe.complete();
  }

}
