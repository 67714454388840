import { Injectable } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from './base.service';
import { ApiService } from '../api/api.service';
import { AppService } from './app.service';
import { AppCacheService } from './app-cache.service';
import { Router } from '@angular/router';
import { Action } from 'projects/common-lib/src/lib/ux-models';

@Injectable({
  providedIn: 'root'
})
export class SystemService extends BaseService {

  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected cache: AppCacheService,
    protected sanitizer: DomSanitizer,
    protected router: Router) {

    super();

  }


  getDefaultSensitiveInformationHandlers(tableName: string): m5.DataTableSupportSensitiveInformationHandlerViewModel[] {

    const handlers: m5.DataTableSupportSensitiveInformationHandlerViewModel[] = [];

    if (tableName === "CONTACT") {
      const contactName = this.getDefaultSensitiveInformationHandlerModel("Name");
      contactName.PropertyName = "CONTACT_NAME";
      handlers.push(contactName);
      const firstName = this.getDefaultSensitiveInformationHandlerModel("Name");
      firstName.PropertyName = "FIRST_NAME";
      handlers.push(firstName);
      const lastName = this.getDefaultSensitiveInformationHandlerModel("Name");
      lastName.PropertyName = "LAST_NAME";
      handlers.push(lastName);
      const middleName = this.getDefaultSensitiveInformationHandlerModel("Name");
      middleName.PropertyName = "MIDDLE_NAME";
      handlers.push(middleName);
      const otherName = this.getDefaultSensitiveInformationHandlerModel("Name");
      otherName.PropertyName = "OTHER_NAME";
      handlers.push(otherName);
      const address1 = this.getDefaultSensitiveInformationHandlerModel("Address");
      address1.PropertyName = "ADDRESS1";
      handlers.push(address1);
      const address2 = this.getDefaultSensitiveInformationHandlerModel("Address");
      address2.PropertyName = "ADDRESS2";
      handlers.push(address2);
      const address3 = this.getDefaultSensitiveInformationHandlerModel("Address");
      address3.PropertyName = "ADDRESS3";
      handlers.push(address3);
      const phone = this.getDefaultSensitiveInformationHandlerModel("Phone");
      phone.PropertyName = "PHONE";
      handlers.push(phone);
      const altPhone = this.getDefaultSensitiveInformationHandlerModel("Phone");
      altPhone.PropertyName = "ALTERNATE_PHONE";
      handlers.push(altPhone);
      const fax = this.getDefaultSensitiveInformationHandlerModel("Phone");
      fax.PropertyName = "FAX";
      handlers.push(fax);
      const homePhone = this.getDefaultSensitiveInformationHandlerModel("Phone");
      homePhone.PropertyName = "HOME_PHONE";
      handlers.push(homePhone);
      const cellular = this.getDefaultSensitiveInformationHandlerModel("Phone");
      cellular.PropertyName = "CELLULAR";
      handlers.push(cellular);
      const pager = this.getDefaultSensitiveInformationHandlerModel("Phone");
      pager.PropertyName = "PAGER";
      handlers.push(pager);
      const voiceMail = this.getDefaultSensitiveInformationHandlerModel("Phone");
      voiceMail.PropertyName = "VOICE_MAIL";
      handlers.push(voiceMail);
      const extension = this.getDefaultSensitiveInformationHandlerModel("Phone");
      extension.PropertyName = "EXTENSION";
      handlers.push(extension);
      const email = this.getDefaultSensitiveInformationHandlerModel("Email", m5.SensitiveInformationMaskType.Email, m5.SensitiveInformationSanitizeType.Email);
      email.PropertyName = "EMAIL";
      handlers.push(email);
      const altEmail = this.getDefaultSensitiveInformationHandlerModel("Email", m5.SensitiveInformationMaskType.Email, m5.SensitiveInformationSanitizeType.Email);
      altEmail.PropertyName = "ALTERNATE_EMAIL";
      handlers.push(altEmail);
      const imAddress = this.getDefaultSensitiveInformationHandlerModel("Other");
      imAddress.PropertyName = "IM_ADDRESS";
      handlers.push(imAddress);
      const altImAddress = this.getDefaultSensitiveInformationHandlerModel("Other");
      altImAddress.PropertyName = "ALTERNATE_IM_ADDRESS";
      handlers.push(altImAddress);
      const webSite = this.getDefaultSensitiveInformationHandlerModel("Other");
      webSite.PropertyName = "WEB_SITE";
      handlers.push(webSite);
      const ftpSite = this.getDefaultSensitiveInformationHandlerModel("Other");
      ftpSite.PropertyName = "FTP_SITE";
      handlers.push(ftpSite);
      const taxId = this.getDefaultSensitiveInformationHandlerModel("Tax Id");
      taxId.PropertyName = "TAX_ID";
      handlers.push(taxId);
      const referredBy = this.getDefaultSensitiveInformationHandlerModel("Name");
      referredBy.PropertyName = "REFERRED_BY";
      handlers.push(referredBy);
      const login = this.getDefaultSensitiveInformationHandlerModel("Security Information");
      login.PropertyName = "LOGIN";
      handlers.push(login);
      const salutation = this.getDefaultSensitiveInformationHandlerModel("Name");
      salutation.PropertyName = "SALUTATION";
      handlers.push(salutation);
      const assistant = this.getDefaultSensitiveInformationHandlerModel("Name");
      assistant.PropertyName = "ASSISTANT_NAME";
      handlers.push(assistant);
      const manager = this.getDefaultSensitiveInformationHandlerModel("Name");
      manager.PropertyName = "MANAGER_NAME";
      handlers.push(manager);
      const spouse = this.getDefaultSensitiveInformationHandlerModel("Name");
      spouse.PropertyName = "SPOUSE_NAME";
      handlers.push(spouse);
      const children = this.getDefaultSensitiveInformationHandlerModel("Name");
      children.PropertyName = "CHILDREN_NAMES";
      handlers.push(children);
      const birth = this.getDefaultSensitiveInformationHandlerModel("Other");
      birth.PropertyName = "BIRTH_DATE";
      handlers.push(birth);
      const anniversary = this.getDefaultSensitiveInformationHandlerModel("Other");
      anniversary.PropertyName = "ANNIVERSARY_DATE";
      handlers.push(anniversary);
    }

    return handlers;

  }


  getDefaultSensitiveInformationHandlerModel(
    category: string = "",
    maskType: m5.SensitiveInformationMaskType = m5.SensitiveInformationMaskType.Default,
    sanitizeType: m5.SensitiveInformationSanitizeType = m5.SensitiveInformationSanitizeType.Default): m5.DataTableSupportSensitiveInformationHandlerViewModel {

    const model = new m5.DataTableSupportSensitiveInformationHandlerViewModel();
    model.Category = category;
    model.MaskType = m5.SensitiveInformationMaskType[maskType];
    model.MaskCharactersToShowPrefix = 1;
    model.MaskCharactersToShowSuffix = 1;
    model.MaskRemovalRequestAudited = true;
    model.SanitizeType = m5.SensitiveInformationSanitizeType[sanitizeType];
    model.SanitizeCharactersToKeepPrefix = 1;
    model.SanitizeCharactersToKeepSuffix = 1;
    model.SanitizeRequestAudited = true;

    return model;

  }


  getProcessTemplateStepActionProperties(stepActionType: string): m5.ProcessTemplateStepActionPropertiesEditViewModel<any> {

    const model = new m5.ProcessTemplateStepActionPropertiesEditViewModel<any>();
    model.StepActionType = stepActionType;
    model.Version = 1;
    model.Properties = {};
    model.Attributes = {};

    if (Helper.equals(stepActionType, "Process", true)) {
      model.Properties = new m5.ProcessTemplateStepActionChildProcessPropertiesModel();
    } else if (Helper.equals(stepActionType, "Case", true)) {
      model.Properties = new m5.ProcessTemplateStepActionCasePropertiesEditViewModel();
      model.Properties.CaseSuccessOutcome = "S";
      model.Properties.CaseFailureOutcome = "F";
      model.Properties.CaseIndeterminateOutcome = "S";
    } else if (Helper.equals(stepActionType, "Script", true)) {
      model.Properties = new m5.ProcessTemplateStepActionScriptPropertiesEditViewModel();
      model.Properties.ScriptSuccessResultCodes = [0];
      model.Properties.ScriptWaitingResultCodes = [52];
    } else if (Helper.equals(stepActionType, "RunExecutable", true) || Helper.equals(stepActionType, "RunPowerShell", true)) {
      model.Properties = new m5.ProcessTemplateStepActionRunExecutablePropertiesModel();
      model.Properties.WindowStyle = "Hidden";
      model.Properties.MaximumRunMinutes = 0;
      model.Properties.ShellExecute = true;
      model.Properties.WaitForExit = true;
      model.Properties.TreatErrorOutputAsFailure = false;
      model.Properties.ExecutableSuccessResultCodes = [0];
    } else if (Helper.equals(stepActionType, "Job", true)) {
      model.Properties = new m5.ProcessTemplateStepActionJobPropertiesEditViewModel();
    } else if (Helper.equals(stepActionType, "Plugin", true)) {
      model.Properties = new m5.ProcessTemplateStepActionPluginPropertiesEditViewModel();
    } else if (Helper.equals(stepActionType, "Notification", true)) {
      model.Properties = new m5.ProcessTemplateStepActionNotificationPropertiesEditViewModel();
    } else if (Helper.equals(stepActionType, "Step", true)) {
      model.Properties = new m5.ProcessTemplateStepActionStepPropertiesEditViewModel();
    } else if (Helper.equals(stepActionType, "Sql", true)) {
      model.Properties = new m5.ProcessTemplateStepActionSqlPropertiesEditViewModel();
      model.Properties.SqlStatementRowsAffected = "N";
      model.Properties.SqlStatementNoRowsAffected = "N";
    } else if (Helper.equals(stepActionType, "DbBackup", true)) {
      model.Properties = new m5.ProcessTemplateStepActionDbBackupPropertiesEditViewModel();
      model.Properties.BackupType = "Log";
      model.Properties.CompressionType = "DbServer";
    } else if (Helper.equals(stepActionType, "DbRestore", true)) {
      model.Properties = new m5.ProcessTemplateStepActionDbRestorePropertiesEditViewModel();
      model.Properties.RestoreType = "Database";
    } else if (Helper.equals(stepActionType, "CopyFiles", true)) {
      model.Properties = new m5.ProcessTemplateStepActionCopyFilesPropertiesEditViewModel();
      model.Properties.SourceFileServerId = 0;
      model.Properties.TargetFileServerId = 0;
    } else if (Helper.equals(stepActionType, "DeleteFiles", true)) {
      model.Properties = new m5.ProcessTemplateStepActionDeleteFilesPropertiesEditViewModel();
      model.Properties.FileServerId = 0;
      model.Properties.Exclude = [];
    } else if (Helper.equals(stepActionType, "ConvertFile", true)) {
      model.Properties = new m5.ProcessTemplateStepActionConvertFilePropertiesModel();
      model.Properties.Overwrite = true;
    } else if (Helper.equals(stepActionType, "AttachFile", true)) {
      model.Properties = new m5.ProcessTemplateStepActionAttachFilePropertiesModel();
      model.Properties.MoveFile = true;
    } else if (Helper.equals(stepActionType, "SystemWatchEvent", true)) {
      model.Properties = new m5.ProcessTemplateStepActionSystemWatchEventPropertiesModel();
      model.Properties.PayloadVersion = 1;
      model.Properties.Wait = true;
    } else if (Helper.equals(stepActionType, "Webhook", true)) {
      model.Properties = new m5web.WebApiRequestViewModel();
      model.Properties.Method = "HttpPost";
      // model.Properties.Headers = { Auth: "test" };
      const auth = new m.KeyValuePairModel<string, string>();
      auth.Key = "Auth";
      auth.Value = "test";
      model.Properties.Headers.push(auth);
      model.Properties.ContentType = "application/json";
      model.Properties.Protocol = "Tls12";
      model.Properties.ExceptionHandling = "Log";
    }

    return model;

  }


  actionIsVisible(action: Action, data: any, cargo: any): boolean {
    // No action then nothing ot check
    if (!action) {
      return true;
    }
    // Check visible role (if any)
    if (action.requiresRole) {
      const hasRole: boolean = this.appService.hasRole(action.requiresRole);
      // Log.debug("security", "Action Role", `Action ${action.actionId} requires role ${action.requiresRole}.  Has role = ${hasRole}.`);
      if (!hasRole) {
        // Missing role means false but existence of role doesn't guarantee true since there may be other visibility checks
        return false;
      }
    }
    // Check visible permission (if any)
    if (action.requiresPermissionAccessArea) {
      const hasPermission: boolean = this.appService.hasPermission(action.requiresPermissionAccessArea, Helper.getFirstDefinedString(action.requiresPermissionRight, "F"));
      // Log.debug("security", "Action Permission", `Action ${action.actionId} requires permission ${action.requiresPermissionAccessArea} ${action.requiresPermissionRight}.  Has permission = ${hasPermission}.`);
      if (!hasPermission) {
        // Missing permission means false but existence of permission doesn't guarantee true since there may be other visibility checks
        return false;
      }
    }
    // No visible function, or data then we default to visible
    if (!action.visible || (!data && !cargo)) {
      return true;
    }
    try {
      return action.visible(data, cargo);
    } catch (err) {
      Log.errorMessage(err);
      return true;
    }
  }



  actionIsActive(action: Action, data: any, cargo: any): boolean {
    // No action, active function, or data then we default to not active
    if (!action || !action.active || (!data && !cargo)) {
      return false;
    }
    try {
      return action.active(data, cargo);
    } catch (err) {
      Log.errorMessage(err);
      return true;
    }
  }



}
