import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy, AfterViewInit, DoCheck } from '@angular/core';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { NgForm, FormControl, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { Dictionary } from 'projects/core-lib/src/lib/models/dictionary';
import { FormStatusService } from 'projects/core-lib/src/lib/services/form-status.service';
import { FormStatusModel } from 'projects/core-lib/src/lib/models/form-status-model';

@Component({
  selector: 'ib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit, OnChanges, DoCheck {

  @Input() public name: string = "";
  @Input() public label: string = "";
  @Input() public icon: string = "";
  @Input() public iconColor: string = "";
  @Input() public iconAnimation: string = "";
  @Input() public tooltip: string = "";
  @Input() public color: string = "default";
  @Input() public size: string = "";
  @Input() public buttonClasses: string = "";
  @Input() public block: boolean = false;
  @Input() public outline: boolean = false;
  @Input() public active: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public formStatus: FormStatusModel;
  @Input() public disableWhenPristine: boolean = true;
  @Input() public disableWhenInvalid: boolean = true;
  @Input() public disableWhenWorking: boolean = true;
  @Input() public working: boolean = false;
  @Input() public workingIcon: string = "spinner";
  @Input() public workingIconAnimation: string = "spin";
  @Input() public workingLabel: string = "Please wait...";
  @Input() public displayErrorMessages: boolean = true;

  @Input() public popoverShow: boolean = false;
  @Input() public popoverTitle: string = "";
  @Input() public popoverDescription: string = "";
  @Input() public popoverClass: string = "";

  @Output() public click: EventEmitter<EventModel> = new EventEmitter();

  /**
   * Event with event.stopPropagation() we seem to have some parent components (click) event
   * firing so provide (onClick) to avoid that.  We haven't dropped (click) yet since there
   * is a ton of code using that and we can't just search and replace it.
   */
  @Output() public onClick: EventEmitter<EventModel> = new EventEmitter();

  // Should we be showing reason button is disabled?
  // public disabledTooltip: string = "";
  // public disabledMessage: string = "";
  public workingLabelToUse: string = "";
  public workingIconToUse: string = "";
  public workingIconAnimationToUse: string = "";
  public classes: string = "";
  public formButtonDisabled: boolean = false;

  constructor() { }

  ngOnInit() {
    this.configure();
    this.updateButtonState();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
    this.updateButtonState();
    // console.error(this.popoverShow, this.popoverTitle, this.popoverDescription);
  }

  ngDoCheck() {
    // Very odd but we have some scenarios where button is disabled
    // when the formStatus has been updated with a value that would
    // indicate it should be enabled but ngOnChanges() never fires!
    // ngDoCheck() does the trick here.
    this.updateButtonState();
  }

  public configure() {

    // We need some icon or label when in working mode so if we were not given any then default to the non-working values
    if (this.workingLabel || this.workingIcon) {
      this.workingLabelToUse = this.workingLabel;
      this.workingIconToUse = this.workingIcon;
      this.workingIconAnimationToUse = this.workingIconAnimation;
    } else {
      this.workingLabelToUse = this.label;
      this.workingIconToUse = this.icon;
      this.workingIconAnimationToUse = this.iconAnimation;
    }

    let classes: string = "btn";

    // Apply color
    if (this.color) {
      if (Helper.equals(this.color, "default", true)) {
        classes += ` btn-${this.outline ? "outline-" : ""}secondary`;
      } else if (Helper.equals(this.color, "primary", true)) {
        classes += ` btn-${this.outline ? "outline-" : ""}primary`;
      } else if (Helper.equals(this.color, "secondary", true)) {
        classes += ` btn-${this.outline ? "outline-" : ""}secondary`;
      } else if (Helper.equals(this.color, "success", true)) {
        classes += ` btn-${this.outline ? "outline-" : ""}success`;
      } else if (Helper.equals(this.color, "danger", true)) {
        classes += ` btn-${this.outline ? "outline-" : ""}danger`;
      } else if (Helper.equals(this.color, "warning", true)) {
        classes += ` btn-${this.outline ? "outline-" : ""}warning`;
      } else if (Helper.equals(this.color, "info", true)) {
        classes += ` btn-${this.outline ? "outline-" : ""}info`;
      } else if (Helper.equals(this.color, "light", true)) {
        classes += ` btn-${this.outline ? "outline-" : ""}light`;
      } else if (Helper.equals(this.color, "dark", true)) {
        classes += ` btn-${this.outline ? "outline-" : ""}dark`;
      } else if (Helper.equals(this.color, "link", true)) {
        classes += " btn-link";
      } else {
        Log.errorMessage(`Button color ${this.color} is not valid.  Possible values include: "default", "primary", "secondary", "success", "danger", "warning", "info", "light", "dark", or "link".  Defaulting to "secondary".`);
        classes += ` btn-${this.outline ? "outline-" : ""}secondary`;
      }
    } else {
      classes += ` btn-${this.outline ? "outline-" : ""}secondary`;
    }

    // Apply size
    if (this.size) {
      if (Helper.equals(this.size, "large", true)) {
        classes += " btn-lg";
      } else if (Helper.equals(this.size, "lg", true)) {
        classes += " btn-lg";
      } else if (Helper.equals(this.size, "small", true)) {
        classes += " btn-sm";
      } else if (Helper.equals(this.size, "sm", true)) {
        classes += " btn-sm";
      } else if (Helper.equals(this.size, "x-small", true)) {
        classes += " btn-xs";
      } else if (Helper.equals(this.size, "xs", true)) {
        classes += " btn-xs";
      } else {
        Log.errorMessage(`Button size ${this.size} is not valid.  Possible values include: "", "large", "small", "x-small".  Defaulting to "".`);
      }
    }

    if (this.block) {
      classes += " btn-block";
    }

    if (this.active) {
      classes += " active";
    }

    if (this.buttonClasses) {
      classes += " " + this.buttonClasses;
    }

    // Push our class into class property for html view rendering
    this.classes = classes;

  }


  public updateButtonState() {
    let disabled: boolean = this.disabled;
    if (this.formStatus) {
      if (this.disableWhenPristine && this.formStatus.isPristine) {
        disabled = true;
      }
      if (this.disableWhenInvalid && !this.formStatus.isValid) {
        disabled = true;
      }
    }
    if (this.disableWhenWorking && this.working) {
      disabled = true;
    }
    this.formButtonDisabled = disabled;
    // console.error(`${this.name} disabled = ${disabled}`, this.formStatus);
  }


  public fireClick($event) {
    Helper.fireClickVibration();
    // console.error("Button click", this.disabled, this.formButtonDisabled);
    // Do not emit any click events when the button is disabled
    if (!this.disabled && !this.formButtonDisabled) {
      const payload: EventModel = new EventModel("click", event, null, new EventElementModel("button", null, this.name, this.label, this.icon));
      this.click.emit(payload);
      this.onClick.emit(payload);
    }
    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // If we used different event names then it wouldn't be an issue but using these event names is more dev friendly.
      event.stopPropagation();
      event.preventDefault();
    } catch (err) {
      Log.errorMessage(err);
    }
  }

  // When the button was disabled, it would still fire a click event. We tested (onClick), and it works fine,
  // but (click) did not. When (click) was used, it wouldn't call fireClick, but something was still causing
  // a click event to be fired. Tested removing the icons, adding a disabled input to the templates, but
  // this did not fix. This divClick fixes it because in the event propagation firing order, it must come
  // before whatever was firing that click.
  divClick($event) {

    if (this.disabled || this.formButtonDisabled) {
      event.stopPropagation();
    }
  }
}
