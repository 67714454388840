import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as Enumerable from 'linq';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputSelectComponent),
  multi: true
};

@Component({
  selector: 'ib-input-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputSelectComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.


  constructor(
    protected apiService: ApiService,
    protected uxService: UxService,
    protected changeDetectorRef: ChangeDetectorRef) {
    super(apiService, uxService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    // configure is called from ngOnChanges in the InputBaseComponent
    // this.configure();
  }

  public configure() {
    // Call the base class configure method to handle a lot of this
    super.configure();
  }

  protected onPickListReady() {
    super.onPickListReady();
    // When pick lists are loaded we need to fire change detection
    this.changeDetectorRef.detectChanges();
  }


}
