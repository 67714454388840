<ng-container *ngIf="!nav.isMobile()">
  <p-panelMenu [model]="primeMenu"
               [style]="{'width':'100%'}"
               [multiple]="true"
               styleClass="{{nav.themeClasses}}"></p-panelMenu>
  <div *ngIf="nav.canSideCollapse"
       class="ib-sidebar-collapse-icon"
       [ngClass]="{'ib-menu-width-narrow': nav.isSideCollapsed, 'ib-menu-width-standard': !nav.isSideCollapsed}"
       (click)="toggleSideCollapsed()">
    <i class="far"
       [ngClass]="{'fa-chevron-right': nav.isSideCollapsed , 'fa-chevron-left': !nav.isSideCollapsed }"></i>
  </div>
</ng-container>
