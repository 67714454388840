import { Component, Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { FileUploadConfigurationSettings, UploadCounters } from 'projects/core-lib/src/lib/services/file-upload.service';
import { EventModel } from '../../ux-models';

@Directive()
export abstract class FileUploadBaseClass extends BaseComponent implements FileUploadConfigurationSettings, OnInit {
  /*
   * By default we are uploading files to the server.  If the file is intended
   * to be intercepted by the web application and handled locally then this should
   * be set to false assets using properties assigned here.  This scenario should
   * handle the file event which will be fired when a new file is submitted.
   */
  @Input() upload: boolean = true;

  /*
   * The type of upload being done.  If set to 'Other' then the uploadUrl must
   * set along with any optional upload data.
   */
  @Input() uploadType: "Asset" | "Attachment" | "Other" = "Attachment";

  /*
   * If not uploading to assets set the upload url here.
   */
  @Input() uploadUrl: string = "";

  /*
   * If not uploading to assets and upload data is desired or if complete
   * control desired over the asset data set the upload data here.
   */
  @Input() uploadData: any = null;


  /**
   * If uploading to an existing attachment this is the attachment id
   */
  @Input() attachmentId: string = "";

  /**
   * If uploading to an existing asset this is the asset id
   */
  @Input() assetId: number = null;

  /**
   * If uploading child assets this is the parent asset id
   */
  @Input() parentAssetId: number = null;

  // The attachment owner type as expected by the asset model
  @Input() ownerType: string = "";
  // The attachment owner id as expected by the asset model
  @Input() ownerId: number | string = null;
  // The attachment secondary owner type (if any)
  @Input() secondaryOwnerType: string = "";
  // The attachment secondary owner id (if any)
  @Input() secondaryOwnerId: number | string = null;

  // Upload icon defaults to "cloud-upload (solid)"
  @Input() uploadIcon: string = "cloud-upload (solid)";
  // Upload text defaults to "<strong>Drop files or click to upload</strong>"
  @Input() uploadText: string = "<strong>Drop files or click to upload</strong>";

  // This is a comma separated list of mime types or file extensions. e.g.: image/*,application/pdf,.docx.  Defaults to any file type.
  @Input() acceptedFileTypes: string = null;

  // The maximum file size in MB.  Defaults to 100.
  @Input() maxFileSize: number = 100;

  // The maximum number of files to upload in parallel.  Defaults to 5.
  @Input() parallelUploads: number = 5;

  // The dropzone component defaults to 30 seconds which may be too quick for some of the server side processing that might happen.
  @Input() timeout: number = 180_000; // 180,000 ms = 180 seconds = 3 minutes

  // Optional properties frequently used for data attached to the file upload
  @Input() summary: string = "";
  @Input() description: string = "";
  @Input() group: string = "";
  @Input() systemGroup: string = "";
  @Input() category: string = "";
  @Input() class: string = "";
  @Input() visibility: string = "I";
  @Input() scope: string = "";
  @Input() status: string = "";
  @Input() tags: string[] = [];

  // Callback on successful upload with response properties file: any, response: m5.IApiResponse
  @Output() success: EventEmitter<EventModel> = new EventEmitter();
  // Callback on failed upload with response properties file: any, response: m5.IApiResponse
  @Output() error: EventEmitter<EventModel> = new EventEmitter();
  // Callback on file posted to the client.  This is typically used when upload is set to false.
  @Output() file: EventEmitter<EventModel> = new EventEmitter();

  // Counters to use for displaying stats
  counters: UploadCounters = {
    countPending: 0,
    countStarted: 0,
    countUploading: 0,
    countDone: 0,
    countSuccess: 0,
    countError: 0,
  };

  /**
   * A config object to be passed to the dropzone component. Contains some of their
   * config properties as well as some custom event emitters to suit our needs.
   * https://docs.dropzone.dev/configuration/basics/configuration-options
   */
  config: any = {};


  constructor() {
    super();
   }

  ngOnInit(): void {
  }

  /**
   * Builds the interface that needs to be passed to the FileUploadService when we call it's configure method.
   * @returns
   */
  getConfigurationSettings(): FileUploadConfigurationSettings {
    const configureData: FileUploadConfigurationSettings = {
      upload: this.upload,
      uploadType: this.uploadType,
      uploadUrl: this.uploadUrl,
      uploadData: this.uploadData,
      uploadIcon: this.uploadIcon,
      uploadText: this.uploadText,
      acceptedFileTypes: this.acceptedFileTypes,
      timeout: this.timeout,
      maxFileSize: this.maxFileSize,
      parallelUploads: this.parallelUploads,
      clickable: true,
      disablePreviews: false,

      ownerType: this.ownerType,
      ownerId: this.ownerId,
      secondaryOwnerType: this.secondaryOwnerType,
      secondaryOwnerId: this.secondaryOwnerId,

      summary: this.summary,
      description: this.description,
      group: this.group,
      systemGroup: this.systemGroup,
      category: this.category,
      class: this.class,
      visibility: this.visibility,
      scope: this.scope,
      status: this.status,
      tags: this.tags,

      attachmentId: this.attachmentId,
      assetId: this.assetId,
      parentAssetId: this.parentAssetId

    };
    return configureData;
  }


  @HostListener('window:dragenter', ['$event'])
  blockDragEnter($event) {
    // Prevent dropping files outside of drop zone since that will cause browser to navigate to the dropped file and maybe break our host page
    $event.preventDefault();
    $event.dataTransfer.effectAllowed = "none";
    $event.dataTransfer.dropEffect = "none";
  }
  @HostListener('window:dragover', ['$event'])
  blockDragOver($event) {
    // Prevent dropping files outside of drop zone since that will cause browser to navigate to the dropped file and maybe break our host page
    $event.preventDefault();
    $event.dataTransfer.effectAllowed = "none";
    $event.dataTransfer.dropEffect = "none";
  }
  @HostListener('window:drop', ['$event'])
  blockDrop($event) {
    // Prevent dropping files outside of drop zone since that will cause browser to navigate to the dropped file and maybe break our host page
    $event.preventDefault();
    $event.dataTransfer.effectAllowed = "none";
    $event.dataTransfer.dropEffect = "none";
  }
}
