import { Pipe, PipeTransform } from '@angular/core';
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { Helper } from '../helpers/helper';

@Pipe({
  name: 'pickListObjectText'
})
export class PickListObjectTextPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //  return null;
  // }

  // data.Value | pickListObjectText : pickListSelectionArray
  public transform(value: string, pickList: m5core.PickListSelectionViewModel[]): string {

    return Helper.pickListDisplayText(value, pickList);

    // if (!value) {
    //  return "";
    // }
    // if (!pickList || pickList.length === 0) {
    //  return value;
    // }

    // const matches = pickList.filter(x => Helper.equals(x.Value, value, true));
    // if (!matches || matches.length === 0) {
    //  return value;
    // }

    // return Helper.getFirstDefinedString(matches[0].DisplayText, value);

  }



}
