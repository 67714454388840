<ngx-avatars name="{{nameForAvatar}}"
             gravatarId="{{emailForAvatar}}"
             [size]="size"
             [round]="true"
             ngbTooltip="{{tooltipText}}"
             container="body"
             [disableTooltip]="!tooltip"
             [placement]="tooltipPlacement"
             class="{{customClass}}"
             [value]="value"
             (clickOnAvatar)="fireClick($event)">
</ngx-avatars>
