import { Component, OnInit } from '@angular/core';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as m5auth from "projects/core-lib/src/lib/models/ngModelsAuth5";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiProperties, ApiOperationType, IApiResponseWrapper, IApiResponseWrapperTyped, ApiCall } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { ApiModuleSecurity } from 'projects/core-lib/src/lib/api/Api.Module.Security';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { AlertItemType } from 'projects/common-lib/src/lib/alert/alert-manager';

@Component({
  selector: 'ib-multi-factor-authentication-step-one',
  templateUrl: './multi-factor-authentication-step-one.component.html',
  styleUrls: ['./multi-factor-authentication-step-one.component.css']
})
export class MultiFactorAuthenticationStepOneComponent extends BaseComponent implements OnInit {

  public user: m5sec.AuthenticatedUserViewModel;
  public mfaReason: m.StandardResultCode;
  public data: m5sec.MultiFactorAuthenticationRequestViewModel;
  public apiProperties: ApiProperties;
  public apiCall: ApiCall;
  public errorMessage: string = "";
  public returnUrl: string = "";
  public rememberMe: boolean = false;
  public retry: boolean = false;
  public title: string = "Verify Your Identity";
  // [options]="[{value:'M',label:'Male'}, {value:'F',label:'Female'}, {value:'O',label:'Other'}, {value:'U', label:'Unknown'}]">
  public selectedOption: m5auth.MultiFactorAuthenticationOptionViewModel = null;
  public options: { value: m5auth.MultiFactorAuthenticationOptionViewModel, label: string }[] = [];


  constructor(
    protected appService: AppService,
    protected apiService: ApiService,
    protected route: ActivatedRoute,
    protected router: Router) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.user = Helper.sessionStorageGetObject<m5sec.AuthenticatedUserViewModel>(Constants.LocalStorage.UserObjectPending, null);
    this.data = new m5sec.MultiFactorAuthenticationRequestViewModel();
    this.apiProperties = ApiModuleSecurity.SecurityMultiFactorAuthentication();
    this.apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Call);

    // See if we have a return url to redirect to after successful login
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"];
    this.rememberMe = this.route.snapshot.queryParams["rememberMe"] || false;
    this.retry = this.route.snapshot.queryParams["retry"] || false;

    if (this.user) {
      // this.title = Helper.getFirstDefinedString(this.user.ContactName, `${this.user.ContactFirstName} ${this.user.ContactLastName}`.trim(), this.user.Login, "Verify Your Identity");
      this.mfaReason = (<any>this.user).LoginResultCode;
      if (!this.user.MultiFactorAuthenticationOptions || this.user.MultiFactorAuthenticationOptions.length === 0) {
        const error: string = "Multi-factor authentication is required but no options appear to be available.  Please try again.  If you continue to see this error please contact your system administrator.";
        this.returnToLogin(error);
      } else {
        this.options = [];
        this.user.MultiFactorAuthenticationOptions.forEach(option => {
          let label: string = "";
          if (Helper.startsWith(option.Method, "E", true)) {
            label = `Email to: ${option.Target}`;
          } else if (Helper.startsWith(option.Method, "S", true)) {
            label = `Text message to: ${option.Target}`;
          } else if (Helper.startsWith(option.Method, "P", true)) {
            label = `Voice call to: ${option.Target}`;
          } else if (Helper.startsWith(option.Method, "T", true)) {
            label = `Authenticator Application`;
          } else {
            label = option.Target;
          }
          // console.error(label, option);
          this.options.push({ value: option, label: label });
          // If this option is the default option then select it
          if (option.DefaultOption) {
            this.selectedOption = option;
          }
        });
        // If we have a selected option then we had a default and we can move on now unless we're in retry mode
        if (this.selectedOption && !this.retry) {
          this.next(null);
        }
      }
    } else {
      Log.errorMessage(`The MFA process could not find user object ${Constants.LocalStorage.UserObjectPending} in session storage.`);
      const error: string = "We were unable to find the required information for multi-factor authentication.  Please try again.  If you continue to see this error please contact your system administrator.";
      this.returnToLogin(error);
    }

  }


  returnToLogin(error: string) {
    if (error) {
      // Our login component clears alert messages so use session storage for a sticky login error
      Helper.sessionStorageSave(Constants.LocalStorage.LoginErrorMessage, error);
    }
    const params: any = {};
    if (this.returnUrl) {
      params.returnUrl = this.returnUrl;
    }
    if (this.rememberMe) {
      params.rememberMe = this.rememberMe;
    }
    this.router.navigate(["/", "login"], { queryParams: params });
  }


  registerApp() {
    const params: any = {};
    if (this.returnUrl) {
      params.returnUrl = this.returnUrl;
    }
    if (this.rememberMe) {
      params.rememberMe = this.rememberMe;
    }
    this.router.navigate(["/", "login", "mfa-app-registration"], { queryParams: params });
  }


  next($event) {
    this.errorMessage = "";
    if (!this.selectedOption) {
      this.errorMessage = "You must select one of the contact options.";
      return;
    }
    if (Helper.equals(this.selectedOption.Method, "TOTP", true)) {
      // Some methods do not require a trip to the server to request a code be sent so in those cases we should skip the API call
      this.user.MultiFactorAuthenticationTarget = this.selectedOption;
      Helper.sessionStorageSaveObject<m5sec.AuthenticatedUserViewModel>(Constants.LocalStorage.UserObjectPending, this.user);
      const params: any = {};
      if (this.returnUrl) {
        params.returnUrl = this.returnUrl;
      }
      if (this.rememberMe) {
        params.rememberMe = this.rememberMe;
      }
      this.router.navigate(["/", "login", "mfa-step-2"], { queryParams: params });
      return;
    }
    this.data = new m5sec.MultiFactorAuthenticationRequestViewModel();
    this.data.ContactId = this.user.ContactId;
    this.data.Login = this.user.Login;
    this.data.Method = this.selectedOption.Method;
    this.data.Target = this.selectedOption.Target;
    this.data.Source = this.selectedOption.Id;
    this.apiService.call(this.apiCall, this.data).subscribe((response: IApiResponseWrapperTyped<m5sec.AuthenticatedUserViewModel>) => {
      if (response.Data.Success || response.Data.ResultCode === m.StandardResultCode.AccessRequiresMultiFactorAuthentication) {
        const pending: any = response.Data.Data;
        pending.LoginResultCode = response.Data.ResultCode;
        delete pending.Settings;
        delete pending.Groups;
        delete pending.Roles;
        delete pending.Permissions;
        Helper.sessionStorageSaveObject(Constants.LocalStorage.UserObjectPending, pending);
        const params: any = {};
        if (this.returnUrl) {
          params.returnUrl = this.returnUrl;
        }
        if (this.rememberMe) {
          params.rememberMe = this.rememberMe;
        }
        this.router.navigate(["/", "login", "mfa-step-2"], { queryParams: params });
      } else {
        this.appService.alertManager.addAlertFromApiResponse(response, this.apiCall);
      }
    });
  }


}
