import { Component, OnInit, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as m5auth from "projects/core-lib/src/lib/models/ngModelsAuth5";
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ContactService } from 'projects/core-lib/src/lib/services/contact.service';
import { SecurityPolicyItemStatus, SecurityService } from 'projects/core-lib/src/lib/services/security.service';

@Component({
  selector: 'ib-security-policy-password-compliance',
  templateUrl: './security-policy-password-compliance.component.html',
  styleUrls: ['./security-policy-password-compliance.component.css']
})
export class SecurityPolicyPasswordComplianceComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() policy: m5auth.AuthenticationPolicyViewModel = null;
  @Input() doubleEntry: boolean = false;
  @Input() login: string = "";
  @Input() password1: string = "";
  @Input() password2: string = "";
  @Input() contactName: string = "";
  @Input() firstName: string = "";
  @Input() lastName: string = "";

  status: SecurityPolicyItemStatus[] = [];

  constructor(protected securityService: SecurityService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    // On any change we need to update our status since ... each keypress should update things
    // like password input attribute which will fire this on changes method which can then update.
    this.updateStatus(null);
  }

  ngAfterViewInit() {
    this.updateStatus(null);
  }

  updateStatus($event) {
    this.status = this.securityService.getSecurityPolicyStatus(this.policy, this.doubleEntry, this.login,
      this.password1, this.password2, this.contactName, this.firstName, this.lastName);
  }

}
