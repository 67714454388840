import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import * as Enumerable from 'linq';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ContextColorPickerComponent),
  multi: true
};

@Component({
  selector: 'ib-context-color-picker',
  templateUrl: './context-color-picker.component.html',
  styleUrls: ['./context-color-picker.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class ContextColorPickerComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.
  @Input() public color: string = "";
  @Input() public text: string = "Context Color";

  public buttonColor: string = "outline-dark";
  public classes: string = "btn btn-outline-dark me-2 dropdown-toggle";

  public colors: string[] = ["none", "primary", "secondary", "success", "warning", "danger", "info", "light", "dark"];

  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }

  public configure() {

    // Call the base class configure method to handle a lot of this
    super.configure();

    if (!this.text) {
      this.text = "Context Color";
    }

    if (!this.optionsNoneLabel) {
      this.optionsNoneLabel = "none";
    }
    if (this.optionsIncludeNone) {
      this.colors = [this.optionsNoneLabel.toLowerCase(), "primary", "secondary", "success", "warning", "danger", "info", "light", "dark"];
    } else {
      this.colors = ["primary", "secondary", "success", "warning", "danger", "info", "light", "dark"];
    }

    // Use our color input to init our color button
    if (this.color) {
      // console.error(this.value);
      this.value = this.color;
      this.buttonColor = this.color;
    } else if (this.optionsIncludeNone) {
      // console.error("no color");
      this.color = "";
      this.value = "";
      this.buttonColor = "outline-dark";
    } else {
      // console.error("no color");
      // We were not given a color and none isn't an option
      this.color = "info";
      this.value = "info";
      this.buttonColor = "info";
    }

    // Now build our button classes
    this.buildButtonClasses();

  }

  protected buildButtonClasses() {
    this.classes = `btn btn-${this.buttonColor}`;
    if (Helper.equals(this.size, "large", true) || Helper.equals(this.size, "lg", true)) {
      this.classes += " btn-lg";
    } else if (Helper.equals(this.size, "small", true) || Helper.equals(this.size, "sm", true)) {
      this.classes += " btn-sm";
    } else if (this.size) {
      Log.errorMessage(`Context color picker button size ${this.size} is not valid.  Possible values include: "", "large", "small".  Defaulting to "".`);
    }
    this.classes += " me-2 dropdown-toggle";
  }

  public selectColor(color: string, event: any) {
    if (Helper.equals(color, this.optionsNoneLabel, true)) {
      this.color = "";
      this.value = "";
      this.buttonColor = "outline-dark";
    } else {
      this.color = color;
      this.value = color;
      this.buttonColor = color;
    }
    this.buildButtonClasses();
    this.fireChange(event, null);
  }

  public getColorName = (input: string) => {
    if (!input) {
      return this.optionsNoneLabel || "None";
    }
    return Helper.toTitleCase(input);
  };

}
