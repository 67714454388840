import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularPinturaModule } from '@pqina/angular-pintura';
import { AttachmentEditModalComponent } from './attachment-edit-modal.component';

@NgModule({
  imports: [
    CommonModule,
    AngularPinturaModule

  ],
  declarations: [
    AttachmentEditModalComponent
  ],
  exports: [
    AttachmentEditModalComponent
  ],
})
export class AttachmentEditModule { }
