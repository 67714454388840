import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { SelectItem } from 'primeng/api';
import { StaticPickList } from 'projects/core-lib/src/lib/models/model-helpers';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Helper } from '../../../../../core-lib/src/lib/helpers/helper';

@Component({
  selector: 'ib-icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.css']
})
export class IconPickerComponent implements OnInit {

  @Input() icon: string = "";
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() optionsIncludeNone: boolean = true;
  @Input() optionsNoneLabel: string = "<None>";
  @Input() placeholder: string = "Icon Search";
  @Input() typeahead: boolean = true; // when true will use typeahead text input instead of select input.
  @Output() change: EventEmitter<EventModel> = new EventEmitter();

  icons: SelectItem[] = [];
  iconNames: string[] = [];

  inputWrapperClass: string = `${Constants.Layout.fullWidth} input-group ps-0 pe-0`;

  constructor() { }

  ngOnInit() {

    this.icons = [];
    if (this.optionsIncludeNone) {
      this.icons.push({ label: this.optionsNoneLabel, value: "" });
    }

    this.iconNames = StaticPickList.FontAwesomeIconNames();
    this.iconNames.forEach(icon => {
      this.icons.push({ label: icon, value: icon, icon: IconHelper.parseIcon(icon).calculatedClasses });
    });

    // if (Helper.equals(this.size, "large", true) || Helper.equals(this.size, "lg", true)) {
    //  this.inputWrapperClass += " input-group-lg";
    // } else if (Helper.equals(this.size, "small", true) || Helper.equals(this.size, "sm", true)) {
    //  this.inputWrapperClass += " input-group-sm";
    // }

  }

  iconChanged($event) {

    // console.error("icon picked", $event);

    // For select input get the icon from $event.value
    if (!this.typeahead) {
      this.icon = $event.value;
    }

    // console.error(this.icon);
    const payload: EventModel = new EventModel("change", $event, this.icon);
    // console.error(payload);
    this.change.emit(payload);

    if (this.typeahead) {
      try {
        // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
        // Since our "ib-input-*" component has this method as does the native html input control.
        // If we used different event names then it wouldn't be an issue but using these event names is more developer friendly.
        $event.stopPropagation();
        $event.preventDefault();
      } catch (err) {
        // Some custom components like multiselect do not have the above event method to fire.
        // Log.errorMessage(err);
      }
    }

  }

  clicked($event) {
    // console.error("clicked", $event);
    // this.icon = $event.value;
    // let payload: EventModel = new EventModel("change", $event, this.icon);
    // this.change.emit(payload);
  }


  typeaheadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.iconNames.filter(v => Helper.contains(v, term, true)).slice(0, 500))
    );

}
