import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';

@Component({
  selector: 'ib-overlay-simple-alert',
  templateUrl: './overlay-simple-alert.component.html',
  styleUrls: ['./overlay-simple-alert.component.css']
})
export class OverlaySimpleAlertComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() public options: ModalCommonOptions = new ModalCommonOptions();
  // @Output() public onOk: EventEmitter<EventModel> = new EventEmitter();
  // @Output() public onCancel: EventEmitter<EventModel> = new EventEmitter();

  public size: string = "lg";

  constructor() {
    super();
  }

  ngOnInit() {
    this.configure();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }

  protected configure() {

    if (!this.options) {
      this.options = new ModalCommonOptions();
    }

    if (this.options.size === "larger") {
      this.size = "xl";
    } else if (this.options.size === "largest") {
      this.size = "largest";
    }

    // For simple alert modals we do not want to show alerts in the modal
    this.options.showAlerts = false;

    if (!this.options.title && !this.options.message) {
      this.options.message = "There was no message provided to show.<br/><br/><small>";
      const trace = Log.getStackTrace();
      trace.forEach((line) => {
        this.options.message += line + "<br/>";
      });
      this.options.message += "</small>";
    }
    // We need text or icon on at least one of our buttons
    if (!this.options.okButtonText && !this.options.okButtonIcon && !this.options.cancelButtonText && !this.options.cancelButtonIcon) {
      this.options.okButtonText = "Ok";
    }

  }

}
