<header class="{{nav.themeClasses}} ib-nav-top">

  <ng-container *ngIf="isMobile">
    <div class="float-start">
      <p-slideMenu #slidemenu
                   [model]="menu"
                   [popup]="true"
                   [viewportHeight]="350"></p-slideMenu>
      <button #btn
              class="btn btn-lg {{nav.themeClasses}}"
              style="margin: 5px;"
              (click)="makeVisible(); slidemenu.toggle($event)"><i class="far fa-bars"></i></button>
    </div>
  </ng-container>

  <div class="ib-nav-top-content">

    <div *ngIf="!isMobile"
         class="float-start">
      <a (click)="gotoHomePage()"
         class="clickable">{{title}}</a>
    </div>

    <div class="float-end">

      <!-- partition notice -->
      <div class="float-start"
           *ngIf="partitionNoticeIcon || partitionNoticeText">
        <div class="ib-nav-top-content-item">
          <span class="badge bg-{{partitionNoticeColor}}">
            <ib-icon [icon]="partitionNoticeIcon"
                     [label]="partitionNoticeText"></ib-icon>
          </span>
        </div>
      </div>


      <!-- search -->
      <div *ngIf="showSearch"
           class="float-start">
        <div *ngIf="searchConfigCount === 1"
             class="ib-nav-top-content-item ps-3 pe-3"
             style="font-size:1rem; padding-top:3px;">
          <a [routerLink]="['/' , 'search' , searchConfigs[0].ExternalSearchConfigurationId || searchConfigs[0].SearchConfigurationId]"><i class="fal fa-search"></i></a>
        </div>
        <div *ngIf="searchConfigCount > 1"
             class="ib-nav-top-content-item d-inline-block"
             ngbDropdown
             placement="bottom-right"
             #search="ngbDropdown">
          <button class="btn ib-nav-top-content-button"
                  ngbTooltip="Search"
                  placement="left"
                  id="dropdownManual"
                  ngbDropdownAnchor
                  (click)="search.toggle()">
            <i class="fal fa-search"></i>
          </button>
          <div ngbDropdownMenu
               aria-labelledby="dropdownManual">
            <button class="dropdown-item"
                    [routerLink]="['/' , 'search' , config.ExternalSearchConfigurationId || config.SearchConfigurationId]"
                    *ngFor="let config of searchConfigs">{{config.Description}}</button>
          </div>
        </div>
      </div>



      <!-- support -->
      <div *ngIf="showHelpMenu"
           class="float-start">
        <ib-help-button [helpTopic]="helpTopic"
                        dropDownWrapperClass="ib-nav-top-content-item"
                        dropDownButtonClass="ib-nav-top-content-button">
        </ib-help-button>
      </div>


      <!-- page history -->
      <div *ngIf="nav.showPageHistory && !isMobilePortrait"
           class="float-start">
        <div class="ib-nav-top-content-item d-inline-block"
             ngbDropdown
             placement="bottom-right"
             #pageHistory="ngbDropdown">
          <button class="btn ib-nav-top-content-button"
                  ngbTooltip="History"
                  placement="left"
                  id="dropdownManual"
                  ngbDropdownAnchor
                  (click)="pageHistory.toggle()">
            <i class="fal fa-history"></i>
          </button>
          <div ngbDropdownMenu
               aria-labelledby="dropdownManual">
            <button class="dropdown-item"
                    [routerLink]="[history.url]"
                    *ngFor="let history of nav.pageHistory.list">{{history.label}}</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item"
                    (click)="nav.pageHistory.clear()"><i class="fal fa-power-off"></i>&nbsp;&nbsp;Clear History</button>
            <div class="dropdown-divider"></div>
            <button *ngIf="nav.showBookmarkOption"
                    class="dropdown-item"
                    (click)="bookmarkPage()"><i class="fal fa-bookmark fa-fw"></i>&nbsp;&nbsp;Bookmark this page</button>
            <button *ngIf="nav.showSetHomePageOption"
                    class="dropdown-item"
                    (click)="setHomePage()"><i class="fal fa-home fa-fw"></i>&nbsp;&nbsp;Set as home page</button>
            <button *ngIf="nav.showSiteMapOption"
                    class="dropdown-item"
                    [routerLink]="['/system/site-map']"><i class="fal fa-sitemap fa-fw"></i>&nbsp;&nbsp;Site Map</button>
          </div>
        </div>
      </div>


      <!-- recent cases -->
      <div id="ibNavTopItemRecentCases" *ngIf="nav.showRecentCases && !isMobile"
           class="float-start">
        <div class="ib-nav-top-content-item d-inline-block"
             ngbDropdown
             placement="bottom-right"
             #recentCases="ngbDropdown">
          <button class="btn ib-nav-top-content-button"
                  [ngbTooltip]="recentCasesTooltip"
                  placement="left"
                  id="dropdownManual"
                  ngbDropdownAnchor
                  (click)="recentCases.toggle()">
            <i class="fal fa-folder-open"></i>
          </button>
          <div ngbDropdownMenu
               aria-labelledby="dropdownManual">
            <button class="dropdown-item"
                    [routerLink]="[case.url]"
                    *ngFor="let case of nav.recentCases.list">{{case.label}}</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item"
                    (click)="nav.recentCases.clear()"><i class="fal fa-power-off"></i>&nbsp;&nbsp;Clear</button>
          </div>
        </div>
      </div>


      <!-- recent customers -->
      <div *ngIf="nav.showRecentCustomers && !isMobile"
           class="float-start">
        <div class="ib-nav-top-content-item d-inline-block"
             ngbDropdown
             placement="bottom-right"
             #recentCustomers="ngbDropdown">
          <button class="btn ib-nav-top-content-button"
                  ngbTooltip="Recent Customers"
                  placement="left"
                  id="dropdownManual"
                  ngbDropdownAnchor
                  (click)="recentCustomers.toggle()">
            <i class="fal fa-address-card"></i>
          </button>
          <div ngbDropdownMenu
               aria-labelledby="dropdownManual">
            <button class="dropdown-item"
                    [routerLink]="[customer.url]"
                    *ngFor="let customer of nav.recentCustomers.list">{{customer.label}}</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item"
                    (click)="nav.recentCustomers.clear()"><i class="fal fa-power-off"></i>&nbsp;&nbsp;Clear</button>
          </div>
        </div>
      </div>


      <!-- user options -->
      <div class="float-start">
        <ib-user-options></ib-user-options>
      </div>


    </div>

    <div class="clearfix"></div>
  </div>
</header>
