import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputWrapperComponent),
  multi: true
};

@Component({
  selector: 'ib-input-wrapper',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputWrapperComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }

  public configure() {

    super.configure();

    // With undefined label we don't have proper alignment
    if (!this.label) {
      this.label = "   ";
    }

    // We sometimes have suffix for icon actions to appear after the label but this is different than how
    // suffix is used else where so remove classes that our base class may have injected for suffix handling.
    this.inputWrapperClass = Helper.replaceAll(this.inputWrapperClass, "ui-inputgroup", "");
    this.inputWrapperClass = Helper.replaceAll(this.inputWrapperClass, "input-group-lg", "");
    this.inputWrapperClass = Helper.replaceAll(this.inputWrapperClass, "input-group-sm", "");
    this.inputWrapperClass = Helper.replaceAll(this.inputWrapperClass, "input-group", "");

  }

}
