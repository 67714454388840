import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { TableHelper } from 'projects/common-lib/src/lib/table/table-helper';
import { Log, Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ButtonItem, Action, EventModelTyped, EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { Subject } from 'rxjs';
import { ContactService } from 'projects/core-lib/src/lib/services/contact.service';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { DataEditorFormModalWrapperBaseClass } from 'projects/common-lib/src/lib/ux/data-editor/data-editor-form-modal-wrapper-base-class';
import { StaticPickList } from 'projects/core-lib/src/lib/models/model-helpers';
import { UxService } from '../../services/ux.service';

@Component({
  selector: 'ib-table-column-configuration-modal',
  templateUrl: './table-column-configuration-modal.component.html',
  styleUrls: ['./table-column-configuration-modal.component.css']
})
export class TableColumnConfigurationModalComponent extends DataEditorFormModalWrapperBaseClass<m5web.TableColumnConfigurationViewModel> implements OnInit, OnChanges, OnDestroy {


  dataTypePickList: m5core.PickListSelectionViewModel[] = [];
  alignPickList: m5core.PickListSelectionViewModel[] = [];
  toolTipTypePickList: m5core.PickListSelectionViewModel[] = [];
  toolTipPlacementPickList: m5core.PickListSelectionViewModel[] = [];
  filterTypePickList: m5core.PickListSelectionViewModel[] = [];
  filterMatchModePickList: m5core.PickListSelectionViewModel[] = [];

  getStyleEditorActions: ButtonItem = null;
  renderEditorActions: ButtonItem = null;
  tooltipTitleEditorActions: ButtonItem = null;
  tooltipTextEditorActions: ButtonItem = null;

  optionsPickListJsonError: string = "";
  filterPickListJsonError: string = "";


  constructor(
    protected appService: AppService,
    protected apiService: ApiService,
    protected uxService: UxService,
    protected sanitizer: DomSanitizer,
    public modal: NgbActiveModal) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.setupPickListsStatic();
    this.setupGetCellStyleEditorActions();
    this.setupRenderEditorActions();
    this.setupTooltipTitleEditorActions();
    this.setupTooltipTextEditorActions();
  }
  // ngOnChanges(changes: SimpleChanges) {
  //   super.ngOnChanges(changes);
  // }
  // ngOnDestroy() {
  //  super.ngOnDestroy();
  // }

  setupPickListsStatic() {
    this.dataTypePickList = StaticPickList.stringItemsToPickList("unknown", "string", "boolean", "number", "int", "float", "currency", "date", "time", "datetime", "picklist", "icon", "email", "avatar", "function", "pie-chart");
    this.alignPickList = StaticPickList.stringItemsToPickList("left", "center", "right");
    this.toolTipTypePickList = StaticPickList.stringItemsToPickList("none", "tooltip", "popover");
    this.toolTipPlacementPickList = StaticPickList.stringItemsToPickList("auto", "top", "bottom", "left", "right", "top-left", "top-right", "bottom-left", "bottom-right", "left-top", "left-bottom", "right-top", "right-bottom");
    this.filterTypePickList = StaticPickList.stringItemsToPickList("none", "text", "select", "multiselect");
    this.filterMatchModePickList = StaticPickList.stringItemsToPickList("startsWith", "contains", "endsWith", "equals", "notEquals", "in", "lt", "lte", "gt", "gte");
  }


  setupRenderEditorActions() {
    this.renderEditorActions = new ButtonItem("Example", "js-square (brand)", "secondary");
    this.renderEditorActions.action = (event: EventModel) => {
      const code: string =
        `(row) => {
    // Use row object to build how the column value should be rendered.
    let output = row.Value1 + ": " + row.Value2;
    return output;
};`;
      if (this.data.Render) {
        this.data.Render = code + "\n\n\n" + this.data.Render;
      } else {
        this.data.Render = code;
      }
    };
  }

  setupTooltipTitleEditorActions() {
    this.tooltipTitleEditorActions = new ButtonItem("Example", "js-square (brand)", "secondary");
    this.tooltipTitleEditorActions.action = (event: EventModel) => {
      const code: string =
        `(row) => {
    // Use row object to build what you want the tooltip value to be
    let output = row.Value1 + ": " + row.Value2;
    return output;
};`;
      if (this.data.ToolTipTitleFunction) {
        this.data.ToolTipTitleFunction = code + "\n\n\n" + this.data.ToolTipTitleFunction;
      } else {
        this.data.ToolTipTitleFunction = code;
      }
    };
  }

  setupTooltipTextEditorActions() {
    this.tooltipTextEditorActions = new ButtonItem("Example", "js-square (brand)", "secondary");
    this.tooltipTextEditorActions.action = (event: EventModel) => {
      const code: string =
        `(row) => {
    // Use row object to build what you want the tooltip value to be
    let output = row.Value1 + ": " + row.Value2;
    return output;
};`;
      if (this.data.ToolTipTextFunction) {
        this.data.ToolTipTextFunction = code + "\n\n\n" + this.data.ToolTipTextFunction;
      } else {
        this.data.ToolTipTextFunction = code;
      }
    };
  }

  setupGetCellStyleEditorActions() {
    this.getStyleEditorActions = new ButtonItem("Example", "js-square (brand)", "secondary");
    this.getStyleEditorActions.action = (event: EventModel) => {
      const code: string =
        `(data, row) => {
    let style = "";
    let alert = false;
    // Some condition that would result in us wanting to alert the cell.
    // Input parameters: data is the cell value and row is the row object.
    if (false) {
      alert = true;
    }
    if (alert) {
      style = "color:red;";
    }
    //console.error("cell style", alert, style, data, row);
    return style;
};`;
      if (this.data.GetStyle) {
        this.data.GetStyle = code + "\n\n\n" + this.data.GetStyle;
      } else {
        this.data.GetStyle = code;
      }
    };
  }



  getOptionsPickListAsJsonString() {
    if (!this.data.OptionsPickList) {
      this.data.OptionsPickList = {};
    }
    return JSON.stringify(this.data.OptionsPickList, null, 4);
  }

  onOptionsPickListJsonStringChange($event) {
    // console.error("json changed", $event);
    if ($event.data) {
      let value: any = {};
      let success: boolean = false;
      try {
        value = JSON.parse($event.data);
        success = true;
      } catch (err) {
        // poorly formed JSON is expected while typing happens
        success = false;
        this.optionsPickListJsonError = err;
        // console.error('json err', err);
      }
      if (success) {
        this.data.OptionsPickList = value;
        this.optionsPickListJsonError = "";
      }
    } else {
      this.data.OptionsPickList = {};
    }
  }


  getFilterPickListAsJsonString() {
    if (!this.data.FilterPickListValues) {
      this.data.FilterPickListValues = {};
    }
    return JSON.stringify(this.data.FilterPickListValues, null, 4);
  }

  onFilterPickListJsonStringChange($event) {
    // console.error("json changed", $event);
    if ($event.data) {
      let value: any = {};
      let success: boolean = false;
      try {
        value = JSON.parse($event.data);
        success = true;
      } catch (err) {
        // poorly formed JSON is expected while typing happens
        success = false;
        this.filterPickListJsonError = err;
        // console.error('json err', err);
      }
      if (success) {
        this.data.FilterPickListValues = value;
        this.filterPickListJsonError = "";
      }
    } else {
      this.data.FilterPickListValues = {};
    }
  }


}
