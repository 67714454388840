import { Injectable, OnInit, OnDestroy, ErrorHandler } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationStart, RouterEvent, NavigationEnd, ActivationStart, Event } from '@angular/router';
import * as Enumerable from 'linq';
import { sub, isAfter } from 'date-fns';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";

import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { RecentlyUsedList } from 'projects/core-lib/src/lib/helpers/recently-used';
// import { Menu, MenuItem } from 'projects/core-lib/src/lib/helpers/menu';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiProperties, ApiCall, ApiOperationType, IApiResponseWrapperTyped, IApiResponseWrapper, CacheLevel } from 'projects/core-lib/src/lib/api/ApiModels';
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5auth from "projects/core-lib/src/lib/models/ngModelsAuth5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import { HelpContext } from 'projects/core-lib/src/lib/models/help-context';
import { ContactCacheModel } from 'projects/common-lib/src/lib/ux-models';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { AlertManager, AlertItemType, AlertItem } from 'projects/common-lib/src/lib/alert/alert-manager';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { Observable, Subject, Subscription, Observer, of, BehaviorSubject, AsyncSubject, timer, ReplaySubject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Favicons } from 'projects/common-lib/src/lib/image/favicon/fav-icons';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TranslationHelperService } from 'projects/core-lib/src/lib/services/translation-helper.service';
import { ApiModuleSecurity } from '../api/Api.Module.Security';
import { ApiModuleWeb } from '../api/Api.Module.Web';
import { ApiModuleCore } from '../api/Api.Module.Core';
import { CanDoWhat } from '../models/security';
import { BaseService } from './base.service';
import { Api } from '../api/Api';
import { GlobalErrorHandler } from './global-error-handler';
import { AppStatusService } from './app-status.service';
import { AppCacheService } from './app-cache.service';
import { AppAnalyticsService } from './app-analytics.service';
import { ContactPreferenceService } from './contact-preference.service';
import { SecurityService } from './security.service';
import { SearchService } from './search.service';
import { DynamicFormService } from './dynamic-form.service';
import { OptInFeatures } from '../models/model-helpers';
import { ApiModuleProxy } from '../api/Api.Module.Proxy';


@Injectable({
  providedIn: 'root'
})
export class AppService extends BaseService implements OnInit, OnDestroy {

  public alertManager: AlertManager;

  // public currencyHelper: currencyHelper;


  protected appInfoSubject2 = new ReplaySubject<m5core.ApplicationInformationModel>(1);
  public appInfoFeed(): Observable<m5core.ApplicationInformationModel> {
    // if (this._appInfo) {
    //   return of(this._appInfo);
    // }
    return this.appInfoSubject2.asObservable();
  }

  protected _appInfoLoaded: boolean = false;
  protected _appInfo: m5core.ApplicationInformationModel = null;
  /**
   * @deprecated Use appInfoFeed() instead which is an observable instead of subject and
   * will replay the last app info object instead of a default which is less helpful.  If
   * we need a default users can call appInfoOrDefault.
   */
  public appInfoSubject = new BehaviorSubject<m5core.ApplicationInformationModel>(this.getDefaultAppInfo());
  public get appInfo(): m5core.ApplicationInformationModel {
    return this._appInfo;
  }
  public set appInfo(info: m5core.ApplicationInformationModel) {
    this._appInfo = info;
    this.appInfoSubject.next(this._appInfo);
    this.appInfoSubject2.next(this._appInfo);
  }
  public get appInfoOrDefault(): m5core.ApplicationInformationModel {
    if (!this._appInfo) {
      return this.getDefaultAppInfo();
    }
    return this._appInfo;
  }
  protected _settings: m5core.AppSettingsEditViewModel = null;
  public get settings(): m5core.AppSettingsEditViewModel {
    return this._settings;
  }
  public set settings(settings: m5core.AppSettingsEditViewModel) {
    this._settings = settings;
    if (this._appInfo) {
      this._appInfo.Settings = settings;
      this.appInfoSubject.next(this._appInfo);
      this.appInfoSubject2.next(this._appInfo);
    }
  }


  protected _systemSettings: m5.SystemSettings = null;
  public get systemSettings(): m5.SystemSettings {
    return this._systemSettings;
  }
  public set systemSettings(settings: m5.SystemSettings) {
    this._systemSettings = settings;
  }





  private _user: m5sec.AuthenticatedUserViewModel = null;
  /**
   * @deprecated Use userFeed() instead which is an observable instead of subject and
   * will replay the last user object instead of a default which is less helpful.  If
   * we need a default users can call userOrDefault.
   */
  public userSubject = new BehaviorSubject<m5sec.AuthenticatedUserViewModel>(new m5sec.AuthenticatedUserViewModel());
  public get user(): m5sec.AuthenticatedUserViewModel {
    return this._user;
  }
  public set user(user: m5sec.AuthenticatedUserViewModel) {
    this.userSet(user, undefined);
  }
  public get userOrDefault(): m5sec.AuthenticatedUserViewModel {
    if (this._user) {
      return this._user;
    }
    return new m5sec.AuthenticatedUserViewModel();
  }

  protected userSubject2 = new ReplaySubject<m5sec.AuthenticatedUserViewModel>(1);
  public userFeed(): Observable<m5sec.AuthenticatedUserViewModel> {
    // if (this._user) {
    //   return of(this._user);
    // }
    // tryGetUser will hydrate user object from local storage if appropriate and will
    // emit via the user subject so that value is available to subscribers.
    this.tryGetUser(false);
    return this.userSubject2.asObservable();
  }

  public get isUserDirectoryUser(): boolean {
    return (this.userOrDefault.ParentContactType === Constants.ContactType.Directory);
  }
  public get isUserCustomer(): boolean {
    return (this.userOrDefault.ParentContactType === Constants.ContactType.Customer);
  }
  public get isUserPartitionZeroDirectoryUser(): boolean {
    return (this.userOrDefault.ParentContactType === Constants.ContactType.Directory && this.userOrDefault.PartitionId === 0);
  }
  public get isUserScopeAdministrator(): boolean {
    return this.hasUserScope(Constants.ContactScope.Administrator);
  }
  public get isUserScopeLimited(): boolean {
    return this.hasUserScope(Constants.ContactScope.Limited);
  }
  public get isUserScopeTerminated(): boolean {
    return this.hasUserScope(Constants.ContactScope.Terminated);
  }
  public get isUserScopeNormal(): boolean {
    // For normal let's make sure we're not admin, limited, or terminated
    if (this.isUserScopeAdministrator) {
      return false;
    } else if (this.isUserScopeLimited) {
      return false;
    } else if (this.isUserScopeTerminated) {
      return false;
    }
    // If not admin, limited, or terminated then we're going to consider ourselves normal
    return true;
    // return this.hasUserScope(Constants.ContactScope.Normal);
  }
  public hasUserScope(scope: string): boolean {
    if (!scope) {
      return false;
    }
    let scopes: string[] = [];
    if (this.userOrDefault.Flags) {
      scopes = this.userOrDefault.Flags;
    }
    return (scopes.filter(x => Helper.equals(x, scope, true) || Helper.equals(x, `Scope:${scope}`, true)).length > 0);
  }

  /**
   * Checks if the current user has permission for requested access area and permission flag.
   * @param accessArea The access area.
   * @param permission The permission flag: S (read single), R (read), A (add), E (edit), D (delete), O (output), X (execute), or F (full).
   * @returns
   */
  public hasPermission(accessArea: string, permission: string): boolean {
    return this.security.hasPermission(this.user, accessArea, permission);
  }


  /**
   * Checks if the current user has the specified role.
   * @returns
   */
  public hasRole(role: string): boolean {
    return this.security.hasRole(this.user, role);
  }


  /**
   * Checks if the current user has the specified auth role.
   * @returns
   */
  public hasAuthRole(role: string | m5auth.AuthenticationRole): boolean {
    return this.security.hasAuthRole(this.user, role);
  }


  /**
  Some things like sign up forms, action links, etc. allow anonymous access via our anonymous access api key.
  When we are running in that scenario setting this to true will prevent redirect to login screen that might
  otherwise get triggered.  If such form results in expected change from anonymous access to authenticated
  access that process sets this false and redirects to the login screen as appropriate.
  */
  public allowAnonymousAccess: boolean = false;


  // public help: Help = new Help(""); // We'll update this once we have a branding support url

  public get showHelpMenu(): boolean {
    if (!this._appInfo) {
      return false;
    } else if (!this._appInfo.Branding) {
      return false;
    } else if (!this._appInfo.Branding.SupportEmailAddress &&
      !this._appInfo.Branding.SupportUrlPortal &&
      !this._appInfo.Branding.SupportUrlIndex &&
      !this._appInfo.Branding.SupportUrlTopicTemplate) {
      // No help to link to
      return false;
    }
    // We have branding and links to facilitate help
    return true;
  }

  public get isBrandNubill(): boolean {
    return (this.appInfoOrDefault.Branding.BrandId === m.BrandId.Nubill);
  }
  public get isBrandIntelliBOSS(): boolean {
    return (this.appInfoOrDefault.Branding.BrandId === m.BrandId.IntelliBOSS);
  }
  public get isBrandReportCompiler(): boolean {
    return (this.appInfoOrDefault.Branding.BrandId === m.BrandId.ReportCompiler);
  }
  public get isBrandProtoBilling(): boolean {
    return (this.appInfoOrDefault.Branding.BrandId === m.BrandId.ProtoBilling);
  }
  public get isBrandNetWiseCRM(): boolean {
    return (this.appInfoOrDefault.Branding.BrandId === m.BrandId.NetWiseCRM);
  }
  public get isBrandQupport(): boolean {
    return (this.appInfoOrDefault.Branding.BrandId === m.BrandId.Qupport);
  }

  public hideStandardSiteElementsRouteList: string[] = ["/login*", "/notice*", "/subscriptions/new*", "/link*"];

  /**
  Some things like sign up forms, action links, etc. want standard site elements to be hidden without
  the overhead of updating our route list and can set this flag to true which will result in those elements
  being hidden until this is set back to false.  If such page results in expected change to start showing
  those standard site elements that process sets this false.
  */
  public hideStandardSiteElementsFlag: boolean = false;


  /**
  This is the current page title.  Since the app service is a global singleton any consumer can
  get or set this value.
  */
  public get title(): string {
    return this.titleService.getTitle();
  }
  public set title(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  public titleReset() {
    // Our title defaults to AppConfig.name but some partition domains may have custom title to use
    this.title = AppConfig.name;
    this.tryGetAppInfo().pipe(takeUntil(this.ngUnsubscribe)).subscribe((info: m5core.ApplicationInformationModel) => {
      this.title = Helper.getFirstDefinedString(info?.Domain?.DisplayName, AppConfig.name);
    });
  }

  public favIconReset(): void {

    let iconSet = false;

    // First see if we have a custom branding icon name set.  This is typically null but can be set.
    if (this.appInfoOrDefault.Branding.IconName) {
      iconSet = this.favicons.activate(this.appInfoOrDefault.Branding.IconName);
      if (iconSet) {
        return;
      }
    }

    // See if we have a custom url for the icon
    if (this.config.iconUrl) {
      this.setCustomIcon(this.config.iconUrl, this.config.iconType);
      return;
    }

    // See if we have a config dictated icon name.
    if (this.config.icon) {
      iconSet = this.favicons.activate(this.config.icon);
      if (iconSet) {
        return;
      }
    }

    // If we didn't have an icon configured or if setting it failed then use the brand to determine the icon to use
    if (!iconSet) {
      if (this.isBrandReportCompiler) {
        this.favicons.activate("reportcompiler");
      } else if (this.isBrandNetWiseCRM) {
        this.favicons.activate("netwisecrm");
      } else if (this.isBrandNubill) {
        this.favicons.activate("nubill");
      } else if (this.isBrandQupport) {
        this.favicons.activate("qupport");
      } else if (this.isBrandIntelliBOSS) {
        this.favicons.activate("intelliboss");
      } else {
        this.favicons.reset();
      }
    }

  }


  // public notices: m5.AssetEditViewModel[] = [];
  protected taskStatus: m5.TaskListStatusViewModel = new m5.TaskListStatusViewModel();
  protected taskStatusSubject = new BehaviorSubject<m5.TaskListStatusViewModel>(new m5.TaskListStatusViewModel());
  public taskStatusMonitor() { return this.taskStatusSubject.asObservable(); }
  protected taskStatusUpdateSubscription: Subscription;
  protected taskStatusInterval: number = 300000; // 5 minutes


  /**
  Quick access to app config without declaring AppConfig everywhere.
  */
  public config: IAppConfig = AppConfig;

  // List of recently accessed customers and cases
  public recentCustomers: RecentlyUsedList = new RecentlyUsedList(10, "recentCustomers");
  public recentProspects: RecentlyUsedList = new RecentlyUsedList(10, "recentProspects");
  public recentMarketingContacts: RecentlyUsedList = new RecentlyUsedList(10, "recentMarketingContacts");
  public recentCases: RecentlyUsedList = new RecentlyUsedList(10, "recentCases");
  public pageHistory: RecentlyUsedList = new RecentlyUsedList(15, "pageHistory");


  protected copyrightHtml: string = null;
  protected patentHtml: string = null;
  protected poweredByHtml: string = null;
  protected logoUrl: string = null;


  /**
  We log certain warnings to the console but we only want to do it once so use this object to keep track.
  */
  protected loggedWarning: any = {};

  protected routeEventSubscription: Subscription;

  /**
   * Controls whether the task status check will be able to run.
   */
  protected isTaskStatusCheckEnabled: boolean = true;
  public get taskStatusEnabled(): boolean {
    return this.isTaskStatusCheckEnabled;
  }
  public set taskStatusEnabled(isEnabled: boolean) {
    this.isTaskStatusCheckEnabled = isEnabled;
  }

  constructor(
    public router: Router,
    public location: Location,
    public apiService: ApiService,
    public security: SecurityService,
    public search: SearchService,
    public status: AppStatusService,
    public cache: AppCacheService,
    public error: ErrorHandler, // this will resolve to GlobalErrorHandler (see app module provides collection)
    public analytics: AppAnalyticsService,
    public translation: TranslationHelperService,
    public titleService: Title,
    public preferences: ContactPreferenceService,
    public favicons: Favicons) {

    super();
    // console.error("APPSERVICE CONSTRUCTOR!");
    try {
      this.alertManager = new AlertManager(this.router);
    } catch (err) {
      Log.errorMessage("Error setting up alert manager.");
      Log.errorMessage(err);
    }
    // try {
    //  this.currencyHelper = new currencyHelper();
    // } catch (err) {
    //  Log.errorMessage("Error setting up currency helper.");
    //  Log.errorMessage(err);
    // }

    try {
      this.init();
    } catch (err) {
      Log.errorMessage("Error in app service init.");
      Log.errorMessage(err);
    }

  }

  // ngOnInit() {
  //  super.ngOnInit();
  //  //console.error("app service ngOnInit");
  // }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.appInfoSubject.complete();
    this.appInfoSubject2.complete();
    this.userSubject.complete();
    this.userSubject2.complete();
    this.taskStatusSubject.complete();
    this.helpLinkSubject.complete();
    // console.error("app service ngOnDestroy");
    if (this.routeEventSubscription) {
      this.routeEventSubscription.unsubscribe();
    }
  }



  public init(): void {

    // Do some config
    // try {
    //  this.configCharts();
    // } catch (ex) {
    //  Log.LogErrorMessage(ex);
    // }
    // try {
    //  this.configCache();
    // } catch (ex) {
    //  Log.LogErrorMessage(ex);
    // }
    try {
      this.configRouteChangeLogging();
    } catch (ex) {
      Log.errorMessage(ex);
    }

    // Load system settings
    try {
      this.systemSettingsLoad();
    } catch (ex) {
      Log.errorMessage(ex);
    }

    // Expose a few internals to the global context to better support custom web app plugins
    try {
      if (!(window as any).IbApp) {
        (window as any).IbApp = {};
      }
      (window as any).IbApp.Helper = Helper;
      (window as any).IbApp.ApiHelper = ApiHelper;
      // Maybe putting all our models in global is too heavy and we just let the custom web apps handle what they need on their own?
      // (window as any).IbApp.Models = {};
      // (window as any).IbApp.Models.Root = m;
      // (window as any).IbApp.Models.Common = m5;
      // (window as any).IbApp.Models.Core = m5core;
      // (window as any).IbApp.Models.Web = m5web;
      // (window as any).IbApp.Models.Security = m5sec;
    } catch (ex) {
      Log.errorMessage(ex);
    }

    // Load any custom scripts defined in our config.js
    if (this.config.customScripts && this.config.customScripts.length > 0) {
      this.config.customScripts.forEach(script => {
        try {
          this.loadExternalScript(script);
        } catch (ex) {
          Log.errorMessage(ex);
        }
      });
    }

    // Load any custom styles defined in our config.js
    if (this.config.customStyles && this.config.customStyles.length > 0) {
      this.config.customStyles.forEach(style => {
        try {
          this.loadExternalStyles(style);
        } catch (ex) {
          Log.errorMessage(ex);
        }
      });
    }

    // We quickly need values for things like theme, anonymous api key, etc. we will later update this
    // with data we get from the server which may differ based on url, token, etc.
    // this._appInfo = this.getDefaultAppInfo();
    this.settings = this.getDefaultAppInfo().Settings;

    // Get and save our app info... no need to act on this promise since the method
    // itself will update the appInfo and settings properties of this service object.
    this.tryGetAppInfo();

    // No action needed on promise returned here since this method will store user object for future access
    this.tryGetUser(false);

    // Set title & favicon
    this.titleReset();
    this.favIconReset();

    // Set up task status check so we can alert when there are new tasks
    // if this is app (not valid for api docs or kiosk). During remoteJS sessions, isTaskStatusCheckEnabled will be set
    // to false to prevent this check to cut down on remote console noise.
    if (this.config.type === "app" && this.isTaskStatusCheckEnabled) {
      this.taskStatusUpdateSubscription = timer(0, this.taskStatusInterval)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(result => {
          this.refreshTaskStatus();
        });
    }

  }


  /**
   * We don't normally want trace for getting task status but if
   * we encounter an error we'll flip trace on until we see success
   * just in case the error is something we need reported.
   */
  refreshTaskStatusTrace: boolean = false;

  public refreshTaskStatus() {
    // If not logged in then no action to take
    if (!this.user) {
      return;
    }
    const apiProp = Api.UserTaskStatus();
    const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
    apiCall.redirectToLoginOnAuthenticationErrors = false;
    apiCall.silent = true;
    apiCall.trace = this.refreshTaskStatusTrace;
    this.apiService.execute(apiCall, null).subscribe((result: IApiResponseWrapperTyped<m5.TaskListStatusViewModel>) => {
      if (result.Data.Success) {
        this.refreshTaskStatusTrace = false;
        this.taskStatus = result.Data.Data;
        this.taskStatusSubject.next(this.taskStatus);
      } else {
        this.refreshTaskStatusTrace = true;
        console.error(result);
      }
    });
  }
  /*
   * This method is used to adjust the task count in real time when users
   * add, delete, or complete tasks so the count displayed in the UI can
   * stay in sync with what they know they just did.
   */
  public adjustTaskStatus(adjustment: number, highPriority: boolean) {
    this.taskStatus.TotalCount += adjustment;
    if (highPriority) {
      this.taskStatus.TotalCountHighPriority += adjustment;
    } else {
      this.taskStatus.TotalCountNotHighPriority += adjustment;
    }
    this.taskStatusSubject.next(this.taskStatus);
  }


  public setCustomIcon(url: string, type: "icon" | "png" | "jpg") {
    this.favicons.setCustomIcon(url, type);
  }


  public redirectToHome = () => {
    // Calling this method before any redirect will result in any partner external auth token (PEAT) found in our query string being persisted to local storage
    ApiHelper.getPartnerExternalAuthenticationToken();
    // Set home page based on app type
    if (this.config.type === "app") {
      let appHomePage: string = this.appInfoOrDefault.Settings.PortalHomePage;
      // console.error("home", appHomePage);
      if (!appHomePage) {
        // The dashboard is our default home page when none is specified
        appHomePage = "/dashboard";
      }
      if (this.appInfoOrDefault.Settings.PortalHomePageCanBeCustomized) {
        this.preferenceObjectGet(Constants.ContactPreference.ApplicationSettings).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(preference => {
            if (preference?.PortalHomePage) {
              this.router.navigate([preference.PortalHomePage]);
            } else {
              // User doesn't have a custom home page
              this.router.navigate([appHomePage]);
            }
          });
      } else {
        // We don't allow users to customize the home page
        this.router.navigate([appHomePage]);
      }
    } else if (this.config.type === "api-docs") {
      this.router.navigate(["/", "overview", "introduction"]);
    } else {
      this.router.navigate(["/"]);
    }
  };

  public redirectToDashboard = () => {
    // Calling this method before any redirect will result in any partner external auth token (PEAT) found in our query string being persisted to local storage
    ApiHelper.getPartnerExternalAuthenticationToken();
    this.router.navigate(["/dashboard"]);
  };

  public redirectToOrgQuickStart = () => {
    if (!this.isLoggedIn()) {
      return;
    }
    // Calling this method before any redirect will result in any partner external auth token (PEAT) found in our query string being persisted to local storage
    ApiHelper.getPartnerExternalAuthenticationToken();
    if (this.isBrandReportCompiler) {
      this.router.navigate(["/", "rc", "config", "quick-start"]);
    }
    // TODO add quick start wizards for other brands
  };

  public redirectToUserQuickStart = () => {
    if (!this.isLoggedIn()) {
      return;
    }

    // Calling this method before any redirect will result in any partner external auth token (PEAT) found in our query string being persisted to local storage
    ApiHelper.getPartnerExternalAuthenticationToken();

    if (this.isBrandReportCompiler) {
      this.router.navigate(["/", "rc", "config", "quick-start", "new-user"]);
    } else {
      this.router.navigate(["/", "profile", "quick-start"]);
    }
  };

  public redirectToReportParser = () => {
    if (!this.isLoggedIn()) {
      return;
    }

    // Calling this method before any redirect will result in any partner external auth token (PEAT) found in our query string being persisted to local storage
    ApiHelper.getPartnerExternalAuthenticationToken();
    this.router.navigate(["/", "rc", "library", "report-parser"]);
  };

  // TODO silent login
  public redirectToLogin = (attemptSilentLogin: boolean = false) => {
    // Sometimes we're willing to accept a silent login and if that is the case and we have a token to support silent logins then do that
    if (attemptSilentLogin) {
      // Try to get a user object... if there is a valid token and successful we're gold.  If it fails it will call this
      // redirectToLogin() method but this time attemptSilentLogin will be false and we'll go through the normal login process.
      this.tryGetUser(true);
      return;
    } else {
      this.userClear();
      if (Helper.getQueryStringParameter("returnUrl")) {
        // We already have returnUrl query string parameter so pass it through don't append it again
        this.router.navigate(["/login"], { queryParams: { returnUrl: Helper.getQueryStringParameter("returnUrl") } });
      } else if (this.router.routerState.snapshot.url) {
        // Oddly sometimes this is not provided so we check window.location.pathname first
        this.router.navigate(["/login"], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
      } else if (window.location.pathname) {
        // If we're not on the login page then append our path to returnUrl query string
        if (Helper.startsWith(window.location.pathname, "/login", true)) {
          this.router.navigate(["/login"]);
        } else {
          this.router.navigate(["/login"], { queryParams: { returnUrl: window.location.pathname } });
        }
      } else {
        this.router.navigate(["/login"]);
      }
    }
  };




  /**
  Checks for user object.  If found it is returned... if not found redirected to login.
  @param {boolean} allowLoginRedirect If true we will redirect to the login page if we know we can't get the AuthenticatedUserViewModel object
  @returns {Observable} Returns an observable that will resolve to the user view model object for the logged in user or null.
  */
  public tryGetUser(allowLoginRedirect: boolean = true, reload: boolean = false): Observable<m5sec.AuthenticatedUserViewModel> {

    // Get our current user object if available
    if (this._user && !reload) {
      if (!this.apiService.currentUserPartitionId) {
        this.apiService.currentUserPartitionId = this._user.PartitionId;
      }
      // We don't want to emit a new value if it's the same as the current value since that will trigger all
      // subscribers to do some action which isn't needed if the actual user did not change at all so try to
      // be smart here about how often we call userSubject.next() instead of just calling it all the time.
      if (this.userSubject.getValue().ContactId !== this._user.ContactId) {
        this.userSubject.next(this._user);
        this.userSubject2.next(this._user);
      } else if (this.userSubject.getValue().Token !== this._user.Token) {
        this.userSubject.next(this._user);
        this.userSubject2.next(this._user);
      } else if (this.userSubject.getValue() !== this._user) {
        this.userSubject.next(this._user);
        this.userSubject2.next(this._user);
      }
      // Don't call this.userFeed() since that calls tryGetUser()
      return this.userSubject2.asObservable();
    }

    // Get our cached user object if available
    if (!reload) {
      let rememberMe1 = true;
      let user = Helper.localStorageGetObject<m5sec.AuthenticatedUserViewModel>(Constants.LocalStorage.UserObject, null);
      if (!user) {
        rememberMe1 = false;
        user = Helper.sessionStorageGetObject<m5sec.AuthenticatedUserViewModel>(Constants.LocalStorage.UserObject, null);
      }
      // If our cached user object is too old then we'll pass back what we have but we're also going to do a silent refresh
      if (user) {
        let silentRefresh: boolean = false;
        if (!user.AsOfUtc) {
          silentRefresh = true;
        } else {
          const expires = sub(new Date(), { hours: 8 });
          if (isAfter(expires, new Date(user.AsOfUtc))) {
            silentRefresh = true;
          }
        }
        if (silentRefresh) {
          Log.debugMessage("User object is old so accepting what we have but also doing a silent refresh.");
          this.loadUserObject(false, rememberMe1);
        }
      }
      if (user) {
        this._user = user;
        if (!this.apiService.currentUserPartitionId) {
          this.apiService.currentUserPartitionId = this._user.PartitionId;
        }
        this.translation.preferredLanguage = Helper.getFirstDefinedString(user.PreferredLanguage, "en");
        (this.error as GlobalErrorHandler).setUser(user);
        this.analytics.setUser(user);
        this.userSubject.next(this._user);
        this.userSubject2.next(this._user);
        // With user defined (and, therefore, authenticated) we can get org settings and security policies loaded
        // we don't need them yet but we'll get them loaded so they're ready when we do need them.
        this.security.getOrganizationSettings(this._user.PartitionId, true);
        this.security.getSecurityPolicies(true);
        // Don't call this.userFeed() since that calls tryGetUser()
        return this.userSubject2.asObservable();
      }
    }

    // No user object but maybe we have a token that can be used to get a user object from the server
    let rememberMe2 = true;
    let token = Helper.localStorageGet(Constants.LocalStorage.AuthenticationToken + "-" + AppConfig.apiUrl, "");
    if (!token) {
      rememberMe2 = false;
      token = Helper.sessionStorageGet(Constants.SessionStorage.AuthenticationToken + "-" + AppConfig.apiUrl, "");
    }
    // How about a partner external auth token (peat)?
    // const peat = Helper.localStorageGet(Constants.LocalStorage.PartnerExternalAuthenticationToken, "");
    // Use this helper which will check local storage or query string parameter
    const peat = ApiHelper.getPartnerExternalAuthenticationToken();

    // No user and now no token(s)?  Not much we can do other than go to the login page (assuming we're allowing that)
    if (!token && !peat) {
      if (allowLoginRedirect) {
        Log.debug("ui", "Redirect", "No valid user object or token found in local or session storage so redirecting to login.", true);
        this.redirectToLogin();
      } else {
        Log.debug("ui", "Redirect Blocked", "No valid user object or token found in local or session storage but blocked from redirecting to login.", true);
      }
      // Don't call this.userFeed() since that calls tryGetUser()
      return this.userSubject2.asObservable();
    }

    Log.debugMessage("No valid user object in local or session storage but found token so attempting to get user object.");
    this.loadUserObject(allowLoginRedirect, rememberMe2);

    // Don't call this.userFeed() since that calls tryGetUser()
    return this.userSubject2.asObservable();

  }


  /**
   * Loads user object from server and sets result when found.
   * @param allowLoginRedirect
   * @param rememberMe
   */
  public loadUserObject(allowLoginRedirect: boolean = true, rememberMe: boolean = false): void {
    const authApiProp = ApiModuleSecurity.SecurityAuthenticate(AppConfig.apiVersion);
    const authApiCall = ApiHelper.createApiCall(authApiProp, ApiOperationType.Get);
    this.apiService.execute(authApiCall, null).subscribe((result: IApiResponseWrapperTyped<m5sec.AuthenticatedUserViewModel>) => {
      if (result.Data.Success) {
        // If we authenticated using a partner token the rememberMe should be on by default as this is a hidden authentication
        // method sent enabled via partner token not a user sign on scenario.
        if (result.Data.Data && result.Data.Data.AuthenticationData && Helper.contains(result.Data.Data.AuthenticationData.Subtype, "Partner", true)) {
          rememberMe = true;
        }
        // userSet() will push object into userSubject for observation among other things
        this.userSet(result.Data.Data, rememberMe);
        return this._user;
      } else {
        this.alertManager.addAlertFromApiResponse(result, authApiCall);
        if (allowLoginRedirect) {
          Log.debug("ui", "Redirect", "Attempt to authenticate user based on token failed so redirecting to login.", true);
          this.redirectToLogin();
        } else {
          Log.debug("ui", "Redirect Blocked", "Attempt to authenticate user based on token failed but blocked from redirecting to login.", true);
        }
        return null;
      }
    });
  }


  /**
  Gets promise to application info object.
  @returns {Observable} Returns an observable that will resolve to the app info object once loaded
  */
  public tryGetAppInfo(reload: boolean = false): Observable<m5core.ApplicationInformationModel> {
    // If we already have an app object then we resolve to that
    if (this._appInfo && this._appInfoLoaded && !reload) {
      return of(this._appInfo);
    }

    const subject = new AsyncSubject<m5core.ApplicationInformationModel>();

    // Get app info from server which can be custom per domain name.
    const apiProperties: ApiProperties = ApiModuleCore.ApplicationInformation(AppConfig.apiVersion);
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProperties, ApiOperationType.Get);
    // Since we may not be authenticated at this point use the api key from the config.  This is locked down on the server for read-only access to non-critical data.
    // don't assume this... createApiCall() will use anonymous api key if needed ... apiCall.apiKey = AppConfig.anonymousApiKey;
    apiCall.silent = true; // We don't want spinner running for this

    // Uncomment if we see too many hits to the API for this info so we can see who is triggering this
    // Log.errorMessage("Trying to get AppInfo", Log.getStackTrace());

    this.apiService.execute(apiCall, window.location.hostname).subscribe((response: IApiResponseWrapperTyped<m5core.ApplicationInformationModel>) => {
      if (response.Data.Success) {
        this.configureAppInfo(response.Data.Data);
        this.appInfoSubject.next(this._appInfo);
        this.appInfoSubject2.next(this._appInfo);

        if (this.canRouteToOrgWizard() && !this.canRouteToUserWizard()) {
          this.redirectToOrgQuickStart();
        } else if (this.canRouteToUserWizard()) {
          this.redirectToUserQuickStart();
        }

        // Mark complete after routing has taken place, that way anyone waiting for that knows it happened.
        subject.next(this._appInfo);
        subject.complete();
      } else {
        this.alertManager.addAlertFromApiResponse(response, apiCall);
        Log.errorMessage(response);
        // Now do a default for our settings so we at least have something
        this.configureAppInfo(this.getDefaultAppInfo());
        this.appInfoSubject.next(this._appInfo);
        this.appInfoSubject2.next(this._appInfo);
        subject.next(this._appInfo);
        subject.complete();
      }

    });

    return subject.asObservable();

  }

  protected configureAppInfo(info: m5core.ApplicationInformationModel) {

    this._appInfo = info;
    this._appInfoLoaded = true; // Flag that we now have an object loaded and are not just using the default

    // If our results don't provide any branding then let's try to do our best
    if (!this._appInfo.Branding || this._appInfo.Branding.BrandId === m.BrandId.Unknown) {
      this._appInfo.Branding = this.getDefaultAppInfo().Branding;
    }

    // Save settings, translation information, etc.
    if (this._appInfo.Settings) {
      this.settings = this._appInfo.Settings;
      if (this._appInfo.Settings.LanguageDefault) {
        this.config.languageDefault = this._appInfo.Settings.LanguageDefault;
        this.translation.defaultLanguage = this._appInfo.Settings.LanguageDefault;
      }
      if (this._appInfo.Settings.LanguagesSupported && this._appInfo.Settings.LanguagesSupported.length > 0) {
        this.config.languagesSupported = this._appInfo.Settings.LanguagesSupported;
        this.translation.supportedLanguages = this._appInfo.Settings.LanguagesSupported;
      }
    }

    // There are a few app info settings that we need to have
    this._appInfo.Theme = Helper.getFirstDefinedString(this._appInfo.Theme, AppConfig?.styleSettings?.theme, "default");
    this._appInfo.AnonymousApiKey = Helper.getFirstDefinedString(this._appInfo.AnonymousApiKey, AppConfig.anonymousApiKey);
    // Save back to AppConfig.anonymousApiKey which is utilized when we make an API call w/o any other authentication information.
    AppConfig.anonymousApiKey = this._appInfo.AnonymousApiKey;

    /// / Refresh our help object now that we have a support url
    // this.help = new Help(this._appInfo.Branding.SupportUrlTopicTemplate);

    // Reset favicon since app info may have had custom icon name
    this.favIconReset();

    // Configure any custom routes we were given
    if (this._appInfo && this._appInfo.Routes && this._appInfo.Routes.length > 0) {
      // console.error("Ready to apply routes");
      // console.error(this._appInfo.Routes);
      this._appInfo.Routes.forEach((route) => {
        // If the route requests standard site elements be hidden then push into our list of URLs where that is true.
        if (route.HideStandardSiteElements) {
          if (this.hideStandardSiteElementsRouteList.indexOf(route.RouteUrl) === -1) {
            this.hideStandardSiteElementsRouteList.push(route.RouteUrl);
          }
        }
      });
    }

  }

  public getBrandId(): m.BrandId {

    // See if we have app info that has a valid brand
    if (this._appInfo && this._appInfo.Branding) {
      if (this._appInfo.Branding.BrandId !== m.BrandId.Unknown) {
        return this._appInfo.Branding.BrandId;
      }
    }

    // See if we have an explicit brand set in the config
    let brandId = this.getBrandIdFromUrlOrName(AppConfig.brand);
    if (brandId !== m.BrandId.Unknown) {
      return brandId;
    }

    // See if our url can be used to know the brand
    brandId = this.getBrandIdFromUrlOrName(window.location.hostname);
    if (brandId !== m.BrandId.Unknown) {
      return brandId;
    }

    // Last ditch effort maybe the name will give us a clue
    return this.getBrandIdFromUrlOrName(AppConfig.name);

  }

  protected getBrandIdFromUrlOrName(name: string): m.BrandId {

    // First things first if this is a support site domain name then the brand is Qupport since that hosts our support portals
    if (Helper.contains(name, "support.", true)) {
      return m.BrandId.Qupport;
    }

    // See if we can tell from keyword or domain name what brand we are
    if (Helper.contains(name, "ReportCompiler", true) || Helper.contains(name, "report compiler", true)) {
      return m.BrandId.ReportCompiler;
    } else if (Helper.contains(name, "NetWiseCRM", true) || Helper.contains(name, "OurCRM", true) || Helper.contains(name, "CRM", true)) {
      return m.BrandId.NetWiseCRM;
    } else if (Helper.contains(name, "Qupport", true)) {
      return m.BrandId.Qupport;
    } else if (Helper.contains(name, "nubill", true)) {
      return m.BrandId.Nubill;
    } else if (Helper.contains(name, "Fortisoft", true) || Helper.contains(name, "Intelli", true)) {
      return m.BrandId.IntelliBOSS;
    }

    // Well, we tried...
    return m.BrandId.Unknown;

  }

  protected getDefaultAppInfo(): m5core.ApplicationInformationModel {
    const info = new m5core.ApplicationInformationModel();
    info.Branding = new m.PartitionBrandModel();
    info.Branding.BrandId = this.getBrandId();
    info.Branding.Brand = AppConfig.brand;
    info.Branding.ApplicationNameLong = AppConfig.name;
    info.Branding.ApplicationNameShort = AppConfig.name;
    info.Branding.ShowPoweredByMessage = (AppConfig.poweredBy !== "");
    info.Branding.ShowCopyrightMessage = (AppConfig.copyright !== "");
    info.Branding.SupportEmailAddress = AppConfig.supportEmailAddress || "";
    info.Branding.SupportUrlPortal = AppConfig.supportUrlPortal || "";
    info.Branding.SupportUrlIndex = AppConfig.supportUrlIndex || "";
    info.Branding.SupportUrlTopicTemplate = AppConfig.supportUrlTopicTemplate || "";
    info.LogoUrl = AppConfig.logoUrl;
    info.Theme = Helper.getFirstDefinedString(AppConfig?.styleSettings?.theme, "default");
    info.AnonymousApiKey = AppConfig.anonymousApiKey;
    info.Services = AppConfig.services;
    info.Modules = AppConfig.modules;
    info.Settings = new m5core.AppSettingsEditViewModel();
    return info;
  }

  public hasModule(module: string, logWarningIfModuleMissing: boolean = false): boolean {
    // return Enumerable.from<string>(this.appInfoOrDefault.Modules).any(x => (Helper.equals(x, module, true)));
    if (Helper.firstOrDefault(this.appInfoOrDefault.Modules, x => Helper.equals(x, module, true))) {
      return true;
    } else {
      if (logWarningIfModuleMissing) {
        console.warn(`Module ${module} does not exist in the list of modules.`);
      }
      return false;
    }
  }

  public hideStandardSiteElements(path: string = ""): boolean {
    // See if our global flag has site elements turned off
    if (this.hideStandardSiteElementsFlag) {
      return true;
    }
    // Try to see if our path is one that has site elements turned off
    let found = false;
    if (!path) {
      path = this.location.path().toLowerCase();
    } else {
      path = path.toLowerCase();
    }
    this.hideStandardSiteElementsRouteList.forEach((value: string) => {
      if (!found && value) {
        // When there is a wildcard our path only needs to start with the value
        if (Helper.contains(value, "*")) {
          value = value.replace("*", "").toLowerCase();
          if (path.startsWith(value)) {
            // Log.LogDebug("ui", "Site", path + " configured to hide site chrome since it starts with " + value + ".");
            found = true;
          }
        } else if (path === value.toLowerCase()) {
          // Log.LogDebug("ui", "Site", path + " configured to hide site chrome since it equals " + value + ".");
          found = true;
        }
      }
    });
    return found;
  }

  public isLoginPath(path: string = ""): boolean {
    // Try to see if our path is part of the login path e.g. /login*
    if (!path) {
      path = this.location.path().toLowerCase();
    } else {
      path = path.toLowerCase();
    }
    return path.startsWith("/login");
  }

  public workareaHeight(componentHeightUsed: number = 100): number {
    // Start with inner height
    let height: number = window.innerHeight;
    // Remove size of header
    height = height - 64;
    // Remove size of footer
    height = height - 30;
    // Remove size of padding
    height = height - 10;
    // Remove common component title, tab, etc. space
    height = height - componentHeightUsed;
    // What's left is our work area height
    return height;
  }

  public isLoggedIn(): boolean {
    if (this._user) {
      return true;
    } else {
      return false;
    }
  }

  public login(): void {
    this.logout(false);
  }

  public logout(flagLogoutAction: boolean = true): void {
    // Clear current user
    this.userClear();
    // Login could be to a different partition so clear the memory cache
    this.apiService.cache.cacheClear();
    // Route to login
    try {
      const params: any = {};
      if (flagLogoutAction) {
        params.action = "logout";
      }
      this.router.navigate(["/login"], { queryParams: params });
    } catch (err) {
      Log.errorMessage(err);
    }
  }

  public userClear(): void {
    this.apiService.currentUserPartitionId = null;
    try {
      delete window.sessionStorage[Constants.LocalStorage.AuthenticationToken + "-" + AppConfig.apiUrl];
      delete window.sessionStorage[Constants.LocalStorage.UserObject];
      delete window.localStorage[Constants.LocalStorage.AuthenticationToken + "-" + AppConfig.apiUrl];
      delete window.localStorage[Constants.LocalStorage.UserObject];
      delete window.localStorage[Constants.LocalStorage.PartnerExternalAuthenticationToken];
    } catch (err) {
      Log.errorMessage(err);
    }
    try {
      window.sessionStorage.clear();
    } catch (err) {
      Log.errorMessage(err);
    }
    try {
      this._user = null;
      // console.error("USER OBJ SET TO NULL");
      // console.error(this.user);
      this._appInfoLoaded = false;
      this._appInfo = null;
      this._settings = null;
    } catch (err) {
      Log.errorMessage(err);
    }
  }

  public userSet(user: m5sec.AuthenticatedUserViewModel, rememberMe: boolean, saveToken: boolean = true): Observable<boolean> {

    const subject = new AsyncSubject<boolean>();

    if (!user) {
      subject.next(false);
      subject.complete();
      return subject.asObservable();
    }

    this._user = user;

    // If we have a proxy defined then we need to call the proxy register endpoint so we get cookies the proxy needs
    if (this.config.proxyUrl) {
      const apiProp = ApiModuleProxy.Register();
      const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Call);
      apiCall.silent = true;
      const model = new m.ProxyRegistrationViewModel();
      model.ProxyDataSourceId = this.config.proxyDataSourceId;
      model.ContactId = this._user.ContactId;
      model.ApiKey = this._user.ApiKey;
      model.Token = this._user.Token;
      this.apiService.call(apiCall, model).subscribe((response: IApiResponseWrapper) => {
        if (response.Data.Success) {
          //console.error(response.Data);
        } else {
          console.error(response.Data);
        }
      });
    }

    this.syncStorageWithUser(rememberMe, saveToken);

    this.apiService.currentUserPartitionId = user.PartitionId;
    this.userSubject.next(this._user);
    this.userSubject2.next(this._user);
    // this.menu = Menu.buildMenuFromUserObject(this.user, this);

    this.translation.preferredLanguage = Helper.getFirstDefinedString(user.PreferredLanguage, "en");

    (this.error as GlobalErrorHandler).setUser(user);
    this.analytics.setUser(user);

    // Reload app information since a user change may have just caused a partition change.
    this.tryGetAppInfo(true).pipe(takeUntil(this.ngUnsubscribe)).subscribe(info => {

      if (this.canRouteToOrgWizard() || this.canRouteToUserWizard()) {

        // Subscribe to router events BEFORE we begin routing. TryGetAppInfo could have routed to org wizard
        // already, but we subscribe here, and then just route again below if needed. That way this is in place
        // and can be used to signal when it's done navigating. But the routing is still in tryGetAppInfo as well
        // since it's called from other places.
        this.router.events.pipe(
          takeUntil(this.ngUnsubscribe),
          filter((event: Event | RouterEvent): event is RouterEvent => event instanceof RouterEvent))
          .subscribe((event: RouterEvent) => {
            // console.log('1234 router event: ', event);
            if (event instanceof NavigationEnd) {
              // Add specific checks just in case whoever observes this method needs to make sure it really is
              // done, instead of just being okay with seeing that it hit the org quick start even though
              // it might still be routing to user quick start after.
              if (this.canRouteToOrgWizard() && !this.canRouteToUserWizard()) {
                if (Helper.contains(event.url, "rc/config/quick-start")) {
                  subject.next(true);
                  subject.complete();
                }
              } else if (this.canRouteToUserWizard()) {
                if (Helper.contains(event.url, "profile/quick-start")) {
                  subject.next(true);
                  subject.complete();
                }
                if (Helper.contains(event.url, "rc/config/quick-start/new-user")) {
                  subject.next(true);
                  subject.complete();
                }
              }
            }
          });

        // Now do the routing, since we are observing for these router events already.
        if (this.canRouteToOrgWizard() && !this.canRouteToUserWizard()) {
          this.redirectToOrgQuickStart();
        } else if (this.canRouteToUserWizard()) {
          this.redirectToUserQuickStart();
        } else {
          // This should not get called but if for some odd reason we're not going to redirect here
          // we need to complete the subject because we won't have a navigation event to listen to.
          subject.next(true);
          subject.complete();
        }

      } else {
        subject.next(true);
        subject.complete();
      }
    });

    // Reload task status
    this.refreshTaskStatus();

    // Reload user preferences
    this.preferences.clearCache();
    // Eager load a few user preferences that we want handy.  We don't need to subscribe to the
    // observable here we just want to get them cached up so they're ready when we need them later.
    // app settings comes down with user object so no loading needed ... this.preferenceObjectGet(Constants.ContactPreference.ApplicationSettings);
    this.preferenceObjectGet(Constants.ContactPreference.HelpDisableAutoOpen);

    if (this._user?.Settings) {
      this.preferenceObjectAddPreLoadedToCache(Constants.ContactPreference.ApplicationSettings, this._user.Settings);
    }

    // TODO: add HelpDisableAutoOpen data, but it's on HelpService so no access?

    // Now call any user set callbacks that are registered with us so they can know about this change.
    if (this._userSetCallbacks) {
      this._userSetCallbacks.forEach((callback: UserSetCallback) => {
        if (callback) {
          try {
            callback.userSet(user);
          } catch (ex) {
            Log.errorMessage(ex);
          }
        }
      });
    }

    return subject.asObservable();

  }

  /**
   * This will make sure the user object that is in storage is in sync with the user object in the app service.
   * @param rememberMe if true the user object will be saved to local storage, if false it will be saved to session storage.
   * If undefined, we will look to see if rememberMe is already defined in storage and use that.
   * @param saveToken
   */
  syncStorageWithUser(rememberMe: boolean, saveToken: boolean = false) {

    if (!this._user) {
      return;
    }

    if (rememberMe === undefined) {
      rememberMe = (window.localStorage[Constants.LocalStorage.UserObject] ? true : false);
    }

    if (rememberMe) {
      // Remember me was checked so put this in local storage
      Helper.localStorageSaveObject(Constants.LocalStorage.UserObject, this._user);

      if (saveToken) {
        Helper.localStorageSave(Constants.LocalStorage.AuthenticationToken + "-" + AppConfig.apiUrl, this._user.Token);
      }

      // Delete the opposite storage
      Helper.sessionStorageDelete(Constants.LocalStorage.UserObject);
    } else {
      // Remember was NOT checked so put this in session storage
      Helper.sessionStorageSaveObject(Constants.LocalStorage.UserObject, this._user);

      if (saveToken) {
        Helper.sessionStorageSave(Constants.LocalStorage.AuthenticationToken + "-" + AppConfig.apiUrl, this._user.Token);
      }

      // Delete the opposite storage
      Helper.localStorageDelete(Constants.LocalStorage.UserObject);
    }
  }




  protected canRouteToUserWizard() {
    if (this.isLoggedIn() && this.user?.Settings?.ApplicationSettings &&
      !this.user?.Settings?.ApplicationSettings?.QuickStartCompletedDateTime) {
      return true;
    } else {
      return false;
    }
  }

  protected canRouteToOrgWizard() {
    if (this.isLoggedIn() && this._appInfo?.Settings && !this._appInfo.Settings.QuickStartCompletedDateTime) {
      // TODO once we have  quick start wizards for other brands we need to include this for those brands
      if (this.isBrandReportCompiler) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  public userReload(): void {
    const prop = ApiModuleSecurity.SecurityAuthenticate(AppConfig.apiVersion);
    const call = ApiHelper.createApiCall(prop, ApiOperationType.Get);
    this.apiService.execute(call, {}).subscribe((response: IApiResponseWrapperTyped<m5sec.AuthenticatedUserViewModel>) => {
      if (response.Data.Success) {
        const rememberMe: boolean = (window.localStorage[Constants.LocalStorage.UserObject] ? true : false);
        this.userSet(response.Data.Data, rememberMe);
      }
    });
  }


  /**
   * An array of callbacks that implement a userSet method to be called when
   * the user is set in this service.
   */
  private _userSetCallbacks: UserSetCallback[] = [];

  /**
   * Register an object that implements the UserSetCallback interface so it can
   * be called when a user is set in this service.
   * @param callback
   */
  public userSetCallbackRegister(callback: UserSetCallback): void {
    if (callback) {
      if (!this._userSetCallbacks.includes(callback)) {
        this._userSetCallbacks.push(callback);
      }
    }
  }

  /**
   * Unregister an object that implements the UserSetCallback interface so it will
   * no longer be called when a user is set in this service.
   * @param callback
   */
  public userSetCallbackUnregister(callback: UserSetCallback): void {
    if (callback) {
      if (this._userSetCallbacks.includes(callback)) {
        const index = this._userSetCallbacks.findIndex(x => x === callback);
        if (index > -1) {
          this._userSetCallbacks.splice(index, 1);
        }
      }
    }
  }


  /*
   * If user object is updated this will refresh that object in our local storage so changes
   * are persisted there.
   */
  public userUpdateExternalServicesAndSaveToLocalStorage(): void {

    // User may have changed preferences that impact error tracking and analytics
    (this.error as GlobalErrorHandler).setUser(this.user);
    this.analytics.setUser(this.user);

    // Update in local storage
    const rememberMe: boolean = (window.localStorage[Constants.LocalStorage.UserObject] ? true : false);
    if (rememberMe) {
      Helper.localStorageSaveObject(Constants.LocalStorage.UserObject, this.user);
    } else {
      Helper.sessionStorageSaveObject(Constants.LocalStorage.UserObject, this.user);
    }

  }


  getLimitedUserMessage(firstPerson: boolean): { title: string, message: string } {
    const title: string = "Limited Access User Account";
    let message: string = "This account is a limited access user account and you do not have access to this feature.  If the account should have access please contact your system administrator to request the access be changed.";
    if (firstPerson) {
      message = "Your account is a limited access user account and you do not have access to this feature.  If you need access please contact your system administrator to request your access be changed.";
    }
    return { title: title, message: message };
  }

  getTerminatedUserMessage(firstPerson: boolean): { title: string, message: string } {
    const title: string = "Terminated User Account";
    let message: string = "This account has been terminated.  If the account should not have been terminated please contact your system administrator to request the termination be reversed.";
    if (firstPerson) {
      message = "Your account has been terminated.  If you believe this was done in error please contact your system administrator to request the account termination be reversed.";
    }
    return { title: title, message: message };
  }


  preferenceObjectGet(preferences: string): Observable<any> {
    return this.preferences.preferenceObjectGet(this.userOrDefault.ContactId, preferences);
  }
  preferenceObjectSet(preferences: string, value: any): void {
    this.preferences.preferenceObjectSet(this.userOrDefault.ContactId, preferences, value);
    return;
  }
  preferenceValueSet(preferences: string, setting: string, value: any): void {
    this.preferences.preferenceValueSet(this.userOrDefault.ContactId, preferences, setting, value);
    return;
  }

  preferenceObjectAddPreLoadedToCache(preferences: string, value: any) {
    this.preferences.preferenceObjectAddPreLoadedToCache(preferences, value);
    return;
  }


  public bookmarkPage() {
    const apiProperties: ApiProperties = ApiModuleWeb.DashboardBookmark(AppConfig.apiVersion);
    const api = ApiHelper.createApiCall(apiProperties, ApiOperationType.Add);
    const model = new m5web.DashboardWidgetPropertyBookmarkEditViewModel();
    (model as any).ContactId = this._user.ContactId;
    model.Title = this.title;
    model.Url = this.location.path();
    this.apiService.add(api, model).subscribe((response: IApiResponseWrapper) => {
      if (response.Data.Success) {
        this.alertManager.addAlertMessage(AlertItemType.Success, "Your bookmark has been added.", 2000);
      } else if (response.Data.ResultCode === 1711) { // RequestedActionAlreadyCompleted
        this.alertManager.addAlertMessage(AlertItemType.Warning, "This page is already in your bookmarks.", 2000);
      } else {
        this.alertManager.addAlertMessage(AlertItemType.Danger, "Error adding bookmark: " + response.Data.Message, 0);
      }
    });
  }


  public getCopyrightHtml(): string {

    // See if we have this message cached
    if (this.copyrightHtml) {
      return this.copyrightHtml;
    }

    if (!this.appInfoOrDefault || !this.appInfoOrDefault.Branding) {
      // Better than nothing
      if (!this.loggedWarning.getCopyrightHtml) {
        Log.warningMessage("No app info branding object so using copyright message from site config file.");
        this.loggedWarning.getCopyrightHtml = true;
      }
      return AppConfig.copyright;
    }

    // See if message is disabled
    if (!this.appInfoOrDefault.Branding.ShowCopyrightMessage) {
      return "";
    }

    if (!this.appInfoOrDefault.Branding.CopyrightCompany) {
      // Better than nothing
      if (!this.loggedWarning.getCopyrightHtml) {
        Log.warningMessage("Show copyright message is true but no copyright company in branding object so using copyright message from site config file.");
        this.loggedWarning.getCopyrightHtml = true;
      }
      return AppConfig.copyright;
    }

    // Build our message
    this.copyrightHtml = `&#0169; Copyright ${this._appInfo.Branding.CopyrightYears} `;
    if (this._appInfo.Branding.CopyrightCompanyUrl) {
      this.copyrightHtml += `<a href="${this._appInfo.Branding.CopyrightCompanyUrl}" target="_blank" class="footer-link">${this._appInfo.Branding.CopyrightCompany}</a>`;
    } else {
      this.copyrightHtml += this._appInfo.Branding.CopyrightCompany;
    }
    if (this._appInfo.Branding.CopyrightIncludeLicensors && this._appInfo.Branding.CopyrightIncludeLicensorsMessage) {
      this.copyrightHtml += ` ${this._appInfo.Branding.CopyrightIncludeLicensorsMessage}`;
    } else if (this._appInfo.Branding.CopyrightIncludeLicensors) {
      this.copyrightHtml += ` and/or its licensors`;
    }

    return this.copyrightHtml;

  }

  public getPatentHtml(): string {

    // See if we have this message cached
    if (this.patentHtml) {
      return this.patentHtml;
    }

    if (!this.appInfoOrDefault || !this.appInfoOrDefault.Branding) {
      // Better than nothing
      if (!this.loggedWarning.getPatentHtml) {
        Log.warningMessage("No app info branding object so using patent message from site config file.");
        this.loggedWarning.getPatentHtml = true;
      }
      return AppConfig.patent;
    }

    // See if message is disabled
    if (!this.appInfoOrDefault.Branding.PatentText) {
      return AppConfig.patent;
    }

    this.patentHtml = this.appInfoOrDefault.Branding.PatentText;
    return this.patentHtml;

  }

  public getPoweredByHtml(): string {

    // See if we have this message cached
    if (this.poweredByHtml) {
      return this.poweredByHtml;
    }

    if (!this.appInfoOrDefault || !this.appInfoOrDefault.Branding) {
      // Better than nothing
      if (!this.loggedWarning.getPoweredByHtml) {
        Log.warningMessage("No app info branding object so using powered by message from site config file.");
        this.loggedWarning.getPoweredByHtml = true;
      }
      return AppConfig.poweredBy;
    }

    // See if message is disabled
    if (!this.appInfoOrDefault.Branding.ShowPoweredByMessage) {
      return "";
    }

    if (!this.appInfoOrDefault.Branding.PoweredByName) {
      // Better than nothing
      if (!this.loggedWarning.getPoweredByHtml) {
        Log.warningMessage("Show powered by message is true but no powered by name in branding object so using powered by message from site config file.");
        this.loggedWarning.getPoweredByHtml = true;
      }
      return AppConfig.poweredBy;
    }

    // Build our message
    if (this._appInfo.Branding.PoweredByUrl) {
      this.poweredByHtml = `Powered by <a href="${this._appInfo.Branding.PoweredByUrl}" target="_blank" class="footer-link">${this._appInfo.Branding.PoweredByName}`;
      if (this._appInfo.Branding.PoweredByTrademark === "(R)") {
        this.poweredByHtml += "&#0174;";
      } else if (this._appInfo.Branding.PoweredByTrademark === "TM") {
        this.poweredByHtml += "&#0153;";
      }
      this.poweredByHtml += `</a>`;
    } else {
      this.poweredByHtml = `Powered by ${this._appInfo.Branding.PoweredByName}`;
      if (this._appInfo.Branding.PoweredByTrademark === "(R)") {
        this.poweredByHtml += "&#0174;";
      } else if (this._appInfo.Branding.PoweredByTrademark === "TM") {
        this.poweredByHtml += "&#0153;";
      }
    }

    return this.poweredByHtml;

  }

  public getLogoUrl(): string {

    // See if we have this cached
    if (this.logoUrl) {
      return this.logoUrl;
    }

    if (!this._appInfo) {
      // Better than nothing
      return AppConfig.logoUrl;
    }

    // Make be we have a hard coded url
    if (this._appInfo.LogoUrl) {
      return this._appInfo.LogoUrl;
    }

    // Make sure we have at least 1 logo
    if (!this._appInfo.Logos || this._appInfo.Logos.length === 0) {
      // The best we can do for now
      return AppConfig.logoUrl;
    }

    // TODO either move this to core or other change so we don't load Api & all related models on app start as this service triggers
    /// / TODO pick the logo that matches our best image sizes and format
    // let logo = this._appInfo.Logos[0];
    // let properties: ApiProperties = Api.AssetActionView(AppConfig.apiVersion);
    // let call: ApiCall = ApiHelper.createApiCall(properties, ApiOperationType.Get);
    // this.logoUrl = ApiHelper.buildApiAbsoluteUrl(call, { AssetId: logo.AssetId, FriendlyName: logo.FriendlyName });
    // if (!call.token && call.partnerToken) {
    //  this.logoUrl = ApiHelper.addQueryStringToUrl(this.logoUrl, `peat=${call.partnerToken}&silent=true`);
    // } else {
    //  this.logoUrl = ApiHelper.addQueryStringToUrl(this.logoUrl, `token=${call.token}&silent=true`);
    // }

    return this.logoUrl;

  }


  protected loadedExternalResources: string[] = [];

  /**
   * Load an external script.
   * @example this.loadExternalScript('url/to/your/scripts').then(() => {}).catch(() => {});
   * @param scriptUrl
   * @param force
   */
  public loadExternalScript(scriptUrl: string, force: boolean = false) {
    if (!force && this.loadedExternalResources.includes(scriptUrl)) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      this.loadedExternalResources.push(scriptUrl);
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }


  /**
   * Load an external css file.
   * @example this.loadExternalStyles('url/to/your/styles').then(() => {}).catch(() => {});
   * @param styleUrl
   * @param force
   */
  public loadExternalStyles(styleUrl: string, force: boolean = false) {
    if (!force && this.loadedExternalResources.includes(styleUrl)) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      this.loadedExternalResources.push(styleUrl);
      // <link rel="stylesheet" type="text/css" href="./assets/custom.css">
      const styleElement = document.createElement('link');
      styleElement.href = styleUrl;
      styleElement.type = "text/css";
      styleElement.rel = "stylesheet";
      styleElement.onload = resolve;
      document.head.appendChild(styleElement);
    });
  }



  labelMacroSubstitution(text: string): string {

    if (!text) {
      return "";
    }
    if (!Helper.contains(text, "{{") || !Helper.contains(text, "}}")) {
      return text;
    }

    // Copy of original in case we need it
    const original: string = text;

    // We only do macro replacement if we have a settings object which holds our labels
    if (this.settings) {
      try {
        // Replace {{macros}} with values from Labels object
        text = Helper.stringFormat(text, this.settings.Labels);
      } catch (err) {
        Log.errorMessage(err);
        text = original;
      }
    }

    if (Helper.contains(text, "{{")) {
      // We have a macro that we have not handled so we're doing the best we can
      Log.errorMessage(`Either no labels available or a macro found that did not have a label: "${text}".`);
      if (Helper.contains(text, "Plural}}")) {
        text = Helper.replaceAll(text, "Plural}}", "s");
      }
      text = Helper.replaceAll(text, "{{", "");
      text = Helper.replaceAll(text, "}}", "");
    }

    return text;

  }



  /**
   * Helper method to get desired label.  This allows customization of things like
   * "Case", "ExternalCaseId", etc. to be more desirable text for user scenario
   * where "Case" might be referred to as "Issue", "File", etc.
   * @param label
   * @param defaultValue
   * @param translate
   */
  getLabel(label: string, defaultValue: string = "", translate: boolean = true) {

    // label examples: "Group", "GroupPlural", "ExternalGroupId", etc.
    if (!defaultValue) {
      defaultValue = label;
    }
    let value = defaultValue;
    let found = false;

    try {
      if (this.settings && this.settings.Labels) {
        value = this.settings.Labels[label];
        found = true;
      }
    } catch (err) {
      Log.errorMessage(err);
    }

    if (!value) {
      value = defaultValue;
      found = false;
    }

    // If things aren't working out for us try to help by doing things like
    // ReportCompilerReportAppendix => Report Appendix
    // GroupPlural => Groups
    // ExternalCaseNumber => External Case Number
    if (!found) {
      if (Helper.startsWith(value, "ReportCompiler", true)) {
        value = value.replace("ReportCompiler", "");
      }
      if (Helper.endsWith(value, "Plural", true)) {
        value = Helper.plural(value.replace("Plural", ""), 2);
      }
      value = Helper.formatIdentifierWithSpaces(value);
    }

    if (translate) {
      try {
        const translated = this.translation.getTranslation(value);
        if (translated) {
          return translated;
        }
      } catch (err) {
        Log.errorMessage(err);
      }
    }

    return value;

  }


  protected configRouteChangeLogging(): void {

    // We only want to listen for the events below if we are actually in debug mode or
    // if we have page analytics turned on.
    if (!AppConfig.debug && !this.analytics.isGoogleAnalyticsAvailable()) {
      return;
    }

    this.routeEventSubscription = this.router.events.subscribe((value: Event | RouterEvent) => {
      // console.error("Router Event");
      // console.error(value);
      if (value instanceof NavigationStart) {
        // TMI ? Log.debug("url", "URL", `Navigation start event with url of "${value.url}"`);
      } else if (value instanceof NavigationEnd) {
        if (AppConfig.debug) {
          Log.debug("url", "URL", `Navigation end event with url of "${value.url}" and url after redirects of "${value.urlAfterRedirects}"`);
        }
        // Post the page url to analytics
        this.analytics.setPath(value.urlAfterRedirects);
      } else if (value instanceof ActivationStart) {
        if (AppConfig.debug) {
          Log.debug("url", "URL", `Route activation start event for outlet "${value.snapshot.outlet}" with url of "${JSON.stringify(value.snapshot.url)}"`);
        }
      }
    });

  }

  /*
   * The most recently received help link object.  This is updated by components when they
   * call helpLinkConfigure() and pass in a context string and this is accessed by the nav header
   * component to show context sensitive menu items.
   */
  public helpLink: m5web.HelpLinkEditViewModel = null;
  protected helpLinkSubject = new BehaviorSubject<m5web.HelpLinkEditViewModel>(null);
  public helpLinkMonitor() { return this.helpLinkSubject.asObservable(); }
  protected helpLinkApiProp: ApiProperties = null;
  protected helpLinkApiCall: ApiCall = null;

  /**
   * This method finds a help link object for the specified context and tag.  The method helps
   * validate and format the object including building help link.  It's called by helpLinkConfigure
   * but also by places like modals when the modal config has a help context specified so we
   * keep this method separate from helpLinkConfigure.
   * @param context The help link context.
   * @param tag An optional help link tag.
   * @returns An observable which will resolve to either a m5web.HelpLinkEditViewModel
   * object or null if no help link is defined the the context and tag.
   */
  helpLinkFind(context: string | HelpContext, tag: string = ""): Observable<m5web.HelpLinkEditViewModel> {

    if (!this.helpLinkApiCall) {
      this.helpLinkApiProp = ApiModuleWeb.HelpLinkFind();
      this.helpLinkApiCall = ApiHelper.createApiCall(this.helpLinkApiProp, ApiOperationType.Get);
      this.helpLinkApiCall.silent = true;
      this.helpLinkApiCall.cacheUseStorage = true;
    }

    const brand: string = m.BrandId[this.appInfoOrDefault.Branding.BrandId]; // enum as string
    let appVersion: string = "";
    if (this.status.currentState && this.status.currentState.versionRunning) {
      appVersion = this.status.currentState.versionRunning;
    }
    const language: string = this.translation.currentLanguage;

    // If our context is an enum then convert that to a string
    if (!Helper.isString(context)) {
      const helpString: string = HelpContext[context]; // enum as string
      if (helpString) {
        context = helpString;
      }
    }

    // Help QA know what help context is requested for a given component
    Log.debug("ui", "Help", `Help Context: ${context}`);

    // We want to keep a cache of help link contexts that returned not found error.
    // We do this so we don't repeatedly attempt to load contexts that don't exist
    // while at the same time not being shy about retrying when the cache expires
    // or when the site is reloaded (since this is not stored cache).
    const cacheKey = `${context}-${brand}-${appVersion}-${tag}`;
    this.helpLinkApiCall.cacheKey = cacheKey;
    if (this.cache.cacheKeyExists("HelpLinkContextNotFound", cacheKey)) {
      return of(null);
    }

    const subject = new AsyncSubject<m5web.HelpLinkEditViewModel>();

    this.apiService.execute(this.helpLinkApiCall, { context: context, brand: brand, appVersion: appVersion, tag: tag, language: language })
      .subscribe((result: IApiResponseWrapperTyped<m5web.HelpLinkEditViewModel>) => {
        if (result.Data.Success && result.Data.Data) {
          const help = result.Data.Data;
          // Build out url for any id based help links
          if (help.Links && help.Links.length > 0) {
            help.Links.forEach(link => {
              // Some description is mandatory for menu text
              if (!link.Description) {
                link.Description = "Help";
              }
              if (!link.Icon) {
                // Default icon
                link.Icon = "question (light)";
              }
              // Convert article id into a link
              if (link.Id && this._appInfo?.Branding?.SupportUrlTopicTemplate && (Helper.equals(link.HelpLinkItemType, "Article", true) || !link.Url)) {
                // If we have a help topic id then build our url from our support topic template
                link.Url = this._appInfo.Branding.SupportUrlTopicTemplate.replace("{id}", link.Id).replace("{description}", Helper.encodeURISlug(link.Description));
                link.Url = this.helpLinkAppendSupportToken(link.Url);
              }
              // See if message text contains article id values that need to be converted to a url
              if (Helper.equals(link.HelpLinkItemType, "Message", true) && this._appInfo?.Branding?.SupportUrlTopicTemplate && link.Message && Helper.contains(link.Message.Text, "[ArticleId", false)) {
                while (Helper.contains(link.Message.Text, "[ArticleId", false)) {
                  const startIndex = link.Message.Text.indexOf("[ArticleId");
                  if (startIndex === -1) {
                    console.error(`Unable to find "[ArticleId" in help message text "${link.Message.Text}".`);
                    break;
                  }
                  const endIndex = link.Message.Text.indexOf("]", startIndex);
                  if (endIndex === -1) {
                    console.error(`Unable to find "]" after position ${startIndex} in help message text "${link.Message.Text}".`);
                    break;
                  }
                  const reference = link.Message.Text.substring(startIndex, endIndex);
                  const id = reference.replace("[ArticleId", "").replace("]", "");
                  let url = this._appInfo.Branding.SupportUrlTopicTemplate.replace("{id}", id).replace("{description}", Helper.encodeURISlug(link.Description));
                  url = this.helpLinkAppendSupportToken(url);
                  link.Message.Text = Helper.replaceAll(link.Message.Text, reference, url);
                }
              }
            });
          }
          subject.next(help);
          subject.complete();
        } else {
          this.cache.cachePutValue("HelpLinkContextNotFound", cacheKey, "404", CacheLevel.PseudoStatic);
          subject.next(null);
          subject.complete();
        }
      });

    return subject.asObservable();

  }


  /**
   * This method does help link configuration for requested context and tag.  If a help link
   * object is available for the requested context and tag the helpLink object will be updated
   * and the helpLinkMonitor observable will push the help link object to observers.
   * @param context The help link context.
   * @param tag An optional help link tag.
   */
  helpLinkConfigure(context?: string | HelpContext, tag: string = ""): void {
    if (!context) {
      this.helpLink = null;
      this.helpLinkSubject.next(this.helpLink);
      return;
    }
    this.helpLinkFind(context, tag)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        // Note that result may be null if there was no help available for the
        // specified context but we want that null to be passed through otherwise
        // we're still showing help items for the previous context.
        this.helpLink = result;
        this.helpLinkSubject.next(this.helpLink);
      });
  }


  helpLinkAppendSupportToken(url: string): string {
    if (!url) {
      return "";
    }
    if (this._appInfo.Branding.SupportUrlAuthenticationQueryStringParameter) {
      if (this.userOrDefault.PartnerTokens && this.userOrDefault.PartnerTokens.Support) {
        url += `?${this._appInfo.Branding.SupportUrlAuthenticationQueryStringParameter}=${this.userOrDefault.PartnerTokens.Support}`;
      }
    }
    return url;
  }

  systemSettingsLoad(cacheIgnore: boolean = false, silent: boolean = true, reportErrors: boolean = true): Observable<m5.SystemSettings> {

    if (this.systemSettings) {
      return of(this.systemSettings);
    }

    const apiProp: ApiProperties = Api.SystemSettings();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
    apiCall.silent = silent;
    apiCall.cacheUseStorage = true;
    apiCall.cacheIgnoreOnRead = cacheIgnore;

    const subject = new AsyncSubject<m5.SystemSettings>();

    this.apiService.execute(apiCall, {}).subscribe((result: IApiResponseWrapperTyped<m5.SystemSettings>) => {
      if (result.Data.Success && result.Data.Data) {
        this.systemSettings = result.Data.Data;
        subject.next(this.systemSettings);
        subject.complete();
      } else {
        if (reportErrors) {
          this.alertManager.addAlertFromApiResponse(result, apiCall);
        }
        subject.next(null);
        subject.complete();
      }
    });

    return subject.asObservable();

  }

  systemSettingsSave(cacheIgnore: boolean = false, reportErrors: boolean = true) {
    const apiProp: ApiProperties = Api.SystemSettings();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Edit);
    apiCall.silent = true;
    apiCall.cacheUseStorage = true;
    apiCall.cacheIgnoreOnRead = cacheIgnore;
    this.apiService.execute(apiCall, this.systemSettings).subscribe((result: IApiResponseWrapperTyped<m5.SystemSettings>) => {
      if (result.Data.Success) {
        // this.data = result.Data.Data;
      } else {
        if (reportErrors) {
          this.alertManager.addAlertFromApiResponse(result, apiCall);
        }
      }
    });
  }

  systemSettingsSaveOne(setting: m5.SettingEditViewModel, reportErrors: boolean = true) {
    const apiProp: ApiProperties = Api.SystemSettingOne();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Edit);
    apiCall.silent = true;
    this.apiService.execute(apiCall, setting).subscribe((result: IApiResponseWrapperTyped<m5.SystemSettings>) => {
      if (result.Data.Success) {
        // this.data = result.Data.Data;
      } else {
        if (reportErrors) {
          this.alertManager.addAlertFromApiResponse(result, apiCall);
        }
      }
    });
  }

  systemSettingsTryGetOne(category: string, attribute: string, defaultValue: string): Observable<m5.SettingEditViewModel> {

    if (this.systemSettings) {
      return of(this.systemSettingsGetOne(category, attribute, defaultValue));
    }

    const subject = new AsyncSubject<m5.SettingEditViewModel>();

    this.systemSettingsLoad().subscribe((settings: m5.SystemSettings) => {
      const setting = this.systemSettingsGetOne(category, attribute, defaultValue);
      subject.next(setting);
      subject.complete();
    });

    return subject.asObservable();

  }

  systemSettingsGetOne(category: string, attribute: string, defaultValue: string): m5.SettingEditViewModel {

    // console.error(category, attribute, defaultValue);

    if (!this.systemSettings) {
      // console.error("no system settings");
      const systemSettings = new m5.SettingEditViewModel();
      systemSettings.Category = category;
      systemSettings.Attribute = attribute;
      systemSettings.Value = defaultValue;
      return systemSettings;
    }

    // Scan groups, categories, and settings for the object we want
    let setting: m5.SettingEditViewModel = null;
    this.systemSettings.Groups.forEach(group => {
      if (group.Categories) {
        const cat = Helper.firstOrDefault(group.Categories, x => Helper.equals(x.Category, category, true));
        if (cat && cat.Settings) {
          // console.error(cat.Settings);
          const item = Helper.firstOrDefault(cat.Settings, x => Helper.equals(x.Attribute, attribute, true));
          if (item) {
            // console.error(item);
            setting = item.Setting;
          }
        }
      }
    });
    if (setting) {
      return setting;
    }

    // If our scan didn't find a match then return the default values
    const model = new m5.SettingEditViewModel();
    model.Category = category;
    model.Attribute = attribute;
    model.Value = defaultValue;
    return model;

  }

  systemSettingsUpdateValue(category: string, attribute: string, value: string, save: boolean = true): void {
    // Scan groups, categories, and settings for the object we want
    this.systemSettings.Groups.forEach(group => {
      if (group.Categories) {
        const cat = Helper.firstOrDefault(group.Categories, x => Helper.equals(x.Category, category, true));
        if (cat && cat.Settings) {
          const item = Helper.firstOrDefault(cat.Settings, x => Helper.equals(x.Attribute, attribute, true));
          if (item) {
            item.Value = value;
            item.Setting.Value = value;
            if (save) {
              this.systemSettingsSaveOne(item.Setting);
            }
          }
        }
      }
    });
  }





  getPickListText(value: any, pickListId: string): Observable<string> {

    // console.error("get pick list text", value, pickListId);

    if (!value) {
      return of("");
    }
    if (!pickListId) {
      return of(value);
    }

    const subject = new AsyncSubject<string>();

    this.apiService.loadPickList(pickListId).subscribe(result => {
      if (result.Data.Success) {
        const match = Helper.firstOrDefault(result.Data.Data, x => Helper.equals(x.Value, value, true));
        if (match) {
          // console.error("found match", match);
          subject.next(match.DisplayText || value);
          subject.complete();
        } else {
          // console.error("no match", value , result.Data.Data);
          subject.next(value);
          subject.complete();
        }
      } else {
        subject.next(value);
        subject.complete();
      }
    });

    return subject.asObservable();

  }



  public getActionLinkForResource(resourceType: string, resourceId: number, resourceId2: string, contactId: number,
    title: string, authenticationRequired: boolean = false, properties: any = null):
    Observable<{ id: string, link: string, model: m5.ActionLinkEditViewModel }> {

    const apiProp: ApiProperties = Api.ActionLink();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Add);

    // Data for our action link
    const data = new m5.ActionLinkEditViewModel();
    data.Role = "?";
    data.AuthenticationRequired = authenticationRequired;
    data.RegardingResourceType = resourceType;
    data.RegardingResourceId = resourceId;
    data.RegardingResourceId2 = resourceId2;
    data.ContactId = contactId;
    data.Value001 = title;
    if (properties) {
      data.Properties = JSON.stringify(properties);
    }

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ id: string, link: string, model: m5.ActionLinkEditViewModel }>();

    this.apiService.execute(apiCall, data).subscribe((result: IApiResponseWrapperTyped<m5.ActionLinkEditViewModel>) => {
      if (result.Data.Success) {
        const id: string = result.Data.Data.ActionLinkId;
        const link: string = `${window.location.protocol}//${window.location.host}/link/${id}`;
        subject.next({ id: id, link: link, model: result.Data.Data });
        subject.complete();
      } else {
        this.alertManager.addAlertFromApiResponse(result, apiCall);
        subject.next({ id: null, link: null, model: null });
        subject.complete();
      }
    });

    return subject.asObservable();

  }


  private _optInFeatures: OptInFeatures = null;
  public get optInFeatures(): OptInFeatures {
    if (!this._optInFeatures) {
      this.optInFeaturesLoad();
    }
    return this._optInFeatures;
  }
  optInFeaturesLoad() {
    this._optInFeatures = Helper.localStorageGetObject<OptInFeatures>("OptInFeatures", {});
  }
  optInFeaturesSave() {
    Helper.localStorageSaveObject("OptInFeatures", this.optInFeatures || {});
  }

}


/**
 * The UserSetCallback interface can be implemented by services or other classes that need to know
 * when AppService.userSet gets called so they can trigger their own actions like loading data,
 * etc.  They call AppService.userSetCallbackRegister and AppService.userSetCallbackUnregister
 * to register and unregister themselves from the callback mechanism.
 */
export interface UserSetCallback {
  userSet: (user: m5sec.AuthenticatedUserViewModel) => void;
}
