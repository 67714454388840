// import * as TinyMce from 'tinymce';

export function HtmlEditorDefaultSettings(): any { // TinyMce.Settings | any {

  return {

    baseURL: 'assets/tinymce',
    tinymceScriptURL: 'assets/tinymce/tinymce.min.js',
    theme_url: '/assets/tinymce/themes/modern/theme.min.js',
    skin_url: '/assets/tinymce/skins/lightgray',
    relative_urls: false,
    convert_urls: false,
    // remove_script_host: false,

    branding: false,

    automatic_uploads: true,
    paste_data_images: true,
    // imagetools_cors_hosts: [AppConfig.ApiUrl.replace("https://", "").replace("http://", "")],
    // images_upload_handler: function (blobInfo, success, failure) {
    //  var processResponse = (response) => {
    //    if (!response.Data.Success) {
    //      self.appService.alertManager.addAlertMessage("danger", response.Data.Message, 0);
    //      // failure callback with our error message
    //      failure(response.Data.Message);
    //      return;
    //    } else {
    //      // BuildFileViewUrl = (assetId: number, friendlyName: string, incrementHitCounter: boolean = true, includeAccessToken: boolean = true, returnAsTrustedResourceUrl: boolean = true): string => {
    //      var url: string = self.Helper.BuildFileViewUrl(response.Data.Data.AssetId, response.Data.Data.FriendlyName, false, false, false);
    //      //console.error(url);
    //      // success callback with our url
    //      success(url);
    //      return;
    //    }
    //  };
    //  var info = blobInfo.blob();
    //  //console.error(info);
    //  var promise: ng.IPromise<m5.IResponse> = self.tinymceFileUpload(info.name, info.type, info.size, blobInfo.base64());
    //  if (promise) {
    //    promise.then(processResponse);
    //  }
    // },
    inline: false,
    plugins: "advlist autolink link image media lists charmap print paste preview hr searchreplace table visualblocks visualchars wordcount autoresize textcolor colorpicker contextmenu",
    contextmenu: "link image inserttable | cell row column deletetable",
    imagetools_toolbar: "rotateleft rotateright | flipv fliph | editimage imageoptions",
    autoresize_bottom_margin: 50,
    autoresize_max_height: 500,
    autoresize_min_height: 250,
    height: 250,
    statusbar: true,
    browser_spellcheck: true,
    valid_elements: "*[*]",

    // we get {{macros}} that are directives wrapped in <p> tags which impacts output formatting.
    // BTW ... we tried the below and it didn't solve the problem. :(
    // See https://stackoverflow.com/a/13842115 for info on how to avoid it with the below settings:
    force_br_newlines: false,
    force_p_newlines: false,
    forced_root_block: '',
    // force_p_newlines: false,
    // force_br_newlines: true,
    remove_linebreaks: false,
    apply_source_formatting: false,
    // apply_source_formatting: true,

    // content_css: [`/css/${self.appService.appInfo.Theme}/libs/bootstrap/bootstrap.min.css`, `/css/${self.appService.appInfo.Theme}/ib.css?ver=201804061605`],
    // body_class: "tiny-mce-body",
    skin: "lightgray",
    theme: "modern"

  };

  /*
  this.tinymceOptions = {
                automatic_uploads: true,
                paste_data_images: true,
                imagetools_cors_hosts: [AppConfig.ApiUrl.replace("https://", "").replace("http://", "")],
                images_upload_handler: function (blobInfo, success, failure) {
                    var processResponse = (response) => {
                        if (!response.Data.Success) {
                            self.appService.alertManager.addAlertMessage("danger", response.Data.Message, 0);
                            // failure callback with our error message
                            failure(response.Data.Message);
                            return;
                        } else {
                            // BuildFileViewUrl = (assetId: number, friendlyName: string, incrementHitCounter: boolean = true, includeAccessToken: boolean = true, returnAsTrustedResourceUrl: boolean = true): string => {
                            var url: string = self.Helper.BuildFileViewUrl(response.Data.Data.AssetId, response.Data.Data.FriendlyName, false, false, false);
                            //console.error(url);
                            // success callback with our url
                            success(url);
                            return;
                        }
                    };
                    var info = blobInfo.blob();
                    //console.error(info);
                    var promise: ng.IPromise<m5.IResponse> = self.tinymceFileUpload(info.name, info.type, info.size, blobInfo.base64());
                    if (promise) {
                        promise.then(processResponse);
                    }
                },
                inline: false,
                plugins: "advlist autolink link image media lists charmap print paste preview hr searchreplace table visualblocks visualchars wordcount autoresize textcolor colorpicker contextmenu",
                contextmenu: "link image inserttable | cell row column deletetable",
                imagetools_toolbar: "rotateleft rotateright | flipv fliph | editimage imageoptions",
                autoresize_bottom_margin: 50,
                autoresize_max_height: 500,
                autoresize_min_height: 250,
                height: 250,
                statusbar: true,
                browser_spellcheck: true,
                valid_elements: "*[*]",
                force_p_newlines: true,
                force_br_newlines: true,
                remove_linebreaks: false,
                apply_source_formatting: true,
                content_css: [`/css/${self.appService.appInfo.Theme}/libs/bootstrap/bootstrap.min.css`, `/css/${self.appService.appInfo.Theme}/ib.css?ver=201804061605`],
                body_class: "tiny-mce-body",
                skin: "lightgray",
                theme: "modern"
            };
    */

}
