export enum HelpContext {

  CustomerOverview,
  CustomerInfo,
  CustomerAddress,
  CustomerBusiness,
  CustomerPersonal,
  CustomerImage,
  CustomerContacts,
  CustomerFiles,
  CustomerNotes,
  CustomerSalesOpportunities,
  CustomerSecurity,
  CustomerBillingAccounts,
  CustomerBillingTransactions,
  CustomerComments,

  ProspectOverview,
  MarketingContactOverview,

  DirectoryOverview,
  DirectoryInfo,
  DirectoryAddress,
  DirectoryBusiness,
  DirectoryPersonal,
  DirectoryImage,
  DirectoryContacts,
  DirectoryFiles,
  DirectoryNotes,
  DirectorySalesOpportunities,
  DirectorySecurity,
  DirectoryComments,
  DirectoryAssociations,
  DirectoryWorkSchedule,

  LocationOverview,
  LocationImage,
  LocationInfo,
  LocationAddress,
  LocationBusiness,
  LocationPersonal,
  LocationNotes,
  LocationFiles,
  LocationSecurity,
  LocationContacts,
  LocationComments,

  Profile,
  AppSettingsExternalServices,
  AppSettingsAdvanced,
  AppSettingsExperimental,
  AppSettingsCache,

  SystemSettingOrganization,
  SystemSettingCase,
  SystemSettingLibrary,
  SystemSettingAlias,
  SystemSettingMenu,

  AssetOverview,
  AssetInfo,
  AssetType,
  AssetAsset,
  AssetRelatedAssets,
  AssetAdvanced,
  AssetVisibility,
  AssetAccessLog,
  AssetFeedback,
  AssetVersions,
  AssetSelectionRules,
  AssetReviewApproval,
  AssetAuthor,
  AssetStatus,
  AssetComments,
  SystemAssetGroupEditor,
  SystemAssetGroupForm,

  AlarmRuleEditor,
  AlarmRuleOverview,
  AlarmRuleNotifications,
  AlarmRuleUsage,
  AlarmRuleComments,

  FilterSettings,
  FilterBuilder,
  FilterAdvanced,
  FilterComments,

  QuerySettings,
  QueryDataSources,
  QueryOutputConfiguration,
  QueryVariables,
  QueryQuery,
  QueryAdvancedQuery,
  QueryUse,
  QueryApproval,
  QueryComments,
  ReportTemplate,

  DataImportDefinitionTable,
  DataImportDefinitionInfo,
  DataImportDefinitionPropertiesJson,
  DataImportDefinitionPropertiesDataUpdate,
  DataImportDefinitionComments,
  DataImportDefinitionDataUpdateTarget,

  Dashboard,
  PickList,

  ApplicationSecurity,
  ApplicationSecurityInfo,
  ApplicationSecurityValidFor,
  ApplicationSecurityApiKeys,
  ApplicationSecurityGroups,
  ApplicationSecurityRoles,
  ApplicationSecurityAllowDeny,
  ApplicationSecurityComments,
  ApiKey,
  ApiKeyInfo,
  ApiKeyHistory,
  ApiKeyValidity,
  ApiKeyDeprecation,
  ApiKeyComments,
  Role,

  GroupInfo,
  GroupSettings,
  GroupMembers,
  GroupRoles,
  GroupComments,

  SalesOpportunityOverview,
  SalesOpportunityOpportunity,
  SalesOpportunityNotes,
  SalesOpportunityAttachments,
  SalesOpportunityComments,

  TelecomLocationProfileOverview,
  TelecomLocationProfileVisibility,
  TelecomLocationProfileCallType,
  TelecomLocationProfileDomestic,
  TelecomLocationProfileInternational,
  TelecomLocationProfileCustomLocation,
  TelecomLocationProfileComments,

  TelecomLocationCustomOverview,
  TelecomLocationCustomLocation,
  TelecomLocationCustomAdvanced,
  TelecomLocationStandardOverview,
  TelecomLocationStandardLocation,
  TelecomLocationStandardAdvanced,

  WebhookOverview,
  WebhookSettings,
  WebhookEventTypes,
  WebhookSecurity,
  WebhookDelivery,
  WebhookFormat,
  WebhookTriggers,
  WebhookConfiguration,
  WebhookHeaders,
  WebhookComments,

  WebhookEventDetails,
  WebhookEventTriggers,
  WebhookEventDeliveries,
  WebhookEventData,
  WebhookEventResponse,
  WebhookEventTrace,

  SearchConfigurationOverview,
  SearchConfigurationForm,
  SearchConfigurationFilter,
  SearchConfigurationQuery,
  SearchConfigurationResults,
  SearchConfigurationApproval,
  SearchConfigurationComments,

  PaymentProviderInfo,
  PaymentProviderMerchant,
  PaymentProviderSettings,
  PaymentProviderSettingsStandard,
  PaymentProviderSettingsTransaction,
  PaymentProviderSettingsAdvanced,
  PaymentProviderSettingsOther,
  PaymentProviderValidity,
  PaymentProviderSelections,
  PaymentProviderAvsCid,
  PaymentProviderNotifications,
  PaymentProviderConnection,
  PaymentProviderErrorHandling,
  PaymentProviderTriggers,
  PaymentProviderComments,

  InventoryOverview,
  InventoryInfo,
  InventoryPurchaseLease,
  InventoryWarrantyMaintenance,
  InventoryHistory,
  InventoryLocations,
  InventoryComments,

  InventoryHistoryContact,
  InventoryHistoryVersion,
  InventoryHistoryAssociation,
  InventoryHistoryComments,

  InventoryLocationInfo,
  InventoryLocationComments,

  InventoryTypeOverview,
  InventoryTypeInfo,
  InventoryTypePurchaseLease,
  InventoryTypeWarrantyMaintenance,
  InventoryTypeVersions,
  InventoryTypeComments,

  InventoryTypeVersionOverview,
  InventoryTypeVersionInfo,
  InventoryTypeVersionComments,

  ReleaseManagementList,
  ReleaseManagementOverview,
  ReleaseManagementAssignCases,
  ReleaseManagementCasesAssigned,
  ReleaseManagementReleaseNotes,

  VersionPlanningList,

  InventoryQuantityTypeInfo,
  InventoryQuantityTypeConversion,
  InventoryQuantityTypeComments,

  JobGeneral,
  JobStatus,
  JobSchedule,
  JobSettings,
  JobEnvironment,
  JobNotifications,
  JobProgress,
  JobNoProgress,
  JobComments,

  CurrencyInfo,
  CurrencyFormat,
  CurrencyComments,

  ProcessTemplateTable,
  ProcessTemplateInfo,
  ProcessTemplateValidity,
  ProcessTemplateSteps,
  ProcessTemplateInputProperties,
  ProcessTemplateInputTemplate,
  ProcessTemplateOutputTemplate,
  ProcessTemplateSettings,
  ProcessTemplatePickLists,
  ProcessTemplateNotifications,
  ProcessTemplateUse,
  ProcessTemplateLogs,
  ProcessTemplateComments,

  ProcessTemplateStepTable,
  ProcessTemplateStepInfo,
  ProcessTemplateStepAction,
  ProcessTemplateStepActionProperties,
  ProcessTemplateStepActionAdvancedProperties,
  ProcessTemplateStepActionAdvancedSettings,
  ProcessTemplateStepInputTemplate,
  ProcessTemplateStepOutputTemplate,
  ProcessTemplateStepNotifications,
  ProcessTemplateStepWaitRetry,
  ProcessTemplateStepApproval,
  ProcessTemplateStepUse,
  ProcessTemplateStepLogs,
  ProcessTemplateStepComments,

  ProcessTable,
  ProcessAdd,
  ProcessInfo,
  ProcessSteps,
  ProcessInputProperties,
  ProcessOutputProperties,
  ProcessApproval,
  ProcessReferences,
  ProcessLogs,

  ProcessStepTable,
  ProcessStepInfo,
  ProcessStepInputProperties,
  ProcessStepOutputProperties,
  ProcessStepLogs,

  PartitionRoute,

  SiteMap,

  UsageServiceIdentificationOverview,
  UsageServiceIdentificationUsage,
  UsageServiceIdentificationComments,



  UsageImportLogOverview,
  UsageImportLogProcess,
  UsageImportLogFileNames,
  UsageImportLogRecordCount,

  UsageDataSourceOverview,
  UsageDataSourceNumbering,
  UsageDataSourceUsageData,
  UsageDataSourceImportProcessing,
  UsageDataSourceImportOptions,
  UsageDataSourceImportAutoAdd,
  UsageDataSourceHomeLocation,
  UsageDataSourceExceptions,
  UsageDataSourceComments,

  UsageDataFeedEditor,
  UsageDataFeedOverview,
  UsageDataFeedInfo,
  UsageDataFeedSource,
  UsageDataFeedTarget,
  UsageDataFeedDisconnect,
  UsageDataFeedIDs,
  UsageDataFeedNetworkStats,
  UsageDataFeedOtherStats,
  UsageDataFeedTimeline,
  UsageDataFeedContact,
  UsageDataFeedAttributes,

  UsageCostRatingOverview,
  UsageCostRatingComments,

  UsageBillingOverview,
  UsageBillingAdvanced,

  NetworkElementOverview,
  NetworkElementLocation,
  NetworkElementIdentification,
  NetworkElementRating,
  NetworkElementQos,
  NetworkElementTelecom,
  NetworkElementPortGroups,
  NetworkElementComments,

  NetworkPortGroupOverview,
  NetworkPortGroupCapacity,
  NetworkPortGroupRating,
  NetworkPortGroupTelecom,
  NetworkPortGroupPorts,
  NetworkPortGroupComments,

  NetworkPortOverview,
  NetworkPortCapacity,
  NetworkPortRating,
  NetworkPortTelecom,
  NetworkPortComments,

  BillingProfileOverview,
  BillingProfileVisibility,
  BillingProfileAccountInformation,
  BillingProfileAccountBalance,
  BillingProfileTaxation,
  BillingProfileComments,
  PackageGeneral,
  PackageValidity,
  PackageBilling,
  PackageVisibility,
  PackageContracts,
  PackageProvisioning,
  PackageNotifications,
  PackageItems,
  PackageRating,
  PackageRateAdjustments,
  PackageAssociations,
  PackageInventory,
  PackageComments,

  PackageAssociationOverview,
  PackageAssociationQuantity,
  PackageAssociationComments,

  PackageInventoryOverview,
  PackageInventoryQuantity,
  PackageInventoryComments,

  PackageItemsOverview,
  PackageItemsValidity,
  PackageItemsComments,

  PackageRateAdjustmentOverview,
  PackageRateAdjustmentComments,


  RatingProfileRates,
  RatingProfileSurchargesDiscounts,
  RatingProfileHolidays,
  RatingProfileLocations,
  RatingProfileVisibility,
  RatingProfileOverview,
  RatingProfileSettings,
  RatingProfileComments,
  RatingGroupSteps,
  RatingGroupDetails,
  RatingGroupValid,
  RatingGroupComments,
  RatingHolidayDetails,
  RatingRateDetails,
  RatingRateCost,
  RatingRateSurcharges,
  RatingRateDays,
  RatingStepRates,
  RatingStepRateLookup,
  RatingStepImportRates,
  RatingSurchargeDiscountDetails,
  RatingZoneDetails,

  BillingReportProfileOverview,
  BillingReportProfileBillTo,
  BillingReportProfileRemitTo,
  BillingReportProfileVisibility,
  BillingReportProfileBillingReports,
  BillingReportProfileComments,
  BillingReportSettings,
  BillingReportReport,
  BillingReportPrint,
  BillingReportExport,
  BillingReportDelivery,
  BillingReportComments,


  BillingAccountOverview,
  PackageOccurrenceGeneral,
  PackageOccurrenceItemGeneral,
  PackageOccurrenceRateAdjustmentGeneral,
  BillingAccountTransactions,
  BillingAccountPackages,
  BillingAccountLocation,
  BillingAccountBalance,
  BillingAccountInvoice,
  BillingAccountPayment,
  BillingAccountTaxation,
  BillingAccountLifeCycle,
  BillingAccountComments,

  MyBillingInvoices,
  MyBillingPayments,
  MyBillingPaymentMethods,
  MyBillingSubscriptions,

  PaymentRefund,

  VisibilityOverview,

  ItemOverview,
  ItemCalculation,
  ItemRates,
  ItemOther,
  ItemComments,
  ItemRateOverview,
  ItemRateCost,
  ItemRateSurchargeDiscount,
  ItemRateComments,
  ItemListOverview,
  ItemListComments,

  VoucherBatchOverview,
  VoucherBatchAmounts,
  VoucherBatchLifeCycle,
  VoucherBatchMarketing,
  VoucherBatchStats,
  VoucherBatchLots,
  VoucherBatchVouchers,
  VoucherBatchVisibility,
  VoucherBatchRestriction,
  VoucherBatchComments,
  VoucherBatchRestrictionRuleOverview,
  VoucherBatchRestrictionRuleComments,
  VoucherBatchVisibilityOverview,
  VoucherBatchWizardStepGeneral,
  VoucherBatchWizardStepTemplates,
  VoucherBatchWizardStepCounts,
  VoucherBatchWizardStepValues,
  VoucherBatchWizardStepShelfLife,
  VoucherOverview,
  VoucherLotOverview,
  VoucherLotManufacturer,
  VoucherLotDistributor,
  VoucherLotTimeline,
  VoucherLotStats,
  VoucherLotVouchers,
  VoucherLotComments,

  VoucherModalListAdd,
  VoucherModalRangeEdit,
  VoucherModalResetCode,

  FileServerOverview,
  FileServerSsl,
  FileServerSsh,

  CaseTemplate,
  CaseTemplateInfo,
  CaseTemplateDefaults,
  CaseTemplateFeatures,
  CaseTemplateNotifications,
  CaseTemplateReview,
  CaseTemplateClose,
  CaseTemplateCancel,
  CaseTemplateDuplicate,
  CaseTemplateTasks,
  CaseTemplateContacts,
  CaseTemplateSettings,
  CaseTemplateComments,

  rcCase,
  rcCaseOverview,
  rcCaseAdvanced,
  rcCaseTimeline,
  rcCaseContacts,
  rcCaseTasks,
  rcCaseSubjectMatterProperty,
  rcCaseSubjectMatterLibrarySelectionRuleSingle,
  rcCaseSubjectMatterLibrarySelectionRuleMultiple,
  rcCaseOptions,
  rcCaseReportSections,
  rcCaseReportAppendices,
  rcCaseReportAppendicesSections,
  rcCaseReportAppendicesDocuments,
  rcCasePreview,
  rcCaseReport,
  rcCaseComments,

  rcCaseTemplate,
  rcCaseTemplateInfo,
  rcCaseTemplateDefaults,
  rcCaseTemplateFeatures,
  rcCaseTemplateNotifications,
  rcCaseTemplateReview,
  rcCaseTemplateClose,
  // rcCaseTemplateCancel,
  // rcCaseTemplateDuplicate,
  rcCaseTemplateTasks,
  rcCaseTemplateContacts,
  rcCaseTemplateSettings,
  rcCaseTemplateSection,
  rcCaseTemplateAppendix,
  rcCaseTemplateComments,

  rcReportParser,
  rcReportParserUpload,
  rcReportParserSettings,
  rcReportParserReportInformation,
  rcReportParserPending,
  rcReportParserOther,
  rcReportParserOverview,
  rcReportParserQuickImport,
  rcLibraryOverview,
  rcLibraryTextInfo,
  rcLibraryTextSelectionRules,
  rcLibraryTextFeedback,
  rcLibraryTextReview,
  rcLibraryTextApproval,
  rcLibraryTextAuthor,
  rcLibraryTextComments,
  rcLibraryDocuments,
  rcLibraryDocumentsInfo,
  rcLibraryDocumentsDocument,
  rcLibraryDocumentsFeedback,
  rcLibraryDocumentsVersions,
  rcLibraryDocumentsReview,
  rcLibraryDocumentsApproval,
  rcLibraryDocumentsAuthor,
  rcLibraryDocumentsComments,
  rcLibraryAddParagraphModalDialog,
  rcLibraryInsightsOverview,
  rcLibraryInsightsGroups,
  rcLibraryInsightsCategories,
  rcLibraryInsightsAuthors,
  rcLibraryInsightsCoverage,
  rcLibraryInsightsAnalysis,
  rcLibraryInsightsReview,

  rcLibraryGroups,
  rcTemplateTypes,
  rcTemplateTypesPickListReview,
  rcTemplateDocuments,

  rcTasksMyTasks,
  rcTasksAssignedBy,
  rcTasksSupervisedBy,

  rcQuickStartOrganizationInformation,
  rcQuickStartOrganizationUsers,
  rcQuickStartOrganizationCases,
  rcQuickStartOrganizationReportReview,
  rcQuickStartOrganizationReportSections,
  rcQuickStartOrganizationNextSteps,

  rcGettingStarted,

  newUserWizardInformation,
  newUserWizardUserInfo,
  newUserWizardServices,
  newUserWizardDashboard,
  newUserWizardNextSteps,

  newUserWizardInformationRC,
  newUserWizardUserInfoRC,
  newUserWizardServicesRC,
  newUserWizardDashboardRC,
  newUserWizardCreateReportRC,
  newUserWizardNextStepsRC,

  BillingProfileInvoiceSettings,
  HelpLinkContext,
  HelpLinkLinks,
  HelpLinkComments,


}

