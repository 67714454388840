// !!! This is generated code... DO NOT modify this file !!!
import * as m from './ngCoreModels';


/**
 * @name AuthenticationAccessViewModel
 * @description
 * The authentication access table contains a list of access identifiers used for authentication.  This provides a historical
 * context to a system access request and allow an application to decide to accept a token based access request or to ask for
 * login or password authentication again.  Then depending on desired security settings existing tokens can be revoked, access
 * can be challenged, or a notification of access can be sent.
 */
export class AuthenticationAccessViewModel {

    /**
     * @name AuthenticationAccessId
     * @description
     * The id of the authentication access record.
     * @servertype System.Object
     * @type {any}
     */
    AuthenticationAccessId: any = null;

    /**
     * @name AuthenticationId
     * @description
     * The id of the authentication identity the access record belongs to.
     * @servertype System.Object
     * @type {any}
     */
    AuthenticationId: any = null;

    /**
     * @name AccessIdentifierType
     * @description
     * The identifier type for this access record.  Possible values include:
     * I = IP Address
     * D = Device Id
     * @servertype String
     * @type {string}
     */
    AccessIdentifierType: string = "";

    /**
     * @name AccessIdentifier
     * @description
     * For identifier type device id this is the device id.
     * For identifier type ip address this is the ip address.
     * @servertype String
     * @type {string}
     */
    AccessIdentifier: string = "";

    /**
     * @name Information1
     * @description
     * Information value 1 for the access identifier which will differ depending on access identifier type.
     * For IP Address this is a user presentation of the location identified for the ip address.
     * For Device Id this is the operating system of the device used for access.
     * @servertype String
     * @type {string}
     */
    Information1: string = "";

    /**
     * @name Information2
     * @description
     * Information value 2 for the access identifier which will differ depending on access identifier type.
     * For IP Address this the country code of the location identified for the ip address.
     * For Device Id this is the application or browser used for access.
     * @servertype String
     * @type {string}
     */
    Information2: string = "";

    /**
     * @name Information3
     * @description
     * Information value 3 for the access identifier which will differ depending on access identifier type.
     * For IP Address this is the country code and region (separated by a hyphen) of the location identified for the ip address.
     * For Device Id this is the user agent for the device used for access.
     * @servertype String
     * @type {string}
     */
    Information3: string = "";

    /**
     * @name Information4
     * @description
     * Information value 4 for the access identifier which will differ depending on access identifier type.
     * For IP Address this is an object of location information identified for the ip address with these properties:
     * ContinentCode, CountryCode, Country, Region, City, PostalCode, TimeZone, Latitude, Longitude
     * For Device Id this is the device fingerprint for the device used for access.
     * @servertype String
     * @type {string}
     */
    Information4: string = "";

    /**
     * @name Information5
     * @description
     * Information value 5 for the access identifier which will differ depending on access identifier type.
     * For IP Address this is the continent code of the location identified for the ip address.
     * @servertype String
     * @type {string}
     */
    Information5: string = "";

    /**
     * @name AccessCount
     * @description
     * The number of times this access identifier has been used by an authentication identity.  Use of access tokens to not update
     * this value but using a refresh token to get new access tokens will update this value.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AccessCount: number = 0;

    /**
     * @name LastAccessDateTime
     * @description
     * The date and time when this access identifier was last used by an authentication identity.  Use of access tokens to not update
     * this value but using a refresh token to get new access tokens will update this value.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAccessDateTime: Date = null;

    /**
     * @name LastAccessAuthenticationId
     * @description
     * The authentication id used when this access identifier was last used.  This may differ from the authentication id linked
     * to the access identifier when a child authentication like refresh tokens or external authentications were used.  When a possible
     * security event is detected this can help determine what type of identity might have been compromised.
     * @servertype System.Object
     * @type {any}
     */
    LastAccessAuthenticationId: any = null;

    /**
     * @name Suspect
     * @description
     * For ip address and device id types, when true this access identifier has been identified as suspect by the system.
     * @servertype Boolean
     * @type {boolean}
     */
    Suspect: boolean = false;

    /**
     * @name BlockedAutomatically
     * @description
     * For ip address and device id types, when true access from this access identifier has been blocked automatically by the system.
     * @servertype Boolean
     * @type {boolean}
     */
    BlockedAutomatically: boolean = false;

    /**
     * @name Whitelisted
     * @description
     * This whitelisted flag can be set true to prevent the system from identifying an access record as suspect or blocked.
     * @servertype Boolean
     * @type {boolean}
     */
    Whitelisted: boolean = false;

    /**
     * @name Comments
     * @description
     * Comments.  This can contain information about why an access record was whitelisted, marked as suspect, or blocked automatically.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Flags
     * @description
     * A list of flags regarding the authentication access.
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

    /**
     * @name Properties
     * @description
     * Properties related to this authentication access.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name MetaData
     * @servertype IB.Authentication.Model.v5.AuthenticationMetaDataViewModel
     * @type {AuthenticationMetaDataViewModel}
     */
    MetaData: AuthenticationMetaDataViewModel = null;

}



/**
 * @name AuthenticationMetaDataViewModel
 */
export class AuthenticationMetaDataViewModel {

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name DeletedAt
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DeletedAt: Date = null;

    /**
     * @name ObjVersion
     * @servertype String
     * @type {string}
     */
    ObjVersion: string = "";

}



/**
 * @name AuthenticationAllowDenyViewModel
 */
export class AuthenticationAllowDenyViewModel {

    /**
     * @name MergeWithOtherAllowDenyLists
     * @servertype Boolean
     * @type {boolean}
     */
    MergeWithOtherAllowDenyLists: boolean = false;

    /**
     * @name ContinentAllow
     * @servertype string array
     * @type {string[]}
     */
    ContinentAllow: string[] = [];

    /**
     * @name ContinentDeny
     * @servertype string array
     * @type {string[]}
     */
    ContinentDeny: string[] = [];

    /**
     * @name CountryAllow
     * @servertype string array
     * @type {string[]}
     */
    CountryAllow: string[] = [];

    /**
     * @name CountryDeny
     * @servertype string array
     * @type {string[]}
     */
    CountryDeny: string[] = [];

    /**
     * @name IpAddressAllow
     * @servertype string array
     * @type {string[]}
     */
    IpAddressAllow: string[] = [];

    /**
     * @name IpAddressDeny
     * @servertype string array
     * @type {string[]}
     */
    IpAddressDeny: string[] = [];

}



/**
 * @name AuthenticationClientInfoViewModel
 */
export class AuthenticationClientInfoViewModel {

    /**
     * @name IpAddress
     * @servertype String
     * @type {string}
     */
    IpAddress: string = "";

    /**
     * @name ContinentCode
     * @servertype String
     * @type {string}
     */
    ContinentCode: string = "";

    /**
     * @name CountryCode
     * @servertype String
     * @type {string}
     */
    CountryCode: string = "";

    /**
     * @name Country
     * @servertype String
     * @type {string}
     */
    Country: string = "";

    /**
     * @name Region
     * @servertype String
     * @type {string}
     */
    Region: string = "";

    /**
     * @name City
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name Latitude
     * @servertype Double (nullable)
     * @type {number}
     */
    Latitude: number = 0;

    /**
     * @name Longitude
     * @servertype Double (nullable)
     * @type {number}
     */
    Longitude: number = 0;

    /**
     * @name IpAddressSearched
     * @servertype Boolean
     * @type {boolean}
     */
    IpAddressSearched: boolean = false;

    /**
     * @name Location
     * @servertype String
     * @type {string}
     */
    Location: string = "";

    /**
     * @name LocationObject
     * @servertype Dictionary<string, object>
     * @type {{[key: string]:  any}}
     */
    LocationObject: {[key: string]:  any} = {};

    /**
     * @name DomainName
     * @servertype String
     * @type {string}
     */
    DomainName: string = "";

    /**
     * @name Url
     * @servertype String
     * @type {string}
     */
    Url: string = "";

    /**
     * @name AppName
     * @servertype String
     * @type {string}
     */
    AppName: string = "";

    /**
     * @name UserAgentString
     * @servertype String
     * @type {string}
     */
    UserAgentString: string = "";

    /**
     * @name Browser
     * @servertype String
     * @type {string}
     */
    Browser: string = "";

    /**
     * @name BrowserVersion
     * @servertype String
     * @type {string}
     */
    BrowserVersion: string = "";

    /**
     * @name BrowserAndVersion
     * @servertype String
     * @type {string}
     */
    BrowserAndVersion: string = "";

    /**
     * @name OperatingSystem
     * @servertype String
     * @type {string}
     */
    OperatingSystem: string = "";

    /**
     * @name OperatingSystemVersion
     * @servertype String
     * @type {string}
     */
    OperatingSystemVersion: string = "";

    /**
     * @name OperatingSystemAndVersion
     * @servertype String
     * @type {string}
     */
    OperatingSystemAndVersion: string = "";

    /**
     * @name DeviceType
     * @servertype String
     * @type {string}
     */
    DeviceType: string = "";

    /**
     * @name DeviceName
     * @servertype String
     * @type {string}
     */
    DeviceName: string = "";

    /**
     * @name DeviceArchitecture
     * @servertype String
     * @type {string}
     */
    DeviceArchitecture: string = "";

    /**
     * @name DeviceId
     * @servertype String
     * @type {string}
     */
    DeviceId: string = "";

    /**
     * @name DeviceFingerprint
     * @servertype String
     * @type {string}
     */
    DeviceFingerprint: string = "";

    /**
     * @name Other
     * @servertype String
     * @type {string}
     */
    Other: string = "";

    /**
     * @name UserAgentStringParsed
     * @servertype Boolean
     * @type {boolean}
     */
    UserAgentStringParsed: boolean = false;

}



/**
 * @name AuthenticationDataViewModel
 * @description
 * The authentication table contains information related to either primary or secondary authentication.
 */
export class AuthenticationDataViewModel {

    /**
     * @name IsValid
     * @description
     * This is set to true when the authentication request was valid.
     * @servertype Boolean
     * @type {boolean}
     */
    IsValid: boolean = false;

    /**
     * @name ErrorMessage
     * @description
     * An error message, if any, about the authentication attempt.
     * @servertype String
     * @type {string}
     */
    ErrorMessage: string = "";

    /**
     * @name AuthEngineVersion
     * @servertype Int32
     * @type {number}
     */
    AuthEngineVersion: number = 0;

    /**
     * @name AuthenticationId
     * @description
     * The id of the authentication entry.
     * @servertype String
     * @type {string}
     */
    AuthenticationId: string = null;

    /**
     * @name ParentAuthenticationId
     * @description
     * The parent authentication id for the authentication entry.  This is required for types T (token), M (MFA), Q (security question),
     * and E (external).
     * @servertype String
     * @type {string}
     */
    ParentAuthenticationId: string = null;

    /**
     * @name TenantId
     * @description
     * The tenant for this authentication.  This value is typically empty when used locally but in a shared authentication service
     * scenario where authentication services are being provided for multiple applications or companies that are segregated this
     * tenant value uniquely identifies the application or company the authentication belongs to.
     * @servertype String
     * @type {string}
     */
    TenantId: string = "";

    /**
     * @name Type
     * @description
     * The authentication type for this authentication record.  Possible values include:
     * L = Login (Login and password based authentication)
     * P = Passwordless Login (Login and WebAuthn based passwordless authentication)
     * K = API Key (API key based authentication with optional api secret support)
     * T = Token (Refresh token linked to parent authentication)
     * M = MFA (MFA authentication based on subtype and linked to parent authentication)
     * Q = Security Question (Security question and answer linked to parent authentication)
     * E = External (External [e.g. SSO] authentication based on subtype and linked to parent authentication)
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Subtype
     * @description
     * The authentication subtype for this authentication record.
     * For type K (API Key) the possible values include:
     * Standard = Standard API Key
     * Anonymous = The API Key is used when anonymous access is requested and grants the rights associated with the user identities
     * attached to this authentication.  Anonymous access is needed for things like anonymous sign up, registration, branding, etc.
     * For type T (refresh token) this is the authentication user id the token belongs to since tokens are linked to users.
     * For type M (MFA) the possible values include:
     * TOTP = Time-Based One-Time Password
     * WebAuthn = WebAuthn Based MFA
     * SMS = SMS
     * Voice = Voice
     * VoiceSMS = Voice or SMS
     * Email = Email
     * BackupCode = Backup Code
     * For type E (external) the possible values include:
     * Partner:* (A partner auth service where * represents the partner name up to 92 characters long)
     * OperatingSystem (windows domain controller, windows server, LDAP, etc.)
     * Google
     * Facebook
     * Azure
     * Microsoft
     * GitHub
     * X
     * LinkedIn
     * @servertype String
     * @type {string}
     */
    Subtype: string = "";

    /**
     * @name Value
     * @description
     * The identity value of this authentication record.
     * For type L (login) this is the login name.
     * For type K (api key) this is the api key.
     * For type T (refresh token) this is the token family id for the refresh token family.
     * For type M (MFA) and subtype TOTP this is a unique random value with no attached meaning.
     * For type M (MFA) and subtype SMS or Voice this is the phone number to use.
     * For type M (MFA) and subtype Email this is the email address to use.
     * For type M (MFA) and subtype BackupCode this is a unique random value with no attached meaning.
     * For type Q (security question) this is the question.
     * For type E (external) this is the identity provided by the external login provider to uniquely identify the user.
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Description
     * @description
     * The description for this authentication record.
     * For type L (login) this is an optional description.
     * For type K (api key) this is an optional description.
     * For type M (MFA) and subtype TOTP this is a label the user associated with the TOTP device.
     * For type E (external) this is an optional description often used for user name.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DisplayName
     * @description
     * The display name for this authentication record.
     * For type M (MFA) and subtype TOTP this is the application display name used.
     * For type E (external) this is an optional display name.
     * @servertype String
     * @type {string}
     */
    DisplayName: string = "";

    /**
     * @name PolicyId
     * @description
     * The id of the authentication policy to use for this authentication.  When not specified or not found, policies for the tenant
     * and user specified organization will be used.
     * @servertype String
     * @type {string}
     */
    PolicyId: string = "";

    /**
     * @name Enabled
     * @description
     * When true the authentication is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this authentication is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this authentication is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name SuspendedOnDateTime
     * @description
     * The date and time this authentication was suspended.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedOnDateTime: Date = null;

    /**
     * @name SuspendedUntilDateTime
     * @description
     * When not null the authentication is suspended until this date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedUntilDateTime: Date = null;

    /**
     * @name SuspendedBy
     * @description
     * An indication of who or what triggered this to be suspended.
     * @servertype String
     * @type {string}
     */
    SuspendedBy: string = "";

    /**
     * @name SuspendedReason
     * @description
     * The reason the authentication was suspended.  Possible values include:
     *   = Not Suspended
     * P = Too many invalid login attempts
     * C = Possible compromise detected
     * T = Terminated Account
     * O = Other
     * @servertype String
     * @type {string}
     */
    SuspendedReason: string = "";

    /**
     * @name SuspendedReasonMessage
     * @description
     * A message regarding the reason the authentication was suspended.
     * @servertype String
     * @type {string}
     */
    SuspendedReasonMessage: string = "";

    /**
     * @name RevokeTokenDateTime
     * @description
     * The date and time all tokens for this authentication record were last revoked.  This can be used to revoke all tokens issued
     * prior to this time when an account may have been compromised.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RevokeTokenDateTime: Date = null;

    /**
     * @name Deprecated
     * @description
     * When true the authentication is considered deprecated and should not be used.  This is most often used with api keys when
     * a new key has been issued and a previous key should be no longer be used but needs to be left active so applications that
     * use it do not fail until updated.
     * @servertype Boolean
     * @type {boolean}
     */
    Deprecated: boolean = false;

    /**
     * @name DeprecatedLastNoticeDateTime
     * @description
     * The date and time the last notice was issued regarding an authentication being deprecated.  This can be used with a security
     * profile configuration to determine how and when notifications should be sent.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DeprecatedLastNoticeDateTime: Date = null;

    /**
     * @name AuthenticationCount
     * @description
     * The number of times this authentication has been performed successfully.  This does not include token validations.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AuthenticationCount: number = 0;

    /**
     * @name LastAuthenticationDateTime
     * @description
     * The date and time when this authentication was last done successfully.  This does not include token validations.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAuthenticationDateTime: Date = null;

    /**
     * @name LastAuthenticationInformation
     * @description
     * Information about the last authentication.
     * @servertype String
     * @type {string}
     */
    LastAuthenticationInformation: string = "";

    /**
     * @name FailedAuthenticationCount
     * @description
     * The number of times this authentication was attempted with an invalid password since the last successful authentication.
     *  This may be used along with the security profile to temporarily block a login.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    FailedAuthenticationCount: number = 0;

    /**
     * @name Roles
     * @description
     * A list of authentication roles belonging to the authenticated identity.  These roles are specifically limited to the authentication
     * service.  Application roles are outside of the scope of the authentication system.
     * @servertype string array
     * @type {string[]}
     */
    Roles: string[] = [];

    /**
     * @name ImplicitRolesAlreadyInList
     * @servertype Boolean
     * @type {boolean}
     */
    ImplicitRolesAlreadyInList: boolean = false;

    /**
     * @name Profile
     * @description
     * Profile object for this authentication.
     * @servertype System.Object
     * @type {any}
     */
    Profile: any = {};

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Flags
     * @description
     * A list of flags regarding the authentication.
     * For MFA this can contain a value 'default' to indicate this is the default MFA option.
     * For api keys this can be used to limit the scope allowed for the api key.  For example: 'job', 'webhook', 'allowed-webhook-target-type',
     * etc.
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

    /**
     * @name Properties
     * @description
     * Properties related to this authentication.
     * For type E (external) these properties are possible:
     * ProfileUrl = An optional URL for the user profile.
     * ImageUrl = An optional URL for the user image.
     * ThumbnailUrl = An optional URL for the user thumbnail.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name CurrentUser
     * @servertype IB.Authentication.Model.v5.AuthenticationDataUserModel
     * @type {AuthenticationDataUserModel}
     */
    CurrentUser: AuthenticationDataUserModel = null;

    /**
     * @name Users
     * @servertype AuthenticationDataUserModel array
     * @type {AuthenticationDataUserModel[]}
     */
    Users: AuthenticationDataUserModel[] = [];

    /**
     * @name Method
     * @servertype IB.Authentication.Model.v5.AuthenticationMethod Enum
     * @type {AuthenticationMethod}
     */
    Method: AuthenticationMethod = null;

    /**
     * @name TokenType
     * @servertype String
     * @type {string}
     */
    TokenType: string = "";

    /**
     * @name TokenName
     * @servertype String
     * @type {string}
     */
    TokenName: string = "";

    /**
     * @name Policy
     * @servertype IB.Authentication.Model.v5.AuthenticationPolicyViewModel
     * @type {AuthenticationPolicyViewModel}
     */
    Policy: AuthenticationPolicyViewModel = null;

    /**
     * @name PolicySelectionReason
     * @servertype IB.Authentication.Model.v5.PolicySelectionReason Enum
     * @type {PolicySelectionReason}
     */
    PolicySelectionReason: PolicySelectionReason = null;

    /**
     * @name MultiFactorAuthenticationTarget
     * @servertype IB.Authentication.Model.v5.MultiFactorAuthenticationOptionViewModel
     * @type {MultiFactorAuthenticationOptionViewModel}
     */
    MultiFactorAuthenticationTarget: MultiFactorAuthenticationOptionViewModel = null;

    /**
     * @name MultiFactorAuthenticationOptions
     * @servertype MultiFactorAuthenticationOptionViewModel array
     * @type {MultiFactorAuthenticationOptionViewModel[]}
     */
    MultiFactorAuthenticationOptions: MultiFactorAuthenticationOptionViewModel[] = [];

}



/**
 * @name AuthenticationDataUserModel
 * @description
 * The authentication user table contains information about what users are linked to a given authentication identity.
 */
export class AuthenticationDataUserModel {

    /**
     * @name AuthenticationUserId
     * @description
     * The id of the authentication user entry.
     * @servertype String
     * @type {string}
     */
    AuthenticationUserId: string = null;

    /**
     * @name AuthenticationId
     * @description
     * The id of the authentication identity the user is linked to.
     * @servertype String
     * @type {string}
     */
    AuthenticationId: string = null;

    /**
     * @name TenantId
     * @description
     * The tenant for this authentication.  This value is typically empty when used locally but in a shared authentication service
     * scenario where authentication services are being provided for multiple applications or companies that are segregated this
     * tenant value uniquely identifies the application or company the authentication belongs to.
     * @servertype String
     * @type {string}
     */
    TenantId: string = "";

    /**
     * @name OrganizationId
     * @description
     * The id of the user organization the authentication is mapped to.  This correlates to PartitionId in our systems but is typed
     * and named generically here for shared authentication service scenarios.
     * @servertype String
     * @type {string}
     */
    OrganizationId: string = "";

    /**
     * @name UserId
     * @description
     * The id of the user the authentication is mapped to.  This correlates to ContactId in our systems but is typed and named generically
     * here for shared authentication service scenarios.
     * @servertype String
     * @type {string}
     */
    UserId: string = "";

    /**
     * @name Enabled
     * @description
     * When true the authentication user is enabled.  Provides a way to disable user without deleting the user.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name Description
     * @description
     * The description for this user which could be present to user for selection of which user to use after authentication.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name UserType
     * @description
     * An optional user type.  Systems that support authentication for different type of users like employees, affiliates, customers,
     * etc. can use this setting to indicate what type of user this is.
     * @servertype String
     * @type {string}
     */
    UserType: string = "";

    /**
     * @name DefaultUser
     * @description
     * When true the user is considered the default user for the authentication identity.  When multiple user accounts are linked
     * to a single authentication and a default is specified it is automatically selected as the active user instead of requiring
     * the person authenticating to select which account they want to use.
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultUser: boolean = false;

    /**
     * @name LastSelectedDateTime
     * @description
     * The date and time when this user was last selected, either manually or automatically, as the active user.  This can be used
     * when we want to automatically select the last selected user as the current user.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastSelectedDateTime: Date = null;

    /**
     * @name Profile
     * @description
     * Profile object for this user.
     * @servertype System.Object
     * @type {any}
     */
    Profile: any = {};

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Flags
     * @description
     * A list of flags regarding the authentication user.
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

    /**
     * @name Properties
     * @description
     * Properties related to this authentication user.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Tokens
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    Tokens: {[key: string]:  string} = {};

}



/**
 * @name AuthenticationMethod Enum
 * @readonly
 * @enum {number}
 */
export enum AuthenticationMethod {

    /**
     * Login
     */
    Login = 0,

    /**
     * Passwordless Login
     */
    PasswordlessLogin = 1,

    /**
     * Api Key
     */
    ApiKey = 2,

    /**
     * Token
     */
    Token = 3,

    /**
     * Implicit
     */
    Implicit = 4,

    /**
     * Unknown
     */
    Unknown = 5

}



/**
 * @name AuthenticationPolicyViewModel
 */
export class AuthenticationPolicyViewModel {

    /**
     * @name AuthenticationSettingId
     * @servertype System.Object
     * @type {any}
     */
    AuthenticationSettingId: any = {};

    /**
     * @name PolicyId
     * @servertype String
     * @type {string}
     */
    PolicyId: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Priority
     * @servertype Int32
     * @type {number}
     */
    Priority: number = 0;

    /**
     * @name MinimumPasswordLength
     * @servertype Int32
     * @type {number}
     */
    MinimumPasswordLength: number = 0;

    /**
     * @name AlphaCharactersRequired
     * @servertype Boolean
     * @type {boolean}
     */
    AlphaCharactersRequired: boolean = false;

    /**
     * @name NumericCharactersRequired
     * @servertype Boolean
     * @type {boolean}
     */
    NumericCharactersRequired: boolean = false;

    /**
     * @name MixedCaseRequired
     * @servertype Boolean
     * @type {boolean}
     */
    MixedCaseRequired: boolean = false;

    /**
     * @name PunctuationRequired
     * @servertype Boolean
     * @type {boolean}
     */
    PunctuationRequired: boolean = false;

    /**
     * @name PasswordCannotContainLogin
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordCannotContainLogin: boolean = false;

    /**
     * @name PasswordCannotContainName
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordCannotContainName: boolean = false;

    /**
     * @name DaysBeforePasswordChangeRequired
     * @servertype Int32
     * @type {number}
     */
    DaysBeforePasswordChangeRequired: number = 0;

    /**
     * @name PreviousPasswordsToBlockRepeating
     * @servertype Int32
     * @type {number}
     */
    PreviousPasswordsToBlockRepeating: number = 0;

    /**
     * @name PreviousPasswordDaysToBlockRepeating
     * @servertype Int32
     * @type {number}
     */
    PreviousPasswordDaysToBlockRepeating: number = 0;

    /**
     * @name FailureCountUntilLoginBlocked
     * @servertype Int32
     * @type {number}
     */
    FailureCountUntilLoginBlocked: number = 0;

    /**
     * @name SecondsToBlockLogin
     * @servertype Int32
     * @type {number}
     */
    SecondsToBlockLogin: number = 0;

    /**
     * @name AuditLoginFailureBadLoginName
     * @servertype Boolean
     * @type {boolean}
     */
    AuditLoginFailureBadLoginName: boolean = false;

    /**
     * @name AuditLoginFailureBadPassword
     * @servertype Boolean
     * @type {boolean}
     */
    AuditLoginFailureBadPassword: boolean = false;

    /**
     * @name AuditLoginFailureBadMultiFactorAuthentication
     * @servertype Boolean
     * @type {boolean}
     */
    AuditLoginFailureBadMultiFactorAuthentication: boolean = false;

    /**
     * @name AuditLoginBlocked
     * @servertype Boolean
     * @type {boolean}
     */
    AuditLoginBlocked: boolean = false;

    /**
     * @name AuditPasswordChanged
     * @servertype Boolean
     * @type {boolean}
     */
    AuditPasswordChanged: boolean = false;

    /**
     * @name AuditPasswordReset
     * @servertype Boolean
     * @type {boolean}
     */
    AuditPasswordReset: boolean = false;

    /**
     * @name AuditNewIpAddress
     * @servertype Boolean
     * @type {boolean}
     */
    AuditNewIpAddress: boolean = false;

    /**
     * @name AuditNewLocation
     * @servertype Boolean
     * @type {boolean}
     */
    AuditNewLocation: boolean = false;

    /**
     * @name AuditNewDeviceId
     * @servertype Boolean
     * @type {boolean}
     */
    AuditNewDeviceId: boolean = false;

    /**
     * @name AuditSecuritySettingsChanged
     * @servertype Boolean
     * @type {boolean}
     */
    AuditSecuritySettingsChanged: boolean = false;

    /**
     * @name SecurityQuestionAnswerRequired
     * @servertype Boolean
     * @type {boolean}
     */
    SecurityQuestionAnswerRequired: boolean = false;

    /**
     * @name SecurityQuestionAnswerFreeForm
     * @servertype Boolean
     * @type {boolean}
     */
    SecurityQuestionAnswerFreeForm: boolean = false;

    /**
     * @name SecurityQuestionAnswerCount
     * @servertype Int32
     * @type {number}
     */
    SecurityQuestionAnswerCount: number = 0;

    /**
     * @name SecurityQuestions
     * @servertype string array
     * @type {string[]}
     */
    SecurityQuestions: string[] = [];

    /**
     * @name LoginRecoveryToEmailAddress
     * @servertype Boolean
     * @type {boolean}
     */
    LoginRecoveryToEmailAddress: boolean = false;

    /**
     * @name PasswordChangeRequiresSecurityQuestionAnswer
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordChangeRequiresSecurityQuestionAnswer: boolean = false;

    /**
     * @name PasswordResetRequiresSecurityQuestionAnswer
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordResetRequiresSecurityQuestionAnswer: boolean = false;

    /**
     * @name PasswordResetLinkBasedOnLoginToEmailAddress
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordResetLinkBasedOnLoginToEmailAddress: boolean = false;

    /**
     * @name PasswordResetLinkBasedOnLoginAndSecurityQuestionToEmailAddress
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordResetLinkBasedOnLoginAndSecurityQuestionToEmailAddress: boolean = false;

    /**
     * @name TokenRejectOnNewIpAddress
     * @servertype Boolean
     * @type {boolean}
     */
    TokenRejectOnNewIpAddress: boolean = false;

    /**
     * @name TokenRejectOnNewLocation
     * @servertype Boolean
     * @type {boolean}
     */
    TokenRejectOnNewLocation: boolean = false;

    /**
     * @name TokenRejectOnNewDeviceId
     * @servertype Boolean
     * @type {boolean}
     */
    TokenRejectOnNewDeviceId: boolean = false;

    /**
     * @name RequireMultiFactorAuthentication
     * @servertype Boolean
     * @type {boolean}
     */
    RequireMultiFactorAuthentication: boolean = false;

    /**
     * @name RequireMultiFactorAuthenticationForNewIpAddress
     * @servertype Boolean
     * @type {boolean}
     */
    RequireMultiFactorAuthenticationForNewIpAddress: boolean = false;

    /**
     * @name RequireMultiFactorAuthenticationForNewLocation
     * @servertype Boolean
     * @type {boolean}
     */
    RequireMultiFactorAuthenticationForNewLocation: boolean = false;

    /**
     * @name RequireMultiFactorAuthenticationForNewDeviceId
     * @servertype Boolean
     * @type {boolean}
     */
    RequireMultiFactorAuthenticationForNewDeviceId: boolean = false;

    /**
     * @name MultiFactorAuthenticationCodeLength
     * @servertype Int32
     * @type {number}
     */
    MultiFactorAuthenticationCodeLength: number = 0;

    /**
     * @name MultiFactorAuthenticationCodeLifetimeMinutes
     * @servertype Int32
     * @type {number}
     */
    MultiFactorAuthenticationCodeLifetimeMinutes: number = 0;

    /**
     * @name MultiFactorAuthenticationFailOpen
     * @servertype Boolean
     * @type {boolean}
     */
    MultiFactorAuthenticationFailOpen: boolean = false;

    /**
     * @name SupportMultiFactorAuthenticationEmail
     * @servertype Boolean
     * @type {boolean}
     */
    SupportMultiFactorAuthenticationEmail: boolean = false;

    /**
     * @name SupportMultiFactorAuthenticationSms
     * @servertype Boolean
     * @type {boolean}
     */
    SupportMultiFactorAuthenticationSms: boolean = false;

    /**
     * @name SupportMultiFactorAuthenticationVoice
     * @servertype Boolean
     * @type {boolean}
     */
    SupportMultiFactorAuthenticationVoice: boolean = false;

    /**
     * @name SupportMultiFactorAuthenticationAppTotp
     * @servertype Boolean
     * @type {boolean}
     */
    SupportMultiFactorAuthenticationAppTotp: boolean = false;

    /**
     * @name SupportMultiFactorAuthenticationAppDuo
     * @servertype Boolean
     * @type {boolean}
     */
    SupportMultiFactorAuthenticationAppDuo: boolean = false;

    /**
     * @name MultiFactorAuthenticationBackupCodes
     * @servertype Int32
     * @type {number}
     */
    MultiFactorAuthenticationBackupCodes: number = 0;

    /**
     * @name NotificationGroupIdNewAccountCreated
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdNewAccountCreated: number = 0;

    /**
     * @name NotificationGroupIdLoginRecovery
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdLoginRecovery: number = 0;

    /**
     * @name NotificationGroupIdPasswordChanged
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdPasswordChanged: number = 0;

    /**
     * @name NotificationGroupIdPasswordReset
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdPasswordReset: number = 0;

    /**
     * @name NotificationGroupIdPasswordResetLink
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdPasswordResetLink: number = 0;

    /**
     * @name NotificationGroupIdMultiFactorAuthenticationCode
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdMultiFactorAuthenticationCode: number = 0;

    /**
     * @name NotificationGroupIdNewMultiFactorAuthenticationMethod
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdNewMultiFactorAuthenticationMethod: number = 0;

    /**
     * @name NotificationGroupIdNewIpAddress
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdNewIpAddress: number = 0;

    /**
     * @name NotificationGroupIdNewDeviceId
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdNewDeviceId: number = 0;

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TenantId
     * @servertype String
     * @type {string}
     */
    TenantId: string = "";

    /**
     * @name OrganizationId
     * @servertype String
     * @type {string}
     */
    OrganizationId: string = "";

    /**
     * @name MetaData
     * @servertype IB.Authentication.Model.v5.AuthenticationMetaDataViewModel
     * @type {AuthenticationMetaDataViewModel}
     */
    MetaData: AuthenticationMetaDataViewModel = null;

}



/**
 * @name PolicySelectionReason Enum
 * @readonly
 * @enum {number}
 */
export enum PolicySelectionReason {

    /**
     * Auth Requested Policy
     */
    AuthRequestedPolicy = 0,

    /**
     * Highest Priority For Organization
     */
    HighestPriorityForOrganization = 1,

    /**
     * Highest Priority For Tenant
     */
    HighestPriorityForTenant = 2,

    /**
     * Default Policy
     */
    DefaultPolicy = 3,

    /**
     * Unknown
     */
    Unknown = 4

}



/**
 * @name MultiFactorAuthenticationOptionViewModel
 */
export class MultiFactorAuthenticationOptionViewModel {

    /**
     * @name Method
     * @servertype String
     * @type {string}
     */
    Method: string = "";

    /**
     * @name Target
     * @servertype String
     * @type {string}
     */
    Target: string = "";

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name DefaultOption
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultOption: boolean = false;

}



/**
 * @name AuthenticationNotificationSettingViewModel
 */
export class AuthenticationNotificationSettingViewModel {

    /**
     * @name Target
     * @servertype String
     * @type {string}
     */
    Target: string = "";

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

}



/**
 * @name AuthenticationOrganizationSettingViewModel
 */
export class AuthenticationOrganizationSettingViewModel {

    /**
     * @name AuthenticationSettingId
     * @servertype System.Object
     * @type {any}
     */
    AuthenticationSettingId: any = {};

    /**
     * @name OrganizationId
     * @servertype String
     * @type {string}
     */
    OrganizationId: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Policies
     * @servertype AuthenticationPolicySelectionModel array
     * @type {AuthenticationPolicySelectionModel[]}
     */
    Policies: AuthenticationPolicySelectionModel[] = [];

    /**
     * @name ApiHost
     * @servertype String
     * @type {string}
     */
    ApiHost: string = "";

    /**
     * @name AutoSelectUser
     * @servertype String
     * @type {string}
     */
    AutoSelectUser: string = "";

    /**
     * @name AllowDeny
     * @servertype IB.Authentication.Model.v5.AuthenticationAllowDenyViewModel
     * @type {AuthenticationAllowDenyViewModel}
     */
    AllowDeny: AuthenticationAllowDenyViewModel = null;

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TenantId
     * @servertype String
     * @type {string}
     */
    TenantId: string = "";

    /**
     * @name MetaData
     * @servertype IB.Authentication.Model.v5.AuthenticationMetaDataViewModel
     * @type {AuthenticationMetaDataViewModel}
     */
    MetaData: AuthenticationMetaDataViewModel = null;

}



/**
 * @name AuthenticationPolicySelectionModel
 */
export class AuthenticationPolicySelectionModel {

    /**
     * @name UserTypes
     * @servertype string array
     * @type {string[]}
     */
    UserTypes: string[] = [];

    /**
     * @name Roles
     * @servertype string array
     * @type {string[]}
     */
    Roles: string[] = [];

    /**
     * @name PolicyId
     * @servertype String
     * @type {string}
     */
    PolicyId: string = "";

}



/**
 * @name AuthenticationRole Enum
 * @readonly
 * @enum {number}
 */
export enum AuthenticationRole {

    /**
     * User
     */
    User = 0,

    /**
     * Authentication Reader
     */
    AuthenticationReader = 1,

    /**
     * Authentication Helper
     */
    AuthenticationHelper = 2,

    /**
     * Authentication Contributor
     */
    AuthenticationContributor = 3,

    /**
     * Security Policy Reader
     */
    SecurityPolicyReader = 4,

    /**
     * Security Policy Contributor
     */
    SecurityPolicyContributor = 5,

    /**
     * Organization Setting Contributor
     */
    OrganizationSettingContributor = 6,

    /**
     * Organization Administrator
     */
    OrganizationAdministrator = 7,

    /**
     * Tenant Setting Contributor
     */
    TenantSettingContributor = 8,

    /**
     * Tenant Administrator
     */
    TenantAdministrator = 9,

    /**
     * Super Administrator
     */
    SuperAdministrator = 10

}



/**
 * @name AuthenticationSettingViewModel
 * @description
 * The authentication setting table contains settings related to authentication.
 */
export class AuthenticationSettingViewModel {

    /**
     * @name AuthenticationSettingId
     * @description
     * Authentication Setting Id uniquely identifies this setting.
     * @servertype System.Object
     * @type {any}
     */
    AuthenticationSettingId: any = null;

    /**
     * @name Category
     * @description
     * Category.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name Attribute
     * @description
     * The attribute.
     * @servertype String
     * @type {string}
     */
    Attribute: string = "";

    /**
     * @name Value
     * @description
     * Value for this attribute.
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Shared
     * @description
     * True indicates the value is a shared value across all organizations.
     * @servertype Boolean
     * @type {boolean}
     */
    Shared: boolean = false;

    /**
     * @name TenantId
     * @description
     * The tenant for this setting.  This value is typically empty when used locally but in a shared authentication service scenario
     * where authentication services are being provided for multiple applications or companies that are segregated this tenant value
     * uniquely identifies the application or company the setting belongs to.
     * @servertype String
     * @type {string}
     */
    TenantId: string = "";

    /**
     * @name OrganizationId
     * @description
     * The id of the organization the setting belongs to.  This correlates to PartitionId in our systems but is typed and named
     * generically here for shared authentication service scenarios.  If shared is set to true this value will be empty.
     * @servertype String
     * @type {string}
     */
    OrganizationId: string = "";

    /**
     * @name Encrypted
     * @description
     * True indicates the value is encrypted.
     * @servertype Boolean
     * @type {boolean}
     */
    Encrypted: boolean = false;

    /**
     * @name EncryptionKeyServerId
     * @description
     * The id of the encryption key management server.
     * @servertype System.Object
     * @type {any}
     */
    EncryptionKeyServerId: any = {};

    /**
     * @name EncryptionKeyTag
     * @description
     * The encryption key tag provided by the key management server.
     * @servertype String
     * @type {string}
     */
    EncryptionKeyTag: string = "";

    /**
     * @name EncryptionKeyVersion
     * @description
     * The encryption key version for the tag provided by the key management server.
     * @servertype String
     * @type {string}
     */
    EncryptionKeyVersion: string = "";

    /**
     * @name MetaData
     * @servertype IB.Authentication.Model.v5.AuthenticationMetaDataViewModel
     * @type {AuthenticationMetaDataViewModel}
     */
    MetaData: AuthenticationMetaDataViewModel = null;

}



/**
 * @name AuthenticationTenantSettingViewModel
 */
export class AuthenticationTenantSettingViewModel {

    /**
     * @name AuthenticationSettingId
     * @servertype System.Object
     * @type {any}
     */
    AuthenticationSettingId: any = {};

    /**
     * @name TenantId
     * @servertype String
     * @type {string}
     */
    TenantId: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Policies
     * @servertype AuthenticationPolicySelectionModel array
     * @type {AuthenticationPolicySelectionModel[]}
     */
    Policies: AuthenticationPolicySelectionModel[] = [];

    /**
     * @name ApiHost
     * @servertype String
     * @type {string}
     */
    ApiHost: string = "";

    /**
     * @name UserTypes
     * @servertype string array
     * @type {string[]}
     */
    UserTypes: string[] = [];

    /**
     * @name AutoSelectUser
     * @servertype String
     * @type {string}
     */
    AutoSelectUser: string = "";

    /**
     * @name Tokens
     * @servertype AuthenticationTokenConfigurationViewModel array
     * @type {AuthenticationTokenConfigurationViewModel[]}
     */
    Tokens: AuthenticationTokenConfigurationViewModel[] = [];

    /**
     * @name AllowDeny
     * @servertype IB.Authentication.Model.v5.AuthenticationAllowDenyViewModel
     * @type {AuthenticationAllowDenyViewModel}
     */
    AllowDeny: AuthenticationAllowDenyViewModel = null;

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name MetaData
     * @servertype IB.Authentication.Model.v5.AuthenticationMetaDataViewModel
     * @type {AuthenticationMetaDataViewModel}
     */
    MetaData: AuthenticationMetaDataViewModel = null;

}



/**
 * @name AuthenticationTokenConfigurationViewModel
 */
export class AuthenticationTokenConfigurationViewModel {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Name
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name KeyId
     * @servertype String
     * @type {string}
     */
    KeyId: string = "";

    /**
     * @name Key
     * @servertype String
     * @type {string}
     */
    Key: string = "";

    /**
     * @name SignatureAlgorithm
     * @servertype String
     * @type {string}
     */
    SignatureAlgorithm: string = "";

    /**
     * @name Issuer
     * @servertype String
     * @type {string}
     */
    Issuer: string = "";

    /**
     * @name Audience
     * @servertype String
     * @type {string}
     */
    Audience: string = "";

    /**
     * @name IpAddressCountToInclude
     * @servertype Int32
     * @type {number}
     */
    IpAddressCountToInclude: number = 0;

    /**
     * @name IpAddressDaysToInclude
     * @servertype Int32
     * @type {number}
     */
    IpAddressDaysToInclude: number = 0;

    /**
     * @name LocationCountToInclude
     * @servertype Int32
     * @type {number}
     */
    LocationCountToInclude: number = 0;

    /**
     * @name LocationDaysToInclude
     * @servertype Int32
     * @type {number}
     */
    LocationDaysToInclude: number = 0;

    /**
     * @name LifetimeMinutes
     * @servertype Int32
     * @type {number}
     */
    LifetimeMinutes: number = 0;

    /**
     * @name ReuseMinutes
     * @servertype Int32
     * @type {number}
     */
    ReuseMinutes: number = 0;

    /**
     * @name Headers
     * @servertype Dictionary<string, object>
     * @type {{[key: string]:  any}}
     */
    Headers: {[key: string]:  any} = {};

    /**
     * @name Claims
     * @servertype Dictionary<string, object>
     * @type {{[key: string]:  any}}
     */
    Claims: {[key: string]:  any} = {};

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name ValidStartDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Reject
     * @servertype Boolean
     * @type {boolean}
     */
    Reject: boolean = false;

    /**
     * @name DeliveryType
     * @servertype String
     * @type {string}
     */
    DeliveryType: string = "";

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

}



/**
 * @name AuthenticationTokenType Enum
 * @readonly
 * @enum {number}
 */
export enum AuthenticationTokenType {

    /**
     * Access
     */
    Access = 0,

    /**
     * Asset
     */
    Asset = 1,

    /**
     * Other
     */
    Other = 2,

    /**
     * Refresh
     */
    Refresh = 3

}



/**
 * @name AuthenticationTokenDeliveryType Enum
 * @readonly
 * @enum {number}
 */
export enum AuthenticationTokenDeliveryType {

    /**
     * Response Payload
     */
    ResponsePayload = 0,

    /**
     * Cookie
     */
    Cookie = 1,

    /**
     * Cookie Http Only
     */
    CookieHttpOnly = 2,

    /**
     * Response Payload And Cookie
     */
    ResponsePayloadAndCookie = 3,

    /**
     * Response Payload And Cookie Http Only
     */
    ResponsePayloadAndCookieHttpOnly = 4

}



/**
 * @name AuthenticationUserViewModel
 * @description
 * The authentication user table contains information about what users are linked to a given authentication identity.
 */
export class AuthenticationUserViewModel {

    /**
     * @name AuthenticationUserId
     * @description
     * The id of the authentication user entry.
     * @servertype String
     * @type {string}
     */
    AuthenticationUserId: string = null;

    /**
     * @name AuthenticationId
     * @description
     * The id of the authentication identity the user is linked to.
     * @servertype String
     * @type {string}
     */
    AuthenticationId: string = null;

    /**
     * @name AuthenticationType
     * @description
     * The authentication type for the authentication identity this user is linked to.  Possible values include:
     * L = Login (Login and password based authentication)
     * P = Passwordless Login (Login and WebAuthn based passwordless authentication)
     * K = API Key (API key based authentication with optional api secret support)
     * @servertype String
     * @type {string}
     */
    AuthenticationType: string = "";

    /**
     * @name TenantId
     * @description
     * The tenant for this authentication.  This value is typically empty when used locally but in a shared authentication service
     * scenario where authentication services are being provided for multiple applications or companies that are segregated this
     * tenant value uniquely identifies the application or company the authentication belongs to.
     * @servertype String
     * @type {string}
     */
    TenantId: string = "";

    /**
     * @name OrganizationId
     * @description
     * The id of the user organization the authentication is mapped to.  This correlates to PartitionId in our systems but is typed
     * and named generically here for shared authentication service scenarios.
     * @servertype String
     * @type {string}
     */
    OrganizationId: string = "";

    /**
     * @name UserId
     * @description
     * The id of the user the authentication is mapped to.  This correlates to ContactId in our systems but is typed and named generically
     * here for shared authentication service scenarios.
     * @servertype String
     * @type {string}
     */
    UserId: string = "";

    /**
     * @name Enabled
     * @description
     * When true the authentication user is enabled.  Provides a way to disable user without deleting the user.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name Description
     * @description
     * The description for this user which could be present to user for selection of which user to use after authentication.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name UserType
     * @description
     * An optional user type.  Systems that support authentication for different type of users like employees, affiliates, customers,
     * etc. can use this setting to indicate what type of user this is.
     * @servertype String
     * @type {string}
     */
    UserType: string = "";

    /**
     * @name Profile
     * @description
     * Profile object for this user.
     * @servertype System.Object
     * @type {any}
     */
    Profile: any = {};

    /**
     * @name DefaultUser
     * @description
     * When true the user is considered the default user for the authentication identity.  When multiple user accounts are linked
     * to a single authentication and a default is specified it is automatically selected as the active user instead of requiring
     * the person authenticating to select which account they want to use.
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultUser: boolean = false;

    /**
     * @name LastSelectedDateTime
     * @description
     * The date and time when this user was last selected, either manually or automatically, as the active user.  This can be used
     * when we want to automatically select the last selected user as the current user.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastSelectedDateTime: Date = null;

    /**
     * @name LastSelectedInformation
     * @description
     * Information about the last time this user was selected, either manually or automatically, as the active user.
     * @servertype IB.Authentication.Model.v5.AuthenticationClientInfoViewModel
     * @type {AuthenticationClientInfoViewModel}
     */
    LastSelectedInformation: AuthenticationClientInfoViewModel = null;

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Flags
     * @description
     * A list of flags regarding the authentication user.
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

    /**
     * @name Properties
     * @description
     * Properties related to this authentication user.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name MetaData
     * @servertype IB.Authentication.Model.v5.AuthenticationMetaDataViewModel
     * @type {AuthenticationMetaDataViewModel}
     */
    MetaData: AuthenticationMetaDataViewModel = null;

}



/**
 * @name AuthenticationViewModel
 * @description
 * The authentication table contains information related to either primary or secondary authentication.
 */
export class AuthenticationViewModel {

    /**
     * @name AuthenticationId
     * @description
     * The id of the authentication entry.
     * @servertype String
     * @type {string}
     */
    AuthenticationId: string = null;

    /**
     * @name ParentAuthenticationId
     * @description
     * The parent authentication id for the authentication entry.  This is required for types T (token), M (MFA), Q (security question),
     * and E (external).
     * @servertype String
     * @type {string}
     */
    ParentAuthenticationId: string = null;

    /**
     * @name TenantId
     * @description
     * The tenant for this authentication.  This value is typically empty when used locally but in a shared authentication service
     * scenario where authentication services are being provided for multiple applications or companies that are segregated this
     * tenant value uniquely identifies the application or company the authentication belongs to.
     * @servertype String
     * @type {string}
     */
    TenantId: string = "";

    /**
     * @name Type
     * @description
     * The authentication type for this authentication record.  Possible values include:
     * L = Login (Login and password based authentication)
     * P = Passwordless Login (Login and WebAuthn based passwordless authentication)
     * K = API Key (API key based authentication with optional api secret support)
     * T = Token (Refresh token linked to parent authentication)
     * M = MFA (MFA authentication based on subtype and linked to parent authentication)
     * Q = Security Question (Security question and answer linked to parent authentication)
     * E = External (External [e.g. SSO] authentication based on subtype and linked to parent authentication)
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Subtype
     * @description
     * The authentication subtype for this authentication record.
     * For type K (API Key) the possible values include:
     * Standard = Standard API Key
     * Anonymous = The API Key is used when anonymous access is requested and grants the rights associated with the user identities
     * attached to this authentication.  Anonymous access is needed for things like anonymous sign up, registration, branding, etc.
     * For type T (refresh token) this is the authentication user id the token belongs to since tokens are linked to users.
     * For type M (MFA) the possible values include:
     * TOTP = Time-Based One-Time Password
     * WebAuthn = WebAuthn Based MFA
     * SMS = SMS
     * Voice = Voice
     * VoiceSMS = Voice or SMS
     * Email = Email
     * BackupCode = Backup Code
     * For type E (external) the possible values include:
     * Partner:* (A partner auth service where * represents the partner name up to 92 characters long)
     * OperatingSystem (windows domain controller, windows server, LDAP, etc.)
     * Google
     * Facebook
     * Azure
     * Microsoft
     * GitHub
     * X
     * LinkedIn
     * @servertype String
     * @type {string}
     */
    Subtype: string = "";

    /**
     * @name Value
     * @description
     * The identity value of this authentication record.
     * For type L (login) this is the login name.
     * For type K (api key) this is the api key.
     * For type T (refresh token) this is the token family id for the refresh token family.
     * For type M (MFA) and subtype TOTP this is a unique random value with no attached meaning.
     * For type M (MFA) and subtype SMS or Voice this is the phone number to use.
     * For type M (MFA) and subtype Email this is the email address to use.
     * For type M (MFA) and subtype BackupCode this is a unique random value with no attached meaning.
     * For type Q (security question) this is the question.
     * For type E (external) this is the identity provided by the external login provider to uniquely identify the user.
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Description
     * @description
     * The description for this authentication record.
     * For type L (login) this is an optional description.
     * For type K (api key) this is an optional description.
     * For type M (MFA) and subtype TOTP this is a label the user associated with the TOTP device.
     * For type E (external) this is an optional description often used for user name.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DisplayName
     * @description
     * The display name for this authentication record.
     * For type M (MFA) and subtype TOTP this is the application display name used.
     * For type E (external) this is an optional display name.
     * @servertype String
     * @type {string}
     */
    DisplayName: string = "";

    /**
     * @name PolicyId
     * @description
     * The id of the authentication policy to use for this authentication.  When not specified or not found, policies for the tenant
     * and user specified organization will be used.
     * @servertype String
     * @type {string}
     */
    PolicyId: string = "";

    /**
     * @name EncryptionKeyServerId
     * @description
     * The id of the encryption key management server.
     * @servertype System.Object
     * @type {any}
     */
    EncryptionKeyServerId: any = {};

    /**
     * @name EncryptionKeyTag
     * @description
     * The encryption key tag provided by the key management server.
     * @servertype String
     * @type {string}
     */
    EncryptionKeyTag: string = "";

    /**
     * @name EncryptionKeyVersion
     * @description
     * The encryption key version for the tag provided by the key management server.
     * @servertype String
     * @type {string}
     */
    EncryptionKeyVersion: string = "";

    /**
     * @name SecretAlgorithm
     * @description
     * The algorithm used to protect the secrets.  Possible values include:
     * 101 = Encrypt using AES256 and global pepper.  This is valid for:
     * * API Key secrets used for signing api requests
     * * Refresh tokens
     * * MFA TOTP shared secrets
     * * MFA backup codes
     * * MFA codes most recently sent to Email, SMS or Voice
     * * Answers to security questions
     * 201 = Append Global Salt, Apply SHA512 Hash, BCrypt with user salt and work factor of 11, Encrypt using AES256 and global
     * pepper.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SecretAlgorithm: number = 0;

    /**
     * @name GlobalSaltVersion
     * @description
     * The global salt version used while protecting the secrets.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    GlobalSaltVersion: number = 0;

    /**
     * @name GlobalPepperVersion
     * @description
     * The global pepper version used while protecting the secrets.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    GlobalPepperVersion: number = 0;

    /**
     * @name Secret1
     * @description
     * The first secret for this authentication protected using the algorithm and values outlined for the authentication record.
     * For type L (login) this is the password.
     * For type K (api key) this is api secret #1.  Multiple secrets allow for secret rotation.
     * For type T (refresh token) this is the refresh token.
     * For type M (MFA) and subtype TOTP this is the base32 shared secret that must be supplied to the authenticator client.
     * For type M (MFA) and subtype BackupCode this is the backup code.
     * For type M (MFA) and subtype Email, SMS or Voice this is the value last sent for MFA.
     * For type Q (security question) this is the answer converted to upper case with punctuation removed to improve answer matching.
     * @servertype String
     * @type {string}
     */
    Secret1: string = "";

    /**
     * @name Secret2
     * @description
     * The second secret for this authentication protected using the algorithm and values outlined for the authentication record.
     * For type K (api key) this is api secret #2.  Multiple secrets allow for secret rotation.
     * For type T (refresh token) this is the previous refresh token for this token family.
     * Information about refresh token rotation:
     * When a client needs a new access token, it sends the refresh token with the request to get new tokens.  As soon as the new
     * tokens are issued, the refresh token used in the request is invalidated.  This helps safeguard the application from replay
     * attacks resulting from compromised refresh tokens.  When a previously-used refresh token (already invalidated) is used then
     * the most recently issued refresh token is immediately invalidated as well, preventing any refresh tokens in the same token
     * family (all refresh tokens descending from the original refresh token issued for the client) from being used to get new access
     * tokens.  In essence any refresh token for an authentication record that is in the same family as the one stored in secret
     * #1 other than the one stored in secret #1 will not only fail but will result in the refresh token stored in secret #1 also
     * being invalidated due to possible breach which should also trigger logging and, depending on configuration, notifications.
     *  To ensure that re-use detection doesn't adversely affect users that suffer intermittent network performance, a configurable
     * reuse interval can be used to account for scenarios where the tokens issued are not received before the client application
     * retries using the original refresh token which helps in cases where users may be on slower cellular connections or congested
     * networks.  Hence the previous issued refresh token is stored in secret #2 which will be treated as valid from the configurable
     * reuse interval based on secret #1 changed date time.
     * @servertype String
     * @type {string}
     */
    Secret2: string = "";

    /**
     * @name Secret3
     * @description
     * The third secret for this authentication protected using the algorithm and values outlined for the authentication record.
     * For type K (api key) this is api secret #3.  Multiple secrets allow for secret rotation.
     * @servertype String
     * @type {string}
     */
    Secret3: string = "";

    /**
     * @name SecretMustBeChanged
     * @description
     * For type L (login) when this is true the secret must be changed when authentication is next performed.  This is typically
     * done for password resets.
     * For type M (MFA) when this is true the MFA subtype needs to be confirmed.
     * @servertype Boolean
     * @type {boolean}
     */
    SecretMustBeChanged: boolean = false;

    /**
     * @name Secret1ChangedDateTime
     * @description
     * The date and time secret #1 was last changed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    Secret1ChangedDateTime: Date = null;

    /**
     * @name Secret2ChangedDateTime
     * @description
     * The date and time secret #2 was last changed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    Secret2ChangedDateTime: Date = null;

    /**
     * @name Secret3ChangedDateTime
     * @description
     * The date and time secret #3 was last changed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    Secret3ChangedDateTime: Date = null;

    /**
     * @name Secret1ChangedInformation
     * @description
     * Information about the last time secret #1 was changed.  This may include information about the client that made the change
     * including device, operating system, browser, ip address, location, etc.
     * @servertype IB.Authentication.Model.v5.AuthenticationClientInfoViewModel
     * @type {AuthenticationClientInfoViewModel}
     */
    Secret1ChangedInformation: AuthenticationClientInfoViewModel = null;

    /**
     * @name Secret2ChangedInformation
     * @description
     * Information about the last time secret #2 was changed.  This may include information about the client that made the change
     * including device, operating system, browser, ip address, location, etc.
     * @servertype IB.Authentication.Model.v5.AuthenticationClientInfoViewModel
     * @type {AuthenticationClientInfoViewModel}
     */
    Secret2ChangedInformation: AuthenticationClientInfoViewModel = null;

    /**
     * @name Secret3ChangedInformation
     * @description
     * Information about the last time secret #3 was changed.  This may include information about the client that made the change
     * including device, operating system, browser, ip address, location, etc.
     * @servertype IB.Authentication.Model.v5.AuthenticationClientInfoViewModel
     * @type {AuthenticationClientInfoViewModel}
     */
    Secret3ChangedInformation: AuthenticationClientInfoViewModel = null;

    /**
     * @name Enabled
     * @description
     * When true the authentication is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this authentication is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this authentication is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name SuspendedOnDateTime
     * @description
     * The date and time this authentication was suspended.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedOnDateTime: Date = null;

    /**
     * @name SuspendedUntilDateTime
     * @description
     * When not null the authentication is suspended until this date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedUntilDateTime: Date = null;

    /**
     * @name SuspendedBy
     * @description
     * An indication of who or what triggered this to be suspended.
     * @servertype String
     * @type {string}
     */
    SuspendedBy: string = "";

    /**
     * @name SuspendedReason
     * @description
     * The reason the authentication was suspended.  Possible values include:
     *   = Not Suspended
     * P = Too many invalid login attempts
     * C = Possible compromise detected
     * T = Terminated Account
     * O = Other
     * @servertype String
     * @type {string}
     */
    SuspendedReason: string = "";

    /**
     * @name SuspendedReasonMessage
     * @description
     * A message regarding the reason the authentication was suspended.
     * @servertype String
     * @type {string}
     */
    SuspendedReasonMessage: string = "";

    /**
     * @name RevokeTokenDateTime
     * @description
     * The date and time all tokens for this authentication record were last revoked.  This can be used to revoke all tokens issued
     * prior to this time when an account may have been compromised.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RevokeTokenDateTime: Date = null;

    /**
     * @name Deprecated
     * @description
     * When true the authentication is considered deprecated and should not be used.  This is most often used with api keys when
     * a new key has been issued and a previous key should be no longer be used but needs to be left active so applications that
     * use it do not fail until updated.
     * @servertype Boolean
     * @type {boolean}
     */
    Deprecated: boolean = false;

    /**
     * @name DeprecatedAuthenticationCount
     * @description
     * The number of times this authentication has been performed successfully after it was deprecated.  This does not include token
     * validations.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DeprecatedAuthenticationCount: number = 0;

    /**
     * @name DeprecatedAuthenticationCountSinceLastNotice
     * @description
     * The number of times this authentication has been performed successfully after it was deprecated since the last time notice
     * was sent.  When notice is sent this is reset to zero.  This does not include token validations.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DeprecatedAuthenticationCountSinceLastNotice: number = 0;

    /**
     * @name DeprecatedLastNoticeDateTime
     * @description
     * The date and time the last notice was issued regarding an authentication being deprecated.  This can be used with a security
     * profile configuration to determine how and when notifications should be sent.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DeprecatedLastNoticeDateTime: Date = null;

    /**
     * @name AuthenticationCount
     * @description
     * The number of times this authentication has been performed successfully.  This does not include token validations.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AuthenticationCount: number = 0;

    /**
     * @name LastAuthenticationDateTime
     * @description
     * The date and time when this authentication was last done successfully.  This does not include token validations.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAuthenticationDateTime: Date = null;

    /**
     * @name LastAuthenticationInformation
     * @description
     * Information about the last authentication.
     * @servertype String
     * @type {string}
     */
    LastAuthenticationInformation: string = "";

    /**
     * @name FailedAuthenticationCount
     * @description
     * The number of times this authentication was attempted with an invalid password since the last successful authentication.
     *  This may be used along with the security profile to temporarily block a login.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    FailedAuthenticationCount: number = 0;

    /**
     * @name Roles
     * @description
     * A list of authentication roles belonging to the authenticated identity.  These roles are specifically limited to the authentication
     * service.  Application roles are outside of the scope of the authentication system.
     * @servertype string array
     * @type {string[]}
     */
    Roles: string[] = [];

    /**
     * @name AllowDeny
     * @description
     * A list of allow and deny lists for ip addresses, continents, and countries.  These lists are available at the tenant and
     * organization level but are often used here for api key restrictions.
     * @servertype IB.Authentication.Model.v5.AuthenticationAllowDenyViewModel
     * @type {AuthenticationAllowDenyViewModel}
     */
    AllowDeny: AuthenticationAllowDenyViewModel = null;

    /**
     * @name NotificationSettings
     * @description
     * A list of email addresses and mobile numbers to use when non-MFA communication is required.  This is a list of objects with
     * the following properties:
     * Target = The email address or mobile number
     * Type = Email or SMS
     * @servertype AuthenticationNotificationSettingViewModel array
     * @type {AuthenticationNotificationSettingViewModel[]}
     */
    NotificationSettings: AuthenticationNotificationSettingViewModel[] = [];

    /**
     * @name Profile
     * @description
     * Profile object for this authentication.
     * @servertype System.Object
     * @type {any}
     */
    Profile: any = {};

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Flags
     * @description
     * A list of flags regarding the authentication.
     * For MFA this can contain a value 'default' to indicate this is the default MFA option.
     * For api keys this can be used to limit the scope allowed for the api key.  For example: 'job', 'webhook', 'allowed-webhook-target-type',
     * etc.
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

    /**
     * @name Properties
     * @description
     * Properties related to this authentication.
     * For type E (external) these properties are possible:
     * ProfileUrl = An optional URL for the user profile.
     * ImageUrl = An optional URL for the user image.
     * ThumbnailUrl = An optional URL for the user thumbnail.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Users
     * @servertype AuthenticationUserViewModel array
     * @type {AuthenticationUserViewModel[]}
     */
    Users: AuthenticationUserViewModel[] = [];

    /**
     * @name Children
     * @servertype AuthenticationViewModel array
     * @type {AuthenticationViewModel[]}
     */
    Children: AuthenticationViewModel[] = [];

    /**
     * @name MetaData
     * @servertype IB.Authentication.Model.v5.AuthenticationMetaDataViewModel
     * @type {AuthenticationMetaDataViewModel}
     */
    MetaData: AuthenticationMetaDataViewModel = null;

}



