<div class="{{nav.themeClasses}} ib-nav-top">
  <div class="ib-nav-top-content-item d-inline-block"
       ngbDropdown
       [placement]="dropdownPlacement"
       (openChange)="onDropDownOpenChange($event)"
       #userOptions="ngbDropdown">
    <button class="btn ib-nav-top-content-button"
            [ngStyle]="{'padding-left': ( taskStatus.TotalCount ? '0.25rem' : 'inherit' ) }"
            [ngbTooltip]="userName"
            [placement]="buttonTooltipPlacement"
            id="dropdownManual"
            ngbDropdownAnchor
            (click)="userOptions.toggle()">
      <ng-container *ngIf="!taskStatus.TotalCount">
        <ib-avatar *ngIf="user"
                   [contactId]="user.ContactId"
                   name="{{user.ContactName}}"
                   email="{{user.ContactEmail}}"
                   [tooltip]="false"
                   [tooltipPlacement]="avatarTooltipPlacement"
                   [size]="30"
                   [inline]="true"
                   (click)="userOptions.toggle();">
        </ib-avatar>
        <i *ngIf="!user"
            class="fal fa-user-cog"></i>
      </ng-container>
      <span *ngIf="taskStatus.TotalCount">
        <ib-avatar *ngIf="user"
                   [contactId]="user.ContactId"
                   name="{{user.ContactName}}"
                   email="{{user.ContactEmail}}"
                   [tooltip]="false"
                   [tooltipPlacement]="avatarTooltipPlacement"
                   [size]="30"
                   [inline]="true"
                   (click)="userOptions.toggle();">
        </ib-avatar>
        <i *ngIf="!user"
           class="fal fa-user-cog"></i>
        <span class="badge bg-pill bg-danger"
              [ngClass]="{'bg-danger': taskStatus.TotalCountHighPriority, 'bg-info': !taskStatus.TotalCountHighPriority}"
              style="font-size:xx-small; position: absolute; top: 10px; right: 20px;">
          {{taskStatus.TotalCount | number}}
        </span>
      </span>
      <!-- <i *ngIf="!taskStatus.TotalCount" class="fal fa-user-cog"></i>
      <span *ngIf="taskStatus.TotalCount" class="fa-stack fa-1x">
        <i class="fal fa-user-cog fa-stack-1x"></i>
        <span class="badge bg-pill bg-danger"
          [ngClass]="{'bg-danger': taskStatus.TotalCountHighPriority, 'bg-info': !taskStatus.TotalCountHighPriority}"
          style="font-size:xx-small; position: absolute; top: 0; right: 0;">
          {{taskStatus.TotalCount | number}}
        </span>
      </span> -->
    </button>
    <div ngbDropdownMenu
         aria-labelledby="dropdownManual">
      <ng-container *ngIf="isLoggedIn">
        <button *ngIf="showProfileOption"
                class="dropdown-item"
                [routerLink]="['/profile']"><i class="fal fa-user fa-fw"></i>&nbsp;&nbsp;Profile</button>
        <button *ngIf="showMyAppSettingsOption"
                class="dropdown-item"
                [routerLink]="['/settings/my-app-settings']"><i class="fal fa-user-cog fa-fw"></i>&nbsp;&nbsp;My App Settings</button>
        <button *ngIf="showTasksOption"
                class="dropdown-item"
                [routerLink]="['/tasks']">
          <i class="fal fa-check-square fa-fw"></i>&nbsp;&nbsp;Tasks
          <span *ngIf="taskStatus.TotalCountNotHighPriority"
                class="badge bg-pill bg-info ms-1">{{taskStatus.TotalCountNotHighPriority | number}}</span>
          <span *ngIf="taskStatus.TotalCountHighPriority"
                class="badge bg-pill bg-danger ms-1">{{taskStatus.TotalCountHighPriority | number}}</span>
        </button>
        <!-- <button *ngIf="showBillingOption" -->
        <button *ngIf="false"
                class="dropdown-item"
                [routerLink]="['billing/my-billing']"><i class="fal fa-file-invoice fa-fw"></i>&nbsp;&nbsp;Billing</button>
        <!-- when mobile portrait we hide history menu which is where sitemap normally lives so if mobile show it here -->
        <button *ngIf="isMobilePortrait && showSiteMapOption"
                class="dropdown-item"
                [routerLink]="['/system/site-map']"><i class="fal fa-sitemap fa-fw"></i>&nbsp;&nbsp;Site Map</button>
        <div *ngIf="showProfileOption || showTasksOption || showBookmarkOption"
              class="dropdown-divider"></div>
        <button class="dropdown-item"
                (click)="logout()"><i class="fal fa-sign-out fa-fw"></i>&nbsp;&nbsp;Logout</button>
      </ng-container>
      <ng-container *ngIf="!isLoggedIn">
        <button class="dropdown-item"
                [routerLink]="['/login']"><i class="fal fa-sign-in fa-fw"></i>&nbsp;&nbsp;Login</button>
      </ng-container>
    </div>
  </div>
</div>