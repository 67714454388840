import { NgModel } from "@angular/forms";
import { InputStatusChangeModel } from "projects/common-lib/src/lib/input/input-status-change-model";
import { Helper } from "projects/core-lib/src/lib/helpers/helper";

export class InputStatusChangeHelper {


  public static buildModel(id: string, name: string, label: string, control: NgModel): InputStatusChangeModel {

    const model: InputStatusChangeModel = new InputStatusChangeModel();
    model.id = id;
    model.name = name;
    model.label = label;

    if (!control) {
      return model;
    }

    model.valid = control.valid;
    model.invalid = control.invalid;
    model.pristine = control.pristine;
    model.dirty = control.dirty;
    model.touched = control.touched;
    model.untouched = control.untouched;
    model.status = control.status;
    model.errors = control.errors;

    return model;

  }


  public static isChanged(oldStatus: InputStatusChangeModel, control: NgModel): boolean {

    if (!oldStatus && control) {
      return true;
    }
    if (!control) {
      return false;
    }

    if (oldStatus.valid !== control.valid) {
      return true;
    }
    if (oldStatus.invalid !== control.invalid) {
      return true;
    }
    if (oldStatus.pristine !== control.pristine) {
      return true;
    }
    if (oldStatus.touched !== control.touched) {
      return true;
    }
    if (oldStatus.untouched !== control.untouched) {
      return true;
    }
    if (oldStatus.status !== control.status) {
      return true;
    }
    if (!oldStatus.errors && control.errors) {
      return true;
    }
    if (oldStatus.errors && !control.errors) {
      return true;
    }
    if (oldStatus.errors && control.errors) {
      if (!Helper.equals(JSON.stringify(oldStatus.errors), JSON.stringify(control.errors), true)) {
        return true;
      }
    }

    return false;

  }


}
